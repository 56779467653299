md-toast
{
	z-index: 99999999;
}

.md-backend-theme
{
	.md-toast-content
	{
		background: #17386a !important;
		padding: 10px;
	}
}

@media (max-width: 959px)
{
	md-toast.md-bottom {
		position: fixed;
		bottom: 0;
	}
}

.md-error-theme
{
	background-color: red;
}

.md-success-theme
{
	background-color: green;
}
md-toolbar
{
	a, button.linklike
	{
		display: inline-flex;
		align-items: center;
		padding-right: 30px;
		font-size: 14px;
		&.help
		{
			flex: 1 1 0;
			justify-content: flex-end;
			padding: 0;
			md-icon
			{
				margin: 0;
			}
		}

		md-icon
		{
			margin-right: 10px;
			color: #FFF !important;
			fill: #FFF !important;
		}
	}
}
.md-button.md-icon-button
{
	transition: all .24s;
	@include media($tablet)
	{
		margin: 0 2px;
	}
}
.md-icon-button.md-background
{
	width: 42px;
	height: 42px;
	border: 1px solid #FFF;

	@include media($tablet)
	{
		height: 30px;
		width: 30px;
		min-height: 30px;
		min-width: 30px;
		line-height: 16px;
		margin: 4px auto;
		padding: 4px;
	}

	&:hover
	{
		border: 1px solid #FFF;
		background: #00378a !important;
		border-color: #00378a !important;
		md-icon
		{
			color: #FFF !important;
			fill: #FFF !important;
		}
	}
	md-icon
	{
		color: $maincolor-Blue-Light !important;
		fill: $maincolor-Blue-Light !important;
		transition: all .24s;
	}

	&.md-mini
	{
		width: 38px;
		height: 38px;
		md-icon
		{
			line-height: 16px;
			width: 16px;
			height: 16px;
			min-height: 16px;
			min-width: 16px;
		}
	}
}


@media (max-width: $mobile){
	md-tabs{
		md-prev-button,
		md-next-button{
			width: 40px;
			height: 50px;
			md-icon{
				width: 100%;
				height: auto;
			}
		}
	}
	.md-icon-button{
		&.md-background{
			&.md-mini{
				width: 25px;
				height: 25px;
			}
		}
	}
}

