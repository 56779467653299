$maincolor-Grey-Light: #AEAEAE;
$maincolor-Grey-Dark: #888;
$maincolor-Grey-Blue-Light: #9babb6;
$maincolor-Blue-Light: #00378a;
$maincolor-Blue-Dark: #00081F; //#0c3a62;
$maincolor-Red: #FA0019;
$maincolor-Red-Divera247: #FA0019; //#EB0A1B;

// Above the fold scss (not used but for reference)
$webapp-background-gradient-end: #00080F;
$webapp-background-webkit: -webkit-radial-gradient(#00378a 0, $webapp-background-gradient-end 70%, $webapp-background-gradient-end);
$webapp-background: radial-gradient(#00378a 0, $webapp-background-gradient-end 70%, $webapp-background-gradient-end);

$text-color: #00378a;
$footer-background: $maincolor-Blue-Dark;
$footer-background-overlay: radial-gradient(rgba(36,84,157,.75) 0%, rgba(36,84,157,0) 70%, rgba(36,84,157,0) 100%);
$footer-metanav-background: inherit;
$ucr-navigation-inactive-color: #999;
$navigation-background: #000813;
$navigation-link-color: #ccc;
$navigation-hover-other-link-color: #aaa;
$navigation-link-hover-color: #fff;
$navigation-link-active-color: #fff;
$navigation-logo-crop-mobile: 1;

// Other
$navigation-active-border-background:  rgb(228, 17, 21);
$navigation-subnav-outer-background: #0d397b;
$avatar-background: #001e4b;
