.input-datetime
{
	position: relative;
	clear: both;
}
	.input-datetime__field
	{
		position: relative;

	}
	.input-datetime__field__wrap
	{
		position: relative;
	}
		.input-datetime__field__wrap input
		{
			float: none;
		}

	.input-datetime__field.opened
	{
		input
		{
			background: #FFF;
			border: 1px solid $maincolor-Blue-Dark;
			box-shadow: none;
			outline: 1px solid #FFF;
		}
	}

	.input-icons
	{
		top: 1px;
		right: 1px;
		bottom: 1px;
		background: #FFF;
	}

	.input-datetime__picker
	{
		position: absolute;
		right: -1px;
		z-index: 999999;
		padding: 1px;
		color: $maincolor-Blue-Dark;
		font-size: 14px;
		width: 250px;

		&.input-datetime__picker--bottom
		{
			top: 100%;
		}

		&.input-datetime__picker--top
		{
			bottom: 100%;
		}
		.row
		{
			margin: 0;
			padding: 3px;
		}
		.column
		{
			padding: 3px;
		}
	}
		.input-datetime__picker__header,
		.input-datetime__picker__legend
		{
			font-weight: bold;
			text-align: center;
		}
		.input-datetime__picker__header
		{
			display: flex;
			height: 50px;
			align-items: center;

			div
			{
				flex: 1 1 71.42857142857145%;
			}
			div.input-datetime__picker__header__nav
			{
				flex: 1 1 14.28571428571429%;
				font-size: 28px;
				cursor: pointer;
			}
		}
		.input-datetime__picker__content
		{
			color: $maincolor-Blue-Dark;
			background: #FFF;
		}
		.input-datetime__picker__actions
		{
			clear: both;
			a
			{
				display: block;
				padding: 5px 0;
				text-align: center;
				color: $maincolor-Blue-Dark !important;
			}
		}
		.input-datetime__picker__inner
		{
			display: block;
			width: 100%;
			max-width: 250px;
			float: right;
			background: #FFF;
			border: 1px solid #000720;
		}

.input-datetime__field + .input-datetime__field
{
	margin-top: 10px;
}

.calendar
{
	&.calendar--month
	{
		.calendar__day
		{
			float: left;
			width: 14.28571428571429%;
			min-width: 7px;
			cursor: pointer;

			.ratio__content
			{
				top: 1px;
				right: 1px;
				bottom: 1px;
				left: 1px;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			&:first-child
			{
				&.calendar__day--day-2
				{
					margin-left: 14.28571428571429%;
				}

				&.calendar__day--day-3
				{
					margin-left: 28.57142857142858%;
				}

				&.calendar__day--day-4
				{
					margin-left: 42.85714285714287%;
				}

				&.calendar__day--day-5
				{
					margin-left: 57.14285714285716%;
				}

				&.calendar__day--day-6
				{
					margin-left: 71.42857142857145%;
				}

				&.calendar__day--day-7
				{
					margin-left: 85.71428571428574%;
				}
			}

			&:hover .ratio__content
			{
				background: #e0e0e0;
			}

			&.calendar__day--today .ratio__content
			{
				background: yellow;
			}

			&.calendar__day--active .ratio__content
			{
				background: #14325e;
				color: #FFF;
			}
		}
	}
}