// Typography
// Typography
// ------------------------------
@use "sass:math";

$font-family: Roboto, Arial, 'Helvetica Neue', sans-serif !default;
$font-size: 10px !default;

//-- Must be defined before $font-size.
@function rem($multiplier)
{
	@return $multiplier * $font-size;
}

$display-4-font-size-base: rem(11.20) !default;
$display-3-font-size-base: rem(5.600) !default;
$display-2-font-size-base: rem(4.500) !default;
$display-1-font-size-base: rem(3.400) !default;
$headline-font-size-base: rem(2.400) !default;
$title-font-size-base: rem(1.600) !default;
$subhead-font-size-base: rem(1.600) !default;

$body-font-size-base: rem(1.600) !default;
$caption-font-size-base: rem(1.200) !default;

// Layout
// ------------------------------

$baseline-grid: 8px !default;
$layout-gutter-width: ($baseline-grid * 2) !default;

$layout-breakpoint-xs: 600px !default;
$layout-breakpoint-sm: 960px !default;
$layout-breakpoint-md: 1280px !default;
$layout-breakpoint-lg: 1920px !default;

// Button
$button-left-right-padding: rem(0.600) !default;

// Icon
$icon-size: rem(1.600) !default;

// App bar variables
$app-bar-height: 64px !default;

$toast-height: $baseline-grid * 3 !default;
$toast-margin: $baseline-grid * 1 !default;

// Whiteframes

$shadow-key-umbra-opacity: 0.2 !default;
$shadow-key-penumbra-opacity: 0.14 !default;
$shadow-ambient-shadow-opacity: 0.12 !default;

// NOTE(shyndman): gulp-sass seems to be failing if I split the shadow defs across
//    multiple lines. Ugly. Sorry.
$whiteframe-shadow-1dp: 0px 1px 3px 0px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0px 1px 1px 0px rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0px 2px 1px -1px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-2dp: 0px 1px 5px 0px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0px 2px 2px 0px rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0px 3px 1px -2px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-3dp: 0px 1px 8px 0px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0px 3px 4px 0px rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0px 3px 3px -2px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-4dp: 0px 2px 4px -1px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0px 4px 5px 0px rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0px 1px 10px 0px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-5dp: 0px 3px 5px -1px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0px 5px 8px 0px rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0px 1px 14px 0px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-6dp: 0px 3px 5px -1px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0px 6px 10px 0px rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0px 1px 18px 0px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-7dp: 0px 4px 5px -2px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0px 7px 10px 1px rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0px 2px 16px 1px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-8dp: 0px 5px 5px -3px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0px 8px 10px 1px rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0px 3px 14px 2px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-9dp: 0px 5px 6px -3px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0px 9px 12px 1px rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0px 3px 16px 2px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-10dp: 0px 6px 6px -3px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0px 10px 14px 1px rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0px 4px 18px 3px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-11dp: 0px 6px 7px -4px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0px 11px 15px 1px rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0px 4px 20px 3px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-12dp: 0px 7px 8px -4px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0px 12px 17px 2px rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0px 5px 22px 4px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-13dp: 0px 7px 8px -4px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0px 13px 19px 2px rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0px 5px 24px 4px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-14dp: 0px 7px 9px -4px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0px 14px 21px 2px rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0px 5px 26px 4px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-15dp: 0px 8px 9px -5px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0px 15px 22px 2px rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0px 6px 28px 5px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-16dp: 0px 8px 10px -5px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0px 16px 24px 2px rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0px 6px 30px 5px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-17dp: 0px 8px 11px -5px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0px 17px 26px 2px rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0px 6px 32px 5px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-18dp: 0px 9px 11px -5px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0px 18px 28px 2px rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0px 7px 34px 6px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-19dp: 0px 9px 12px -6px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0px 19px 29px 2px rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0px 7px 36px 6px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-20dp: 0px 10px 13px -6px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0px 20px 31px 3px rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0px 8px 38px 7px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-21dp: 0px 10px 13px -6px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0px 21px 33px 3px rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0px 8px 40px 7px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-22dp: 0px 10px 14px -6px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0px 22px 35px 3px rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0px 8px 42px 7px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-23dp: 0px 11px 14px -7px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0px 23px 36px 3px rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0px 9px 44px 8px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;
$whiteframe-shadow-24dp: 0px 11px 15px -7px rgba(0, 0, 0, $shadow-key-umbra-opacity), 0px 24px 38px 3px rgba(0, 0, 0, $shadow-key-penumbra-opacity), 0px 9px 46px 8px rgba(0, 0, 0, $shadow-ambient-shadow-opacity) !default;

// Z-indexes
//--------------------------------------------

$z-index-toast: 105 !default;
$z-index-tooltip: 100 !default;
$z-index-menu: 100 !default;
$z-index-calendar-pane: 100 !default;
$z-index-select: 90 !default;
$z-index-dialog: 80 !default;
$z-index-bottom-sheet: 70 !default;
$z-index-scroll-mask: 50 !default;
$z-index-scroll-mask-bar: 65 !default;
$z-index-sidenav: 60 !default;
$z-index-backdrop: 50 !default;
$z-index-fab: 20 !default;
$z-index-progress-circular: 2 !default; // Used to fix animation bug in Chrome

// Easing Curves
//--------------------------------------------

$swift-ease-out-duration: 0.4s !default;
$swift-ease-out-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1) !default;
$swift-ease-out: all $swift-ease-out-duration $swift-ease-out-timing-function !default;

$swift-ease-in-duration: 0.3s !default;
$swift-ease-in-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2) !default;
$swift-ease-in: all $swift-ease-in-duration $swift-ease-in-timing-function !default;

$swift-ease-in-out-duration: 0.5s !default;
$swift-ease-in-out-timing-function: cubic-bezier(0.35, 0, 0.25, 1) !default;
$swift-ease-in-out: all $swift-ease-in-out-duration $swift-ease-in-out-timing-function !default;

$swift-linear-duration: 0.08s !default;
$swift-linear-timing-function: linear !default;
$swift-linear: all $swift-linear-duration $swift-linear-timing-function !default;

$material-enter-duration: 0.3s;
$material-enter-timing-function: cubic-bezier(0.0, 0.0, 0.2, 1);
$material-enter: all $material-enter-duration $material-enter-timing-function;

$material-leave-duration: 0.3s;
$material-leave-timing-function: cubic-bezier(0.4, 0.0, 1, 1);
$material-leave: all $material-leave-duration $material-leave-timing-function;

// Fab Buttons (shared between buttons.scss and fab*.scss)
// -------------------------------------------
$button-fab-width: rem(5.600) !default;
$button-fab-height: rem(5.600) !default;
$button-fab-padding: rem(1.60) !default;

// Shared Checkbox variables
$checkbox-width: 20px !default;
$checkbox-height: $checkbox-width !default;
$checkbox-border-radius: 2px !default;
$checkbox-border-width: 2px !default;

@mixin margin-selectors($before:1em, $after:1em, $start:0px, $end:0px)
{
	-webkit-margin-before: $before;
	-webkit-margin-after: $after;
	-webkit-margin-start: $start;
	-webkit-margin-end: $end;
}

@mixin not-selectable($value:none)
{
	-webkit-touch-callout: $value;
	-webkit-user-select: $value;
	-khtml-user-select: $value;
	-moz-user-select: $value;
	-ms-user-select: $value;
	user-select: $value;
}

@mixin input-placeholder-color($color)
{
	$pseudos: '::-webkit-input-placeholder', ':-moz-placeholder', '::-moz-placeholder',
	':-ms-input-placeholder', '::-webkit-input-placeholder';

	// It is important to export every pseudo within its own block, because otherwise the placeholder
	// won't be set on the most browsers.
	@each $pseudo in $pseudos
	{
		&#{$pseudo}
		{
			color: unquote($color);
		}
	}
}

@mixin pie-clearfix
{
	&:after
	{
		content: '';
		display: table;
		clear: both;
	}
}

@mixin md-shadow-bottom-z-1()
{
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

@mixin md-shadow-bottom-z-2()
{
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
}

// Mixin for a "flat" input that can be used for components that contain an input
// (datepicker, autocomplete).
@mixin md-flat-input()
{
	font-size: 14px;

	box-sizing: border-box;
	border: none;
	box-shadow: none;
	outline: none;
	background: transparent;

	// The "clear X" that IE adds to input[type="search"]
	&::-ms-clear
	{
		display: none;
	}
}

// Typography mixins

@mixin md-title()
{
	font-size: $title-font-size-base;
	font-weight: 600;
	letter-spacing: 0.005em;
}

@mixin md-body-1()
{
	font-size: $body-font-size-base;
	font-weight: 400;
	letter-spacing: 0.010em;
	line-height: rem(2);
}

@mixin md-body-2()
{
	font-size: $body-font-size-base;
	font-weight: 500;
	letter-spacing: 0.010em;
	line-height: rem(2.4);
}

@mixin md-subhead()
{
	font-size: $subhead-font-size-base;
	font-weight: 400;
	letter-spacing: 0.010em;
	line-height: rem(2.4);
}

@function map-to-string($map)
{
	$map-str: '{';
	$keys: map-keys($map);
	$len: length($keys);
	@for $i from 1 through $len
	{
		$key: nth($keys, $i);
		$value: map-get($map, $key);
		$map-str: $map-str + '_' + $key + '_: _' + map-get($map, $key) + '_';
		@if $i != $len
		{
			$map-str: $map-str + ',';
		}
	}
	@return $map-str + '}';
}

// This is a mixin, which fixes IE11's vertical alignment issue, when using `min-height`.
// See https://connect.microsoft.com/IE/feedback/details/816293/
@mixin ie11-min-height-flexbug($min-height)
{
	&::before
	{
		content: '';
		min-height: $min-height;
		visibility: hidden;
		display: inline-block;
	}
}

// mixin definition ; sets LTR and RTL within the same style call
// @see https://css-tricks.com/almanac/properties/d/direction/

@mixin rtl($prop, $ltr-value, $rtl-value)
{
	#{$prop}: $ltr-value;
	[dir=rtl] &
	{
		#{$prop}: $rtl-value;
	}
}

@mixin rtl-prop($ltr-prop, $rtl-prop, $value, $reset-value)
{
	#{$ltr-prop}: $value;
	[dir=rtl] &
	{
		#{$ltr-prop}: $reset-value;
		#{$rtl-prop}: $value;
	}
}

// To reverse padding (top left bottom right) -> (top right bottom left)
@function rtl-value($list)
{
	@if length($list) == 4
	{
		@return nth($list, 1) nth($list, 4) nth($list, 3) nth($list, 2)
	}
	@if length($list) == 5
	{
		@return nth($list, 1) nth($list, 4) nth($list, 3) nth($list, 2) nth($list, 5)
	}
	@return $list;
}

// Position a FAB button.
@mixin fab-position($spot, $top: auto, $right: auto, $bottom: auto, $left: auto)
{
	&.md-fab-#{$spot}
	{
		top: $top;
		right: $right;
		bottom: $bottom;
		left: $left;
		position: absolute;
	}
}

@mixin fab-all-positions()
{
	@include fab-position(bottom-right, auto, ($button-fab-width - $button-fab-padding)*0.5, ($button-fab-height - $button-fab-padding)*0.5, auto);
	@include fab-position(bottom-left, auto, auto, ($button-fab-height - $button-fab-padding)*0.5, ($button-fab-width - $button-fab-padding)*0.5);
	@include fab-position(top-right, ($button-fab-height - $button-fab-padding)*0.5, ($button-fab-width - $button-fab-padding)*0.5, auto, auto);
	@include fab-position(top-left, ($button-fab-height - $button-fab-padding)*0.5, auto, auto, ($button-fab-width - $button-fab-padding)*0.5);
}

// This mixin allows a user to use the md-checkbox css outside of the
// md-checkbox directive.
// See src/components/select/select.scss for an example.
@mixin checkbox-container(
	$checkedSelector: '.md-checked',
  $width: $checkbox-width,
  $height: $checkbox-height,
  $border-width: $checkbox-border-width,
  $border-radius: $checkbox-border-radius)
{
	.md-container
	{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);

		box-sizing: border-box;
		display: inline-block;

		width: $width;
		height: $height;
		@include rtl(left, 0, auto);
		@include rtl(right, auto, 0);

		&:before
		{
			box-sizing: border-box;
			background-color: transparent;
			border-radius: 50%;
			content: '';
			position: absolute;
			display: block;
			height: auto;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			transition: all 0.5s;
			width: auto;
		}

		&:after
		{
			box-sizing: border-box;
			content: '';
			position: absolute;
			top: -10px;
			right: -10px;
			bottom: -10px;
			left: -10px;
		}

		.md-ripple-container
		{
			position: absolute;
			display: block;
			width: auto;
			height: auto;
			left: -15px;
			top: -15px;
			right: -15px;
			bottom: -15px;
		}
	}

	// unchecked
	.md-icon
	{
		box-sizing: border-box;
		transition: 240ms;
		position: absolute;
		top: 0;
		left: 0;
		width: $width;
		height: $height;
		border-width: $border-width;
		border-style: solid;
		border-radius: $border-radius;
		//display: flex !important;
	}

	&#{$checkedSelector} .md-icon
	{
		border-color: transparent;

		&:after
		{
			box-sizing: border-box;
			transform: rotate(45deg);
			position: absolute;
			left: math.div($width, 3) - $border-width;
			top: math.div($width, 9) - $border-width;
			display: table;
			width: math.div($width, 3);
			height: math.div($width * 2, 3);
			border-width: $border-width;
			border-style: solid;
			border-top: 0;
			border-left: 0;
			content: '';
		}
	}

	// disabled
	&[disabled]
	{
		cursor: default;
	}

	&.md-indeterminate .md-icon
	{
		&:after
		{
			box-sizing: border-box;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			display: table;
			width: $width * 0.6;
			height: $border-width;
			border-width: $border-width;
			border-style: solid;
			border-top: 0;
			border-left: 0;
			content: '';
		}
	}
}

// Mixin to create a primary checkbox.
// Used by the checkbox and select component.
@mixin checkbox-primary($checkedSelector: '.md-checked')
{
	.md-ripple
	{
		color: '{{primary-600}}';
	}

	&#{$checkedSelector} .md-ripple
	{
		color: '{{background-600}}';
	}

	.md-ink-ripple
	{
		color: '{{foreground-2}}';
	}

	&#{$checkedSelector} .md-ink-ripple
	{
		color: '{{primary-color-0.87}}';
	}

	&:not(.md-checked) .md-icon
	{
		border-color: '{{foreground-2}}';
	}

	&#{$checkedSelector} .md-icon
	{
		background-color: '{{primary-color-0.87}}';
	}

	&#{$checkedSelector}.md-focused .md-container:before
	{
		background-color: '{{primary-color-0.26}}';
	}

	&#{$checkedSelector} .md-icon:after
	{
		border-color: '{{primary-contrast-0.87}}';
	}

	& .md-indeterminate[disabled]
	{
		.md-container
		{
			color: '{{foreground-3}}';
		}
	}
}

@mixin dense($prop, $normal, $dense)
{
	#{$prop}: $normal;
	.md-dense > &:not(.md-dense-disabled),
	.md-dense :not(.md-dense-disabled) &:not(.md-dense-disabled)
	{
		#{$prop}: $dense;
	}
}

@mixin dense-rtl($prop, $ltr-normal, $rtl-normal, $ltr-dense, $rtl-dense)
{
	@include rtl($prop, $ltr-normal, $rtl-normal);
	.md-dense > &:not(.md-dense-disabled),
	.md-dense :not(.md-dense-disabled) &:not(.md-dense-disabled)
	{
		@include rtl($prop, $ltr-dense, $rtl-dense);
	}
}

html, body
{
	height: 100%;
	position: relative;
}

body
{
	margin: 0;
	padding: 0;
}

[tabindex='-1']:focus
{
	outline: none;
}

.inset
{
	padding: 10px;
}

a.md-no-style,
button.md-no-style
{
	font-weight: normal;
	background-color: inherit;
	text-align: left;
	border: none;
	padding: 0;
	margin: 0;
}

select,
button,
textarea,
input
{
	vertical-align: baseline;
}

// Fix Android 4.0 button bugs
input[type="reset"],
input[type="submit"],
html input[type="button"],
button
{
	cursor: pointer;
	-webkit-appearance: button;

	&[disabled]
	{
		cursor: default;
	}
}

textarea
{
	vertical-align: top;
	overflow: auto;
}

input
{
	&[type="search"]
	{
		-webkit-appearance: textfield;
		box-sizing: content-box;
		-webkit-box-sizing: content-box;

		&::-webkit-search-decoration,
		&::-webkit-search-cancel-button
		{
			-webkit-appearance: none;
		}
	}
	&:-webkit-autofill
	{
		text-shadow: none;
	}
}

.md-visually-hidden
{
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	text-transform: none;
	width: 1px;
}

.md-shadow
{
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	border-radius: inherit;
	pointer-events: none;
}

.md-shadow-bottom-z-1
{
	@include md-shadow-bottom-z-1();
}

.md-shadow-bottom-z-2
{
	@include md-shadow-bottom-z-2();
}

.md-shadow-animated.md-shadow
{
	transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}

/*
 * A container inside of a rippling element (eg a button),
 * which contains all of the individual ripples
 */
.md-ripple-container
{
	pointer-events: none;
	position: absolute;
	overflow: hidden;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	transition: all 0.55s $swift-ease-out-timing-function;
}

.md-ripple
{
	$sizeDuration: 0.45s * 2;
	position: absolute;
	transform: translate(-50%, -50%) scale(0);
	transform-origin: 50% 50%;
	opacity: 0;
	border-radius: 50%;
	&.md-ripple-placed
	{
		transition: margin $sizeDuration $swift-ease-out-timing-function,
		border $sizeDuration $swift-ease-out-timing-function,
		width $sizeDuration $swift-ease-out-timing-function,
		height $sizeDuration $swift-ease-out-timing-function,
		opacity $sizeDuration $swift-ease-out-timing-function,
		transform $sizeDuration $swift-ease-out-timing-function;
	}
	&.md-ripple-scaled
	{
		transform: translate(-50%, -50%) scale(1);
	}
	&.md-ripple-active, &.md-ripple-full, &.md-ripple-visible
	{
		opacity: 0.20;
	}
	&.md-ripple-remove
	{
		animation: md-remove-ripple $sizeDuration $swift-ease-out-timing-function;
	}
}

// Fix issue causing ripple disappear suddenly in Chrome version 51, opacity .15 is close to the opacity when a normal click mouseup
@keyframes md-remove-ripple
{
	0%
	{
		opacity: .15;
	}
	100%
	{
		opacity: 0;
	}
}

.md-padding
{
	padding: 8px 8px 0 8px;
}

.md-margin
{
	margin: 8px;
}

.md-scroll-mask
{
	position: absolute;
	background-color: transparent;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: $z-index-scroll-mask;

	> .md-scroll-mask-bar
	{
		display: block;
		position: absolute;
		background-color: #FAFAFA;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: $z-index-scroll-mask-bar;
		box-shadow: inset 0px 0px 1px rgba(0, 0, 0, 0.3)
	}
}

// For iOS allow disabling of momentum scrolling
// @see issue #2640
.md-no-momentum
{
	-webkit-overflow-scrolling: auto;
}

// Add a class to help reduce flicker
// @see issue #7078 and #8649
.md-no-flicker
{
	-webkit-filter: blur(0px);
}

@media (min-width: $layout-breakpoint-sm)
{
	.md-padding
	{
		padding: 16px 16px 0 16px;
	}
}

// Bi-directional support

html, body
{
	&[dir=rtl], &[dir=ltr]
	{
		unicode-bidi: embed
	}
}

bdo[dir=rtl]
{
	direction: rtl;
	unicode-bidi: bidi-override;
}

bdo[dir=ltr]
{
	direction: ltr;
	unicode-bidi: bidi-override;
}

// Global page styles
//
// [2] Ensure the page always fills at least the entire height of the viewport.
// [3] Prevent iOS text size adjust after orientation change, without disabling user zoom
// [4] Fonts on OSX will look more consistent with other systems that do not
// render text using sub-pixel anti-aliasing.

html, body
{
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-touch-callout: none;

	min-height: 100%; // [2]

	-webkit-text-size-adjust: 100%; // [3]
	-ms-text-size-adjust: 100%; // [3]

	-webkit-font-smoothing: antialiased; // [4]
	-moz-osx-font-smoothing: grayscale; // [4]
}

md-select, md-card, md-list, md-toolbar,
ul, ol, p, h1, h2, h3, h4, h5, h6
{
	//text-rendering: optimizeLegibility;
}

/************
 * Headings
 ************/
.md-display-4
{
	font-size: $display-4-font-size-base;
	font-weight: 300;
	letter-spacing: -0.010em;
	line-height: $display-4-font-size-base;
}

.md-display-3
{
	font-size: $display-3-font-size-base;
	font-weight: 400;
	letter-spacing: -0.005em;
	line-height: $display-3-font-size-base;
}

.md-display-2
{
	font-size: $display-2-font-size-base;
	font-weight: 400;
	line-height: rem(6.4);
}

.md-display-1
{
	font-size: $display-1-font-size-base;
	font-weight: 400;
	line-height: rem(4);
}

.md-headline
{
	font-size: $headline-font-size-base;
	font-weight: 400;
	line-height: rem(3.2);
}

.md-title
{
	@include md-title();
}

.md-subhead
{
	@include md-subhead();
}

/************
 * Body Copy
 ************/
.md-body-1
{
	@include md-body-1();
}

.md-body-2
{
	@include md-body-2();
}

.md-caption
{
	font-size: $caption-font-size-base;
	letter-spacing: 0.020em;
}

.md-button
{
	letter-spacing: 0.010em;
}

/************
 * Defaults
 ************/
/*
button,
select,
html,
textarea,
input {
  font-family: $font-family;
}

select,
button,
textarea,
input {
  font-size: 100%;
}
*/

/*
*
*  Responsive attributes
*
*  References:
*  1) https://scotch.io/tutorials/a-visual-guide-to-css3-flexbox-properties#flex
*  2) https://css-tricks.com/almanac/properties/f/flex/
*  3) https://css-tricks.com/snippets/css/a-guide-to-flexbox/
*  4) https://github.com/philipwalton/flexbugs#3-min-height-on-a-flex-container-wont-apply-to-its-flex-items
*  5) http://godban.com.ua/projects/flexgrid
*
*
*/

@mixin flex-order-for-name($sizes:null)
{
	@if $sizes == null
	{
		$sizes: '';

		.flex-order
		{
			order: 0;
		}
	}

	@for $i from -20 through 20
	{
		$order: '';
		$suffix: '';

		@each $s in $sizes
		{
			@if $s != ''
			{
				$suffix: '#{$s}-#{$i}';
			} @else
			{
				$suffix: '#{$i}';
			}

			$order: '.flex-order-#{$suffix}';
		}

		#{$order}
		{
			order: #{$i};
		}
	}
}

@mixin offset-for-name($sizes:null)
{
	@if $sizes == null
	{
		$sizes: '';
	}

	@for $i from 0 through 19
	{
		$offsets: '';
		$suffix: '';

		@each $s in $sizes
		{
			@if $s != ''
			{
				$suffix: '#{$s}-#{$i * 5}';
			} @else
			{
				$suffix: '#{$i * 5}';
			}

			$offsets: '.offset-#{$suffix}, .flex-offset-#{$suffix}';
		}

		#{$offsets}
		{
			@if $i != 0
			{
				@include rtl-prop(margin-left, margin-right, #{$i * 5 + '%'}, auto);
			} @else
			{
				@include rtl-prop(margin-left, margin-right, 0, auto);
			}
		}
	}

	@each $i in 33
	{
		$offsets: '';
		$suffix: '';

		@each $s in $sizes
		{
			@if $s != ''
			{
				$suffix: '#{$s}-#{$i}';
			} @else
			{
				$suffix: '#{$i}';
			}

			$offsets: '.offset-#{$suffix}, .flex-offset-#{$suffix} ';
		}

		#{$offsets}
		{
			margin-left: calc(100% / 3);
		}
	}

	@each $i in 66
	{
		$offsets: '';
		$suffix: '';

		@each $s in $sizes
		{
			@if $s != ''
			{
				$suffix: '#{$s}-#{$i}';
			} @else
			{
				$suffix: '#{$i}';
			}

			$offsets: '.offset-#{$suffix}, .flex-offset-#{$suffix} ';
		}

		#{$offsets}
		{
			@include rtl-prop(margin-left, margin-right, calc(200% / 3), auto);
		}
	}
}

@mixin layout-for-name($name: null)
{
	@if $name == null
	{
		$name: '';
	}
	@if $name != ''
	{
		$name: '-#{$name}';
	}

	.layout#{$name}, .layout#{$name}-column, .layout#{$name}-row
	{
		box-sizing: border-box;
		display: -webkit-box;
		display: -webkit-flex;
		display: -moz-box;
		display: -ms-flexbox;
		display: flex;
	}
	.layout#{$name}-column
	{
		flex-direction: column;
	}
	.layout#{$name}-row
	{
		flex-direction: row;
	}
}

@mixin flex-properties-for-name($name: null)
{
	$flexName: 'flex';
	@if $name != null
	{
		$flexName: 'flex-#{$name}';
		$name: '-#{$name}';
	} @else
	{
		$name: '';
	}

	.#{$flexName}
	{
		flex: 1;
		box-sizing: border-box;
	}
	// === flex: 1 1 0%;

	.#{$flexName}-grow
	{
		flex: 1 1 100%;
		box-sizing: border-box;
	}
	.#{$flexName}-initial
	{
		flex: 0 1 auto;
		box-sizing: border-box;
	}
	.#{$flexName}-auto
	{
		flex: 1 1 auto;
		box-sizing: border-box;
	}
	.#{$flexName}-none
	{
		flex: 0 0 auto;
		box-sizing: border-box;
	}
	.#{$flexName}-noshrink
	{
		flex: 1 0 auto;
		box-sizing: border-box;
	}
	.#{$flexName}-nogrow
	{
		flex: 0 1 auto;
		box-sizing: border-box;
	}

	// (1-20) * 5 = 0-100%
	@for $i from 0 through 20
	{
		$value: #{$i * 5 + '%'};

		.#{$flexName}-#{$i * 5}
		{
			flex: 1 1 100%;
			max-width: #{$value};
			max-height: 100%;
			box-sizing: border-box;
		}

		.layout-row > .#{$flexName}-#{$i * 5}
		{
			flex: 1 1 100%;
			max-width: #{$value};
			max-height: 100%;
			box-sizing: border-box;

			// Bug workaround for http://crbug.com/546034 - flex issues on Chrome 48
			@if $i == 0
			{
				min-width: 0;
			}
		}

		.layout-column > .#{$flexName}-#{$i * 5}
		{
			flex: 1 1 100%;
			max-width: 100%;
			max-height: #{$value};
			box-sizing: border-box;
		}

		.layout-row
		{
			> .#{$flexName}-33, > .#{$flexName}-33
			{
				flex: 1 1 33.33%;
				max-width: 33.33%;
				max-height: 100%;
				box-sizing: border-box;
			}
			> .#{$flexName}-66, > .#{$flexName}-66
			{
				flex: 1 1 66.66%;
				max-width: 66.66%;
				max-height: 100%;
				box-sizing: border-box;
			}
		}

		.layout-column
		{
			> .#{$flexName}-33, > .#{$flexName}-33
			{
				flex: 1 1 33.33%;
				max-width: 100%;
				max-height: 33.33%;
				box-sizing: border-box;
			}
			> .#{$flexName}-66, > .#{$flexName}-66
			{
				flex: 1 1 66.66%;
				max-width: 100%;
				max-height: 66.66%;
				box-sizing: border-box;
			}
		}

		.layout#{$name}-row > .#{$flexName}-#{$i * 5}
		{
			flex: 1 1 100%;
			max-width: #{$value};
			max-height: 100%;
			box-sizing: border-box;

			// Bug workaround for http://crbug.com/546034 - flex issues on Chrome 48
			@if $i == 0
			{
				min-width: 0;
			}
		}

		.layout#{$name}-column > .#{$flexName}-#{$i * 5}
		{
			flex: 1 1 100%;
			max-width: 100%;
			max-height: #{$value};
			box-sizing: border-box;

			// Bug workaround for http://crbug.com/546034 - flex issues on Chrome 48
			@if $i == 0
			{
				min-height: 0;
			}
		}

	}

	.layout#{$name}-row
	{
		> .#{$flexName}-33, > .#{$flexName}-33
		{
			flex: 1 1 100%;
			max-width: 33.33%;
			max-height: 100%;
			box-sizing: border-box;
		}
		> .#{$flexName}-66, > .#{$flexName}-66
		{
			flex: 1 1 100%;
			max-width: 66.66%;
			max-height: 100%;
			box-sizing: border-box;
		}

		// Bug workaround for http://crbug.com/546034 - flex issues on Chrome 48
		> .flex
		{
			min-width: 0;
		}

	}

	.layout#{$name}-column
	{
		> .#{$flexName}-33, > .#{$flexName}-33
		{
			flex: 1 1 100%;
			max-width: 100%;
			max-height: 33.33%;
			box-sizing: border-box;
		}
		> .#{$flexName}-66, > .#{$flexName}-66
		{
			flex: 1 1 100%;
			max-width: 100%;
			max-height: 66.66%;
			box-sizing: border-box;
		}

		// Bug workaround for http://crbug.com/546034 - flex issues on Chrome 48
		> .flex
		{
			min-height: 0;
		}
	}

}

@mixin layout-align-for-name($suffix: null)
{

	// Alignment attributes for layout containers' children
	// Arrange on the Main Axis
	// center, start, end, space-between, space-around
	// flex-start is the default for justify-content
	// ------------------------------

	$name: 'layout-align';
	@if $suffix != null
	{
		$name: 'layout-align-#{$suffix}';
	}

	.#{$name},
	.#{$name}-start-stretch // defaults
	{
		justify-content: flex-start;
		align-content: stretch;
		align-items: stretch;
	}

	// Main Axis Center
	.#{$name}-start,
	.#{$name}-start-start,
	.#{$name}-start-center,
	.#{$name}-start-end,
	.#{$name}-start-stretch
	{
		justify-content: flex-start;
	}

	// Main Axis Center
	.#{$name}-center, //stretch
	.#{$name}-center-start,
	.#{$name}-center-center,
	.#{$name}-center-end,
	.#{$name}-center-stretch
	{
		justify-content: center;
	}

	// Main Axis End
	.#{$name}-end, //stretch
	.#{$name}-end-start,
	.#{$name}-end-center,
	.#{$name}-end-end,
	.#{$name}-end-stretch
	{
		justify-content: flex-end;
	}

	// Main Axis Space Around
	.#{$name}-space-around, //stretch
	.#{$name}-space-around-center,
	.#{$name}-space-around-start,
	.#{$name}-space-around-end,
	.#{$name}-space-around-stretch
	{
		justify-content: space-around;
	}

	// Main Axis Space Between
	.#{$name}-space-between, //stretch
	.#{$name}-space-between-center,
	.#{$name}-space-between-start,
	.#{$name}-space-between-end,
	.#{$name}-space-between-stretch
	{
		justify-content: space-between;
	}

	// Arrange on the Cross Axis
	// center, start, end
	// stretch is the default for align-items
	// ------------------------------

	// Cross Axis Start
	.#{$name}-start-start,
	.#{$name}-center-start,
	.#{$name}-end-start,
	.#{$name}-space-between-start,
	.#{$name}-space-around-start
	{
		align-items: flex-start;
		align-content: flex-start;
	}

	// Cross Axis Center
	.#{$name}-start-center,
	.#{$name}-center-center,
	.#{$name}-end-center,
	.#{$name}-space-between-center,
	.#{$name}-space-around-center
	{
		align-items: center;
		align-content: center;
		max-width: 100%;
	}

	// Cross Axis Center IE overflow fix
	.#{$name}-start-center > *,
	.#{$name}-center-center > *,
	.#{$name}-end-center > *,
	.#{$name}-space-between-center > *,
	.#{$name}-space-around-center > *
	{
		max-width: 100%;
		box-sizing: border-box;
	}

	// Cross Axis End
	.#{$name}-start-end,
	.#{$name}-center-end,
	.#{$name}-end-end,
	.#{$name}-space-between-end,
	.#{$name}-space-around-end
	{
		align-items: flex-end;
		align-content: flex-end;
	}

	// Cross Axis Start
	.#{$name}-start-stretch,
	.#{$name}-center-stretch,
	.#{$name}-end-stretch,
	.#{$name}-space-between-stretch,
	.#{$name}-space-around-stretch
	{
		align-items: stretch;
		align-content: stretch;
	}
}

@mixin layout-padding-margin()
{

	// NOTE: these`> *` selectors should only be applied for layout="row" or layout="column" children !!
	.layout-padding-sm > *,
	.layout-padding > .flex-sm
	{
		padding: $layout-gutter-width * 0.25;
	}

	.layout-padding,
	.layout-padding-gt-sm,
	.layout-padding-md,

		// NOTE: these`> *` selectors should only be applied for layout="row" or layout="column" children !!
	.layout-padding > *,
	.layout-padding-gt-sm > *,
	.layout-padding-md > *,
	.layout-padding > .flex,
	.layout-padding > .flex-gt-sm,
	.layout-padding > .flex-md
	{
		padding: $layout-gutter-width * 0.5;
	}

	// NOTE: these`> *` selectors should only be applied for layout="row" or layout="column" children !!
	.layout-padding-gt-md > *,
	.layout-padding-lg > *,
	.layout-padding-gt-lg > *,
	.layout-padding > .flex-gt-md,
	.layout-padding > .flex-lg,
	.layout-padding > .flex-lg,
	.layout-padding > .flex-gt-lg
	{
		padding: math.div($layout-gutter-width, 1);
	}

	// Margin enhancements

	.layout-margin-sm > *,
	.layout-margin > .flex-sm
	{
		margin: $layout-gutter-width * 0.25;
	}

	.layout-margin,
	.layout-margin-gt-sm,
	.layout-margin-md,

		// NOTE: these`> *` selectors should only be applied for layout="row" or layout="column" children !!
	.layout-margin > *,
	.layout-margin-gt-sm > *,
	.layout-margin-md > *,
	.layout-margin > .flex,
	.layout-margin > .flex-gt-sm,
	.layout-margin > .flex-md
	{
		margin: $layout-gutter-width * 0.5;
	}

	// NOTE: these`> *` selectors should only be applied for layout="row" or layout="column" children !!
	.layout-margin-gt-md > *,
	.layout-margin-lg > *,
	.layout-margin-gt-lg > *,
	.layout-margin > .flex-gt-md,
	.layout-margin > .flex-lg,
	.layout-margin > .flex-gt-lg
	{
		margin: math.div($layout-gutter-width, 1);
	}

	.layout-wrap
	{
		flex-wrap: wrap;
	}

	.layout-nowrap
	{
		flex-wrap: nowrap;
	}

	.layout-fill
	{
		margin: 0;
		width: 100%;
		min-height: 100%;
		height: 100%;
	}
}

@mixin layouts_for_breakpoint($name:null)
{
	@include flex-order-for-name($name);
	@include offset-for-name($name);
	@include layout-align-for-name($name);

	@include flex-properties-for-name($name);
	@include layout-for-name($name);
}

.md-panel-outer-wrapper
{
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

._md-panel-hidden
{
	display: none;
}

._md-panel-fullscreen
{
	border-radius: 0;
	left: 0;
	min-height: 100%;
	min-width: 100%;
	position: fixed;
	top: 0;
}

// Only used when no animations are present.
._md-panel-shown .md-panel
{
	opacity: 1;
	transition: none;
}

.md-panel
{
	opacity: 0;
	position: fixed;

	&._md-panel-shown
	{
		// Only used when custom animations are present.
		// Overridden by the default animations.
		opacity: 1;
		transition: none;
	}

	&._md-panel-animate-enter
	{
		opacity: 1;
		transition: $material-enter;
	}

	&._md-panel-animate-leave
	{
		opacity: 1;
		transition: $material-leave;
	}

	&._md-panel-animate-scale-out,
	&._md-panel-animate-fade-out
	{
		opacity: 0;
	}

	&._md-panel-backdrop
	{
		height: 100%;
		position: absolute;
		width: 100%;
	}

	&._md-opaque-enter
	{
		opacity: .48;
		transition: opacity $material-enter-duration $material-enter-timing-function;
	}

	&._md-opaque-leave
	{
		transition: opacity $material-leave-duration $material-leave-timing-function;
	}
}

// !!Important - Theme-based Background-color can be configured in backdrop-theme.scss
//             - Animate background-color opacity only for `.md-opaque` styles

md-backdrop
{
	transition: opacity 450ms;

	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	z-index: $z-index-backdrop;

	&.dark
	{
		background: #000 !important;
	}

	&.md-menu-backdrop
	{
		position: fixed !important;
		z-index: $z-index-menu - 1;
	}
	&.md-select-backdrop
	{
		z-index: $z-index-dialog + 1;
		transition-duration: 0;
	}
	&.md-dialog-backdrop
	{
		z-index: $z-index-dialog - 1;
	}
	&.md-bottom-sheet-backdrop
	{
		z-index: $z-index-bottom-sheet - 1;
	}
	&.md-sidenav-backdrop
	{
		z-index: $z-index-sidenav - 1;
	}

	&.md-click-catcher
	{
		position: absolute;
	}

	&.md-opaque
	{
		opacity: .8;

		&.ng-enter
		{
			opacity: 0;
		}
		&.ng-enter.md-opaque.ng-enter-active
		{
			opacity: .48;
		}
		&.ng-leave
		{
			opacity: .48;
			transition: opacity 400ms;
		}
		&.ng-leave.md-opaque.ng-leave-active
		{
			opacity: 0;
		}
	}

}

$bottom-sheet-horizontal-padding: 2 * $baseline-grid !default;
$bottom-sheet-vertical-padding: 1 * $baseline-grid !default;
$bottom-sheet-icon-after-margin: 4 * $baseline-grid !default;
$bottom-sheet-list-item-height: 6 * $baseline-grid !default;
$bottom-sheet-hidden-bottom-padding: 80px !default;
$bottom-sheet-header-height: 7 * $baseline-grid !default;
$bottom-sheet-grid-font-weight: 400 !default;

md-bottom-sheet
{
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	padding: $bottom-sheet-vertical-padding $bottom-sheet-horizontal-padding $bottom-sheet-vertical-padding + $bottom-sheet-hidden-bottom-padding $bottom-sheet-horizontal-padding;
	z-index: $z-index-bottom-sheet;

	border-top-width: 1px;
	border-top-style: solid;

	transform: translate3d(0, $bottom-sheet-hidden-bottom-padding, 0);
	transition: $swift-ease-out;
	transition-property: transform;

	&.md-has-header
	{
		padding-top: 0;
	}

	&.ng-enter
	{
		opacity: 0;
		transform: translate3d(0, 100%, 0);
	}

	&.ng-enter-active
	{
		opacity: 1;
		display: block;
		transform: translate3d(0, $bottom-sheet-hidden-bottom-padding, 0) !important;
	}

	&.ng-leave-active
	{
		transform: translate3d(0, 100%, 0) !important;
		transition: $swift-ease-in;
	}

	.md-subheader
	{
		background-color: transparent;
		font-family: $font-family;
		line-height: $bottom-sheet-header-height;
		padding: 0;
		white-space: nowrap;
	}

	md-inline-icon
	{
		display: inline-block;
		height: 24px;
		width: 24px;
		fill: #444;
	}

	md-list-item
	{
		display: flex;
		outline: none;

		&:hover
		{
			cursor: pointer;
		}
	}

	&.md-list
	{
		md-list-item
		{
			padding: 0;
			align-items: center;
			height: $bottom-sheet-list-item-height;

		}
	}

	&.md-grid
	{
		padding-left: 3 * $baseline-grid;
		padding-right: 3 * $baseline-grid;
		padding-top: 0;

		md-list
		{
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			transition: all 0.5s;
			align-items: center;
		}

		md-list-item
		{
			flex-direction: column;
			align-items: center;
			transition: all 0.5s;

			height: 12 * $baseline-grid;

			margin-top: $baseline-grid;
			margin-bottom: $baseline-grid;

			/* Mixin for how many grid items to show per row */
			@mixin grid-items-per-row($num, $alignEdges: false)
			{
				$width: math.div(100%, $num);
				flex: 1 1 $width;
				max-width: $width;

				@if $alignEdges
				{
					&:nth-of-type(#{$num}n + 1)
					{
						align-items: flex-start;
					}
					&:nth-of-type(#{$num}n)
					{
						align-items: flex-end;
					}
				}
			}

			@media (max-width: $layout-breakpoint-sm)
			{
				@include grid-items-per-row(3, true);
			}

			@media (min-width: $layout-breakpoint-sm) and (max-width: $layout-breakpoint-md - 1)
			{
				@include grid-items-per-row(4);
			}

			@media (min-width: $layout-breakpoint-md) and (max-width: $layout-breakpoint-lg - 1)
			{
				@include grid-items-per-row(6);
			}

			@media (min-width: $layout-breakpoint-lg)
			{
				@include grid-items-per-row(7);
			}

			// Override of the IE11 fix from @mixin ie11-min-height-flexbug, line 109 mixins.scss
			&::before
			{
				display: none;
			}

			.md-list-item-content
			{
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 6 * $baseline-grid;
				padding-bottom: 2 * $baseline-grid;
			}

			.md-grid-item-content
			{
				border: 1px solid transparent;
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 10 * $baseline-grid;
			}

			.md-grid-text
			{
				font-weight: $bottom-sheet-grid-font-weight;
				line-height: 2 * $baseline-grid;
				font-size: 2 * $baseline-grid - 3;
				margin: 0;
				white-space: nowrap;
				width: 8 * $baseline-grid;
				text-align: center;
				text-transform: none;
				padding-top: 1 * $baseline-grid;
			}
		}
	}
}

// IE only
@media screen and (-ms-high-contrast: active)
{
	md-bottom-sheet
	{
		border: 1px solid #FFF;
	}
}

// Material Design Button
// https://material.google.com/components/buttons.html

$button-border-radius: 5px !default;
$button-fab-border-radius: 50% !default;
$button-icon-border-radius: $button-fab-border-radius !default;

$button-font-size: $body-font-size-base !default;
$button-font-size-dense: $body-font-size-base * 16*0.05 !default;

$button-line-height: rem(3.60) !default;
$button-line-height-dense: rem(3.20) !default;
$button-margin: rem(0.600) rem(0.800) !default;
$button-min-width: rem(8.800) !default;
$button-padding: 0 $button-left-right-padding !default;

// Fab buttons
$button-fab-line-height: rem(5.600) !default;
$button-fab-mini-width: rem(2.47) !default;
$button-fab-mini-height: rem(2.47) !default;
$button-fab-mini-line-height: rem(2.47) !default;

$button-fab-toast-offset: $button-fab-height * 0.75 !default;

$icon-button-height: rem(4.000) !default;
$icon-button-width: rem(4.000) !default;
$icon-button-margin: rem(0.600) !default;

// Fix issue causing buttons in Firefox to be 2px bigger than they should
button.md-button::-moz-focus-inner
{
	border: 0;
}

.md-button
{
	display: inline-block;
	position: relative; // Required for absolute canvas child elements.
	cursor: pointer;

	/** Alignment adjustments */
	@include dense(min-height, $button-line-height, $button-line-height-dense);
	min-width: $button-min-width;
	@include dense(line-height, $button-line-height, $button-line-height-dense);

	vertical-align: middle;
	align-items: center;
	text-align: center;

	border-radius: $button-border-radius;
	box-sizing: border-box;

	/* Reset default button appearance */
	user-select: none;
	outline: none;
	border: 0;

	/** Custom styling for button */
	padding: $button-padding;
	margin: $button-margin;

	background: transparent;
	color: currentColor;
	white-space: nowrap;

	/* Uppercase text content */
	text-transform: normal;
	font-weight: 900;
	@include dense(font-size, $button-font-size, $button-font-size-dense);
	font-style: inherit;
	font-variant: inherit;
	font-family: $font-family;
	text-decoration: none;

	// Ink Ripple should not create any overflow.
	overflow: hidden;

	transition: box-shadow $swift-ease-out-duration $swift-ease-out-timing-function,
	background-color $swift-ease-out-duration $swift-ease-out-timing-function;

	&:focus
	{
		outline: none;
	}

	&:hover, &:focus
	{
		text-decoration: none;
	}

	// By default $ngAnimate looks for transition durations on the element, when using ng-hide, ng-if, ng-show.
	// The .md-button has a transition duration applied, which means, that $ngAnimate delays the hide process.
	// To avoid this, we need to reset the transition, when $ngAnimate looks for the duration.
	&.ng-hide, &.ng-leave
	{
		transition: none;
	}

	&.md-cornered
	{
		border-radius: 0;
	}

	&.md-icon
	{
		padding: 0;
		background: none;
	}

	&.md-raised
	{
		color: #FFF;
		&:not([disabled])
		{
			@include md-shadow-bottom-z-1();
		}
	}

	&.md-icon-button
	{
		margin: 0 $icon-button-margin;
		height: $icon-button-height;
		min-width: 0;
		line-height: $icon-size;
		padding: $baseline-grid;
		width: $icon-button-width;
		border-radius: $button-icon-border-radius;
		.md-ripple-container
		{
			border-radius: $button-icon-border-radius;
			background-clip: padding-box;
			overflow: hidden;
			// The following hack causes Safari/Chrome to respect overflow hidden for ripples
			-webkit-mask-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC');
		}
	}

	&.md-fab
	{

		// Include the top/left/bottom/right fab positions
		@include fab-all-positions();

		z-index: $z-index-fab;

		line-height: $button-fab-line-height;

		min-width: 0;
		width: $button-fab-width;
		height: $button-fab-height;
		vertical-align: middle;

		@include md-shadow-bottom-z-1();
		border-radius: $button-fab-border-radius;
		background-clip: padding-box;
		overflow: hidden;

		transition: $swift-ease-in;
		transition-property: background-color, box-shadow, transform;
		.md-ripple-container
		{
			border-radius: $button-fab-border-radius;
			background-clip: padding-box;
			overflow: hidden;
			// The following hack causes Safari/Chrome to respect overflow hidden for ripples
			-webkit-mask-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC');
		}

		&.md-mini
		{
			line-height: $button-fab-mini-line-height;
			width: $button-fab-mini-width;
			height: $button-fab-mini-height;
			min-height: $button-fab-mini-height;
			&.md-primary
			{
				color: #FFF;
			}
		}

		&.ng-hide, &.ng-leave
		{
			transition: none;
		}
	}

	&:not([disabled])
	{
		&.md-raised,
		&.md-fab
		{
			&.md-focused
			{
				@include md-shadow-bottom-z-1();
			}
			&:active
			{
				@include md-shadow-bottom-z-2();
			}
		}
	}

	.md-ripple-container
	{
		border-radius: $button-border-radius;
		background-clip: padding-box;
		overflow: hidden;
		// The following hack causes Safari/Chrome to respect overflow hidden for ripples
		-webkit-mask-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC');
	}

	&.md-background
	{
		color: $maincolor-Blue-Light !important;
		border: 1px solid #FFF !important;
		&:hover
		{
			color: #FFF !important;
			background: transparent !important;
		}
	}
}

// Using `display:block;` is required for correct vertical alignment
// because '.md-button' uses `display:inline-block;`.
.md-button.md-icon-button,
button.md-button.md-fab
{
	md-icon
	{
		//display: block;
	}
}

.md-toast-open-top
{
	.md-button.md-fab-top-left,
	.md-button.md-fab-top-right
	{
		transition: $swift-ease-out;
		transform: translate3d(0, $button-fab-toast-offset, 0);
		&:not([disabled])
		{
			&.md-focused,
			&:hover
			{
				transform: translate3d(0, $button-fab-toast-offset - 1, 0);
			}
		}
	}
}

.md-toast-open-bottom
{
	.md-button.md-fab-bottom-left,
	.md-button.md-fab-bottom-right
	{
		transition: $swift-ease-out;
		transform: translate3d(0, -$button-fab-toast-offset, 0);
		&:not([disabled])
		{
			&.md-focused,
			&:hover
			{
				transform: translate3d(0, -$button-fab-toast-offset - 1, 0);
			}
		}
	}
}

md-toast.md-bottom
{
	position: fixed;
	bottom: 20px;
}

.md-button-group
{
	display: flex;
	flex: 1;
	width: 100%;

	& > .md-button
	{
		flex: 1;

		display: block;

		overflow: hidden;

		width: 0;

		border-width: 1px 0px 1px 1px;
		border-radius: 0;

		text-align: center;
		text-overflow: ellipsis;
		white-space: nowrap;

		&:first-child
		{
			border-radius: 2px 0px 0px 2px;
		}
		&:last-child
		{
			border-right-width: 1px;
			border-radius: 0px 2px 2px 0px;
		}
	}
}

// IE only
@media screen and (-ms-high-contrast: active)
{
	.md-button.md-raised,
	.md-button.md-fab
	{
		border: 1px solid #FFF;
	}
}

$card-padding: 16px !default;
$card-box-shadow: $whiteframe-shadow-1dp !default;

md-card
{
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	margin: $baseline-grid;

	box-shadow: $card-box-shadow;

	md-card-header
	{
		padding: $card-padding;
		display: flex;
		flex-direction: row;

		&:first-child
		{
			md-card-avatar
			{
				@include rtl-prop(margin-right, margin-left, 12px, auto);
			}
		}

		&:last-child
		{
			md-card-avatar
			{
				@include rtl-prop(margin-left, margin-right, 12px, auto);
			}
		}

		md-card-avatar
		{
			width: 40px;
			height: 40px;

			.md-user-avatar,
			md-icon
			{
				border-radius: 50%;
			}

			md-icon
			{
				padding: 8px;
				> svg
				{
					// Safari workaround for any SVG with padded parent
					height: inherit;
					width: inherit;
				}
			}

			& + md-card-header-text
			{
				max-height: 40px;

				.md-title
				{
					font-size: 14px;
				}
			}
		}

		md-card-header-text
		{
			display: flex;
			flex: 1;
			flex-direction: column;

			.md-subhead
			{
				font-size: 14px;
			}
		}
	}

	> img,
	> md-card-header img,
	md-card-title-media img
	{
		box-sizing: border-box;
		display: flex;
		flex: 0 0 auto;
		width: 100%;
		height: auto;
	}

	md-card-title
	{
		padding: 3 * $card-padding * 0.5 $card-padding $card-padding;
		display: flex;
		flex: 1 1 auto;
		flex-direction: row;

		& + md-card-content
		{
			padding-top: 0;
		}

		md-card-title-text
		{
			flex: 1;
			flex-direction: column;
			display: flex;

			.md-subhead
			{
				padding-top: 0;
				font-size: 14px;
			}

			&:only-child
			{
				.md-subhead
				{
					padding-top: 3 * $card-padding * 0.25;
				}
			}
		}

		md-card-title-media
		{
			margin-top: - $card-padding * 0.5;

			.md-media-sm
			{
				height: 80px;
				width: 80px;
			}
			.md-media-md
			{
				height: 112px;
				width: 112px;
			}
			.md-media-lg
			{
				height: 152px;
				width: 152px;
			}
		}
	}

	md-card-content
	{
		display: block;
		padding: $card-padding;

		& > p
		{
			&:first-child
			{
				margin-top: 0;
			}

			&:last-child
			{
				margin-bottom: 0;
			}
		}

		.md-media-xl
		{
			height: 240px;
			width: 240px;
		}
	}

	.md-actions, md-card-actions
	{
		margin: $baseline-grid;

		&.layout-column
		{
			.md-button
			{
				&:not(.md-icon-button)
				{
					margin: $baseline-grid * 0.25 0;

					&:first-of-type
					{
						margin-top: 0;
					}

					&:last-of-type
					{
						margin-bottom: 0;
					}
				}

				&.md-icon-button
				{
					margin-top: 3 * $baseline-grid * 0.25;
					margin-bottom: 3 * $baseline-grid * 0.25;
				}
			}
		}

		md-card-icon-actions
		{
			flex: 1;
			justify-content: flex-start;
			display: flex;
			flex-direction: row;
		}

		&:not(.layout-column) .md-button
		{
			&:not(.md-icon-button)
			{
				margin: 0 $baseline-grid * .5;

				&:first-of-type
				{
					@include rtl-prop(margin-left, margin-right, 0, auto);
				}

				&:last-of-type
				{
					@include rtl-prop(margin-right, margin-left, 0, auto);
				}
			}

			&.md-icon-button
			{
				margin-left: 3 * $baseline-grid * 0.25;
				margin-right: 3 * $baseline-grid * 0.25;

				&:first-of-type
				{
					@include rtl-prop(margin-left, margin-right, 3 * $baseline-grid * 0.5, auto);
				}

				&:last-of-type
				{
					@include rtl-prop(margin-right, margin-left, 3 * $baseline-grid * 0.5, auto);
				}
			}

			& + md-card-icon-actions
			{
				flex: 1;
				justify-content: flex-end;
				display: flex;
				flex-direction: row;
			}
		}
	}

	md-card-footer
	{
		margin-top: auto;
		padding: $card-padding;
	}
}

@media screen and (-ms-high-contrast: active)
{
	md-card
	{
		border: 1px solid #FFF;
	}
}

.md-image-no-fill
{
	> img
	{
		width: auto;
		height: auto;
	}
}

//$checkbox-width: 20px !default;
//$checkbox-height: $checkbox-width !default;
//$checkbox-border-radius: 2px !default;
//$checkbox-border-width: 2px !default;
//
// ^^ defined in variables.scss
//
$checkbox-margin: 16px !default;
$checkbox-text-margin: 10px !default;
$checkbox-top: 12px !default;

.md-inline-form
{
	md-checkbox
	{
		margin: 19px 0 18px;
	}
}

md-checkbox
{
	box-sizing: border-box;
	display: inline-block;
	margin-bottom: $checkbox-margin;
	white-space: nowrap;
	cursor: pointer;
	outline: none;
	user-select: none;
	position: relative;
	min-width: $checkbox-width;
	min-height: $checkbox-width;
	@include rtl(margin-left, 0, $checkbox-margin);
	@include rtl(margin-right, $checkbox-margin, 0);

	&:last-of-type
	{
		margin-left: 0;
		margin-right: 0;
	}

	&.md-focused:not([disabled])
	{
		.md-container:before
		{
			left: -8px;
			top: -8px;
			right: -8px;
			bottom: -8px;
		}

		&:not(.md-checked)
		{
			.md-container:before
			{
				background-color: rgba(0, 0, 0, 0.12);
			}
		}
	}

	&.md-align-top-left > div.md-container
	{
		top: $checkbox-top;
	}

	@include checkbox-container;

	.md-label
	{
		box-sizing: border-box;
		position: relative;
		display: inline-block;
		vertical-align: middle;
		white-space: normal;
		user-select: text;

		@include rtl(margin-left, $checkbox-text-margin + $checkbox-width, 0);
		@include rtl(margin-right, 0, $checkbox-text-margin + $checkbox-width);

	}
}

// The default item height is also specified in the JavaScript.
$md-autocomplete-item-height: 48px !default;
$md-autocomplete-clear-size: 30px !default;
$md-autocomplete-input-offset: 20px !default;

md-autocomplete
{
	border-radius: 2px;
	display: block;
	height: 40px;
	position: relative;
	overflow: visible;
	min-width: 190px;
	&[disabled]
	{
		input
		{
			cursor: default;
		}
	}
	&[md-floating-label]
	{
		border-radius: 0;
		background: transparent;
		height: auto;

		md-input-container
		{
			padding-bottom: 0;
		}
		md-autocomplete-wrap
		{
			height: auto;
		}

		.md-show-clear-button
		{

			button
			{
				display: block;
				position: absolute;
				right: 0;
				top: $md-autocomplete-input-offset;
				width: $md-autocomplete-clear-size;
				height: $md-autocomplete-clear-size;
			}

			input
			{
				// Add padding to the end of the input to avoid overlapping with the clear button.
				@include rtl-prop(padding-right, padding-left, $md-autocomplete-clear-size, 0);
			}
		}

	}
	md-autocomplete-wrap
	{

		// Layout [layout='row']
		display: flex;
		flex-direction: row;
		box-sizing: border-box;

		position: relative;
		overflow: visible;
		height: 40px;
		&.md-menu-showing
		{
			z-index: $z-index-backdrop + 1;
		}

		md-input-container, input
		{
			// Layout [flex]
			flex: 1 1 0%;
			box-sizing: border-box;
			min-width: 0;
		}

		md-progress-linear
		{
			position: absolute;
			bottom: -2px;
			left: 0;
			// When `md-inline` is present, we adjust the offset to go over the `ng-message` space
			&.md-inline
			{
				bottom: 40px;
				right: 2px;
				left: 2px;
				width: auto;
			}
			.md-mode-indeterminate
			{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 3px;
				transition: none;

				.md-container
				{
					transition: none;
					height: 3px;
				}
				&.ng-enter
				{
					transition: opacity 0.15s linear;
					&.ng-enter-active
					{
						opacity: 1;
					}
				}
				&.ng-leave
				{
					transition: opacity 0.15s linear;
					&.ng-leave-active
					{
						opacity: 0;
					}
				}
			}
		}
	}
	input:not(.md-input)
	{
		@include md-flat-input();
		width: 100%;
		padding: 0 15px;
		line-height: 40px;
		height: 40px;
	}
	.md-show-clear-button button
	{
		position: relative;
		line-height: 20px;
		text-align: center;
		width: $md-autocomplete-clear-size;
		height: $md-autocomplete-clear-size;
		cursor: pointer;
		border: none;
		border-radius: 50%;
		padding: 0;
		font-size: 12px;
		background: transparent;
		margin: auto 5px;
		&:after
		{
			content: '';
			position: absolute;
			top: -6px;
			right: -6px;
			bottom: -6px;
			left: -6px;
			border-radius: 50%;
			transform: scale(0);
			opacity: 0;
			transition: $swift-ease-out;
		}
		&:focus
		{
			outline: none;

			&:after
			{
				transform: scale(1);
				opacity: 1;
			}
		}
		md-icon
		{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate3d(-50%, -50%, 0) scale(0.9);
			path
			{
				stroke-width: 0;
			}
		}
		&.ng-enter
		{
			transform: scale(0);
			transition: transform 0.15s ease-out;
			&.ng-enter-active
			{
				transform: scale(1);
			}
		}
		&.ng-leave
		{
			transition: transform 0.15s ease-out;
			&.ng-leave-active
			{
				transform: scale(0);
			}
		}
	}
	// IE Only
	@media screen and (-ms-high-contrast: active)
	{
		$border-color: #FFF;

		input
		{
			border: 1px solid $border-color;
		}
		li:focus
		{
			color: #FFF;
		}
	}
}

.md-virtual-repeat-container.md-autocomplete-suggestions-container
{
	position: absolute;
	box-shadow: 0 2px 5px rgba(black, 0.25);
	z-index: $z-index-tooltip;

	// Expand the virtualRepeatContainer as much as the max-height from the JavaScript allows.
	// This is necessary for the virtualRepeatContainer to be able to grow back.
	height: 100%;
}

.md-virtual-repeat-container.md-not-found
{
	height: $md-autocomplete-item-height;
}

.md-autocomplete-suggestions
{
	margin: 0;
	list-style: none;
	padding: 0;

	li
	{
		font-size: 14px;
		overflow: hidden;
		padding: 0 15px;
		line-height: $md-autocomplete-item-height;
		height: $md-autocomplete-item-height;
		transition: background 0.15s linear;
		margin: 0;
		white-space: nowrap;
		text-overflow: ellipsis;

		&:focus
		{
			outline: none;
		}

		&:not(.md-not-found-wrapper)
		{
			cursor: pointer;
		}
	}
}

// IE Only
@media screen and (-ms-high-contrast: active)
{
	md-autocomplete,
	.md-autocomplete-suggestions
	{
		border: 1px solid #FFF;
	}
}

md-content
{
	display: block;
	position: relative;
	overflow: visible;
	-webkit-overflow-scrolling: touch;

	&[md-scroll-y]
	{
		overflow-y: auto;
		overflow-x: hidden;
	}
	&[md-scroll-x]
	{
		overflow-x: auto;
		overflow-y: hidden;
	}
	&[md-scroll-xy]
	{
	}

	@media print
	{
		overflow: visible !important;
	}
}

md-divider
{
	display: block;
	border-top-width: 1px;
	border-top-style: solid;
	margin: 0;

	&[md-inset]
	{
		@include rtl-prop(margin-left, margin-right, $baseline-grid * 10, auto);
	}
}

.layout-row,
.layout-xs-row, .layout-gt-xs-row,
.layout-sm-row, .layout-gt-sm-row,
.layout-md-row, .layout-gt-md-row,
.layout-lg-row, .layout-gt-lg-row,
.layout-xl-row
{
	& > md-divider
	{
		border-top-width: 0;
		border-right-width: 1px;
		border-right-style: solid;
	}
}

$chip-font-size: rem(1.6) !default;
$chip-height: rem(3.2) !default;
$chip-padding: 0 rem(1.2) 0 rem(1.2) !default;
$chip-input-padding: 0 !default;
$chip-remove-padding-right: rem(2.2) !default;
$chip-remove-line-height: rem(2.2) !default;
$chip-margin: rem(0.8) rem(0.8) 0 0 !default;
$chip-wrap-padding: 0 0 rem(0.8) rem(0.3) !default;
$chip-delete-icon-size: rem(1.8) !default;
$contact-chip-padding: 0 rem(2.5) 0 0 !default;
$contact-chip-suggestion-image-height: rem(4.0) !default;
$contact-chip-suggestion-margin: rem(0.8) !default;
$contact-chip-name-width: rem(12) !default;

.md-contact-chips
{
	.md-chips
	{
		md-chip
		{
			@include rtl(padding, $contact-chip-padding, rtl-value($contact-chip-padding));
			.md-contact-avatar
			{
				@include rtl(float, left, right);
				img
				{
					height: $chip-height;
					border-radius: $chip-height * 0.5;
				}
			}
			.md-contact-name
			{
				display: inline-block;
				height: $chip-height;
				@include rtl-prop(margin-left, margin-right, rem(0.8), auto);
			}
		}
	}
}

.md-contact-suggestion
{
	height: ($contact-chip-suggestion-margin * 2) + $contact-chip-suggestion-image-height;
	img
	{
		height: $contact-chip-suggestion-image-height;
		border-radius: $contact-chip-suggestion-image-height * 0.5;
		margin-top: $contact-chip-suggestion-margin;
	}
	.md-contact-name
	{
		@include rtl-prop(margin-left, margin-right, $contact-chip-suggestion-margin, auto);
		width: $contact-chip-name-width;
	}
	.md-contact-name, .md-contact-email
	{
		display: inline-block;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.md-contact-chips-suggestions li
{
	height: 100%;
}

.md-chips
{
	@include pie-clearfix();

	display: block;
	font-family: $font-family;
	font-size: $chip-font-size;
	@include rtl(padding, $chip-wrap-padding, rtl-value($chip-wrap-padding));
	vertical-align: middle;

	&.md-readonly .md-chip-input-container
	{
		min-height: $chip-height;
	}

	&:not(.md-readonly)
	{
		cursor: text;
	}

	&.md-removable
	{

		md-chip
		{
			@include rtl-prop(padding-right, padding-left, $chip-remove-padding-right, 0);

			.md-chip-content
			{
				@include rtl-prop(padding-right, padding-left, rem(0.4), 0);
			}
		}

	}

	md-chip
	{
		cursor: default;
		border-radius: $chip-height * 0.5;
		display: block;
		height: $chip-height;
		line-height: $chip-height;
		@include rtl(margin, $chip-margin, rtl-value($chip-margin));
		padding: $chip-padding;
		@include rtl(float, left, right);
		box-sizing: border-box;
		max-width: 100%;
		position: relative;

		.md-chip-content
		{
			display: block;
			@include rtl(float, left, right);
			white-space: nowrap;
			max-width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
			&:focus
			{
				outline: none;
			}
		}
		&._md-chip-content-edit-is-enabled
		{
			-webkit-user-select: none; /* webkit (safari, chrome) browsers */
			-moz-user-select: none; /* mozilla browsers */
			-khtml-user-select: none; /* webkit (konqueror) browsers */
			-ms-user-select: none; /* IE10+ */
		}
		.md-chip-remove-container
		{
			position: absolute;
			@include rtl-prop(right, left, 0, auto);
			line-height: $chip-remove-line-height;
		}
		.md-chip-remove
		{
			text-align: center;
			width: $chip-height;
			height: $chip-height;
			min-width: 0;
			padding: 0;
			background: transparent;
			border: none;
			box-shadow: none;
			margin: 0;
			position: relative;
			md-icon
			{
				height: $chip-delete-icon-size;
				width: $chip-delete-icon-size;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate3d(-50%, -50%, 0);
			}
		}
	}
	.md-chip-input-container
	{
		display: block;
		line-height: $chip-height;
		@include rtl(margin, $chip-margin, rtl-value($chip-margin));
		padding: $chip-input-padding;
		@include rtl(float, left, right);
		input
		{
			&:not([type]), &[type="email"], &[type="number"], &[type="tel"], &[type="url"], &[type="text"]
			{
				border: 0;
				height: $chip-height;
				line-height: $chip-height;
				padding: 0;
				&:focus
				{
					outline: none;
				}
			}
		}
		md-autocomplete, md-autocomplete-wrap
		{
			background: transparent;
			height: $chip-height;
		}
		md-autocomplete
		{
			md-autocomplete-wrap
			{
				box-shadow: none;
			}
			input
			{
				position: relative;
			}
		}
		input
		{
			border: 0;
			height: $chip-height;
			line-height: $chip-height;
			padding: 0;
			&:focus
			{
				outline: none;
			}
		}
		md-autocomplete, md-autocomplete-wrap
		{
			height: $chip-height;
		}
		md-autocomplete
		{
			box-shadow: none;
			input
			{
				position: relative;
			}
		}
		&:not(:first-child)
		{
			@include rtl(margin, $chip-margin, rtl-value($chip-margin));
		}
		input
		{
			background: transparent;
			border-width: 0;
		}
	}
	md-autocomplete
	{
		button
		{
			display: none;
		}
	}
}

// IE only
@media screen and (-ms-high-contrast: active)
{
	.md-chip-input-container,
	md-chip
	{
		border: 1px solid #FFF;
	}
	.md-chip-input-container md-autocomplete
	{
		border: none;
	}
}

md-fab-speed-dial
{
	position: relative;
	display: flex;
	align-items: center;

	// Include the top/left/bottom/right fab positions and set the z-index for absolute positioning
	@include fab-all-positions();
	z-index: $z-index-fab;

	// Allow users to enable/disable hovering over the entire speed dial (i.e. the empty space where
	// items will eventually appear)
	&:not(.md-hover-full)
	{
		// Turn off pointer events when closed
		pointer-events: none;

		md-fab-trigger, .md-fab-action-item
		{
			// Always make the trigger and action items always have pointer events (the tooltip looks
			// for the first parent with pointer-events, so we must set this for tooltips to work)
			pointer-events: auto;
		}

		&.md-is-open
		{
			// Turn on pointer events when open
			pointer-events: auto;
		}
	}

	._md-css-variables
	{
		z-index: $z-index-fab;
	}

	&.md-is-open
	{
		.md-fab-action-item
		{
			align-items: center;
		}
	}

	md-fab-actions
	{
		display: flex;

		// Set the height so that the z-index in the JS animation works
		height: auto;

		.md-fab-action-item
		{
			transition: $swift-ease-in;
		}
	}

	&.md-down
	{
		flex-direction: column;

		md-fab-trigger
		{
			order: 1;
		}

		md-fab-actions
		{
			flex-direction: column;
			order: 2;
		}
	}

	&.md-up
	{
		flex-direction: column;

		md-fab-trigger
		{
			order: 2;
		}

		md-fab-actions
		{
			flex-direction: column-reverse;
			order: 1;
		}
	}

	&.md-left
	{
		flex-direction: row;

		md-fab-trigger
		{
			order: 2;
		}

		md-fab-actions
		{
			flex-direction: row-reverse;
			order: 1;

			.md-fab-action-item
			{
				transition: $swift-ease-in;
			}
		}
	}

	&.md-right
	{
		flex-direction: row;

		md-fab-trigger
		{
			order: 1;
		}

		md-fab-actions
		{
			flex-direction: row;
			order: 2;

			.md-fab-action-item
			{
				transition: $swift-ease-in;
			}
		}
	}

	/*
   * Hide some graphics glitches if switching animation types
   */
	&.md-fling-remove, &.md-scale-remove
	{
		.md-fab-action-item > *
		{
			visibility: hidden;
		}
	}

	/*
   * Handle the animations
   */
	&.md-fling
	{
		.md-fab-action-item
		{
			opacity: 1;
		}
	}

	// For the initial animation, set the duration to be instant
	&.md-fling.md-animations-waiting
	{
		.md-fab-action-item
		{
			opacity: 0;
			transition-duration: 0s;
		}
	}

	&.md-scale
	{
		.md-fab-action-item
		{
			transform: scale(0);
			transition: $swift-ease-in;

			// Make the scale animation a bit faster since we are delaying each item
			transition-duration: math.div($swift-ease-in-duration, 2.1);
		}
	}
}

/** Styles for mdCalendar. */
$md-calendar-cell-size: 44px !default;
$md-calendar-header-height: 40px !default;
$md-calendar-cell-emphasis-size: 40px !default;
$md-calendar-side-padding: 16px !default;
$md-calendar-weeks-to-show: 7 !default;

$md-calendar-month-label-padding: 8px !default;
$md-calendar-month-label-font-size: 14px !default;

$md-calendar-scroll-cue-shadow-radius: 6px !default;

$md-calendar-width: (7 * $md-calendar-cell-size) + (2 * $md-calendar-side-padding) !default;
$md-calendar-height: ($md-calendar-weeks-to-show * $md-calendar-cell-size) + $md-calendar-header-height !default;

// Styles for date cells, including day-of-the-week header cells.
@mixin md-calendar-cell($height: $md-calendar-cell-size)
{
	height: $height;
	width: $md-calendar-cell-size;
	text-align: center;

	// Remove all padding and borders so we can completely
	// control the size of the table cells.
	padding: 0;
	border: none;

	// Prevent issues if somebody is applying box-sizing: border-box; eveywhere.
	box-sizing: content-box;

	// The left / right padding is applied to the cells instead of the wrapper
	// because we want the header background and the month dividing border to
	// extend the entire width of the calendar.
	&:first-child
	{
		@include rtl-prop(padding-left, padding-right, $md-calendar-side-padding, 0);
	}

	&:last-child
	{
		@include rtl-prop(padding-right, padding-left, $md-calendar-side-padding, 0);
	}
}

// Styles for tables used in mdCalendar (the day-of-the-week header and the table of dates itself).
@mixin md-calendar-table()
{
	// Fixed table layout makes IE faster.
	// https://msdn.microsoft.com/en-us/library/ms533020(VS.85).aspx
	table-layout: fixed;
	border-spacing: 0;
	border-collapse: collapse;
}

md-calendar
{
	font-size: 13px;
	user-select: none;
}

// Wrap the scroll with overflow: hidden in order to hide the scrollbar.
// The inner .md-calendar-scroll-container will using a padding-right to push the
// scrollbar into the hidden area (done with javascript).
.md-calendar-scroll-mask
{
	display: inline-block;
	overflow: hidden;
	height: $md-calendar-weeks-to-show * $md-calendar-cell-size;

	// The actual scrolling element.
	.md-virtual-repeat-scroller
	{
		// These two properties are needed to get touch momentum to work.
		// See https://css-tricks.com/snippets/css/momentum-scrolling-on-ios-overflow-elements
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;

		&::-webkit-scrollbar
		{
			display: none;
		}
	}

	// Offsetter is the element that is translateY'ed into view of the user and contains the
	// calendar content.
	.md-virtual-repeat-offsetter
	{
		width: 100%;
	}
}

// Contains the scrolling element (this is the md-virtual-repeat-container).
.md-calendar-scroll-container
{
	// Add an inset shadow to help cue users that the calendar is scrollable. Use a negative x
	// offset to push the vertical edge shadow off to the right so that it's cut off by the edge
	// of the calendar container.
	box-shadow: inset -3px 3px $md-calendar-scroll-cue-shadow-radius rgba(black, 0.2);

	display: inline-block;
	height: $md-calendar-weeks-to-show * $md-calendar-cell-size;

	// Add the shadow radius to the width so that the shadow os pushed off to the side and cut off.
	width: $md-calendar-width + $md-calendar-scroll-cue-shadow-radius;
}

// A single date cell in the calendar table.
.md-calendar-date
{
	@include md-calendar-cell();

	&.md-calendar-date-disabled
	{
		cursor: default;
	}
}

// Circle element inside of every date cell used to indicate selection or focus.
.md-calendar-date-selection-indicator
{
	transition: background-color, color $swift-ease-out-duration $swift-ease-out-timing-function;

	border-radius: 50%;
	display: inline-block;

	width: $md-calendar-cell-emphasis-size;
	height: $md-calendar-cell-emphasis-size;
	line-height: $md-calendar-cell-emphasis-size;

	.md-calendar-date:not(.md-disabled) &
	{
		cursor: pointer;
	}
}

// The label above each month (containing the month name and the year, e.g. "Jun 2014").
.md-calendar-month-label
{
	height: $md-calendar-cell-size;
	font-size: $md-calendar-month-label-font-size;
	font-weight: 500; // Roboto Medium
	@include rtl(padding, 0 0 0 $md-calendar-side-padding + $md-calendar-month-label-padding, rtl-value(0 0 0 $md-calendar-side-padding + $md-calendar-month-label-padding));

	md-calendar-month &:not(.md-calendar-month-label-disabled)
	{
		cursor: pointer;
	}

	md-icon
	{
		@include rtl(transform, rotate(180deg), none);
	}

	span
	{
		vertical-align: middle;
	}
}

// Table containing the day-of-the-week header.
.md-calendar-day-header
{
	@include md-calendar-table();

	th
	{
		@include md-calendar-cell($md-calendar-header-height);
		font-weight: normal;
	}
}

// Primary table containing all date cells. Each month is a tbody in this table.
.md-calendar
{
	@include md-calendar-table();

	// Divider between months.
	tr:last-child td
	{
		border-bottom-width: 1px;
		border-bottom-style: solid;
	}

	// The divider between months doesn't actually change the height of the tbody in which the
	// border appear; it changes the height of the following tbody. The causes the first-child to be
	// 1px shorter than the other months. We fix this by adding an invisible border-top.
	&:first-child
	{
		border-top: 1px solid transparent;
	}

	// Explicitly set vertical-align to avoid conflicting with popular CSS resets. When
	// vertical-align:baseline is set, month headers are misaligned. Also reset the box-sizing,
	// in case the user set it to border-box.
	// http://meyerweb.com/eric/tools/css/reset/
	tbody, td, tr
	{
		vertical-align: middle;
		box-sizing: content-box;
	}
}

/** Styles for mdDatepicker. */
$md-datepicker-button-gap: 12px !default; // Space between the text input and the calendar-icon button.
$md-datepicker-border-bottom-gap: 5px !default; // Space between input and the grey underline.
$md-date-arrow-size: 5px !default; // Size of the triangle on the right side of the input.
$md-datepicker-open-animation-duration: 0.2s !default;
$md-datepicker-triangle-button-width: 36px !default;
$md-datepicker-input-mask-height: 40px !default;
$md-datepicker-button-padding: 8px !default;

md-datepicker
{
	// Don't let linebreaks happen between the open icon-button and the input.
	white-space: nowrap;
	overflow: hidden;
	vertical-align: middle;
}

.md-inline-form
{
	md-datepicker
	{
		margin-top: 12px;
	}
}

// The calendar icon button used to open the calendar pane.
.md-datepicker-button
{
	display: inline-block;
	box-sizing: border-box;
	background: none;
	vertical-align: middle;
	position: relative;

	// Captures any of the click events. This is necessary, because the button has a SVG
	// icon which doesn't propagate the focus event, causing inconsistent behaviour.
	&:before
	{
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		position: absolute;
		content: '';
		speak: none;
	}
}

// The input into which the user can type the date.
.md-datepicker-input
{
	@include md-flat-input();
	min-width: 120px;
	max-width: $md-calendar-width - $md-datepicker-button-gap;
	padding: 0 0 $md-datepicker-border-bottom-gap;
}

// If the datepicker is inside of a md-input-container
._md-datepicker-floating-label
{
	> md-datepicker
	{
		// Prevents the ripple on the triangle from being clipped.
		overflow: visible;

		.md-datepicker-input-container
		{
			border: none;
		}

		.md-datepicker-button
		{
			// Prevents the button from wrapping around, as well as it pushing
			// down the error messages more than they should be.
			@include rtl(float, left, right);
			margin-top: $button-left-right-padding * -2;
			top: $button-left-right-padding * 2 - $md-datepicker-border-bottom-gap * 0.5;
		}
	}

	.md-input
	{
		float: none;
	}

	&._md-datepicker-has-calendar-icon
	{
		> label:not(.md-no-float):not(.md-container-ignore)
		{
			$width-offset: $md-datepicker-triangle-button-width * 2 + $md-datepicker-button-gap;
			$offset: $md-datepicker-triangle-button-width * 0.5;
			@include rtl(right, $offset, auto);
			@include rtl(left, auto, $offset);
			width: calc(100% - #{$width-offset});
		}

		.md-input-message-animation
		{
			$margin: $md-datepicker-triangle-button-width + $md-datepicker-button-padding * 2 + $md-datepicker-button-gap;
			@include rtl-prop(margin-left, margin-right, $margin, auto);
		}
	}
}

._md-datepicker-has-triangle-icon
{
	// Leave room for the down-triangle button to "overflow" it's parent without modifying scrollLeft.
	// This prevents the element from shifting right when opening via the triangle button.
	@include rtl-prop(padding-right, padding-left, $md-datepicker-triangle-button-width * 0.5, 0);
	@include rtl-prop(margin-right, margin-left, -$md-datepicker-triangle-button-width * 0.5, auto);
}

// Container for the datepicker input.
.md-datepicker-input-container
{
	// Position relative in order to absolutely position the down-triangle button within.
	position: relative;

	border-bottom-width: 1px;
	border-bottom-style: solid;

	display: inline-block;
	width: auto;

	.md-icon-button + &
	{
		@include rtl-prop(margin-left, margin-right, $md-datepicker-button-gap, auto);
	}

	&.md-datepicker-focused
	{
		border-bottom-width: 2px;
	}
}

.md-datepicker-is-showing .md-scroll-mask
{
	z-index: $z-index-calendar-pane - 1;
}

// Floating pane that contains the calendar at the bottom of the input.
.md-datepicker-calendar-pane
{
	// On most browsers the `scale(0)` below prevents this element from
	// overflowing it's parent, however IE and Edge seem to disregard it.
	// The `left: -100%` pulls the element back in order to ensure that
	// it doesn't cause an overflow.
	position: absolute;
	top: 0;
	left: -100%;
	z-index: $z-index-calendar-pane;
	border-width: 1px;
	border-style: solid;
	background: transparent;

	transform: scale(0);
	transform-origin: 0 0;
	transition: transform $md-datepicker-open-animation-duration $swift-ease-out-timing-function;

	&.md-pane-open
	{
		transform: scale(1);
	}
}

// Portion of the floating panel that sits, invisibly, on top of the input.
.md-datepicker-input-mask
{
	height: $md-datepicker-input-mask-height;
	width: $md-calendar-width;
	position: relative;
	overflow: hidden;

	background: transparent;
	pointer-events: none;
	cursor: text;
}

// The calendar portion of the floating pane (vs. the input mask).
.md-datepicker-calendar
{
	opacity: 0;
	// Use a modified timing function (from swift-ease-out) so that the opacity part of the
	// animation doesn't come in as quickly so that the floating pane doesn't ever seem to
	// cover up the trigger input.
	transition: opacity $md-datepicker-open-animation-duration cubic-bezier(0.5, 0, 0.25, 1);

	.md-pane-open &
	{
		opacity: 1;
	}

	md-calendar:focus
	{
		outline: none;
	}
}

// Down triangle/arrow indicating that the datepicker can be opened.
// We can do this entirely with CSS without needing to load an icon.
// See https://css-tricks.com/snippets/css/css-triangle/
.md-datepicker-expand-triangle
{
	// Center the triangle inside of the button so that the
	// ink ripple origin looks correct.
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	width: 0;
	height: 0;
	border-left: $md-date-arrow-size solid transparent;
	border-right: $md-date-arrow-size solid transparent;
	border-top: $md-date-arrow-size solid;
}

// Button containing the down "disclosure" triangle/arrow.
.md-datepicker-triangle-button
{
	position: absolute;
	@include rtl-prop(right, left, 0, auto);
	bottom: -$md-date-arrow-size * 0.5;

	// TODO(jelbourn): This position isn't great on all platforms.
	@include rtl(transform, translateX(45%), translateX(-45%));
}

// Need crazy specificity to override .md-button.md-icon-button.
// Only apply this high specificity to the property we need to override.
.md-datepicker-triangle-button.md-button.md-icon-button
{
	height: $md-datepicker-triangle-button-width;
	width: $md-datepicker-triangle-button-width;
	position: absolute;
	padding: $md-datepicker-button-padding;
}

// Disabled state for all elements of the picker.
md-datepicker[disabled]
{
	.md-datepicker-input-container
	{
		border-bottom-color: transparent;
	}

	.md-datepicker-triangle-button
	{
		display: none;
	}
}

// Open state for all of the elements of the picker.
.md-datepicker-open
{
	overflow: hidden;

	.md-datepicker-input-container,
	input.md-input
	{
		border-bottom-color: transparent;
	}

	.md-datepicker-triangle-button,
	&.md-input-has-value > label,
	&.md-input-has-placeholder > label
	{
		display: none;
	}
}

// When the position of the floating calendar pane is adjusted to remain inside
// of the viewport, hide the inputput mask, as the text input will no longer be
// directly underneath it.
.md-datepicker-pos-adjusted .md-datepicker-input-mask
{
	display: none;
}

// Animate the calendar inside of the floating calendar pane such that it appears to "scroll" into
// view while the pane is opening. This is done as a cue to users that the calendar is scrollable.
.md-datepicker-calendar-pane
{
	.md-calendar
	{
		transform: translateY(-85px);
		transition: transform 0.65s $swift-ease-out-timing-function;
		transition-delay: 0.125s;
	}

	&.md-pane-open .md-calendar
	{
		transform: translateY(0);
	}
}

$dialog-padding: $baseline-grid * 3 !default;

.md-dialog-is-showing
{
	max-height: 100%;
}

.md-dialog-container
{
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: $z-index-dialog;
	overflow: hidden;
}

md-dialog
{

	&.md-transition-in
	{
		opacity: 1;
		transition: $swift-ease-out;
		transform: translate(0, 0) scale(1.0);
	}
	&.md-transition-out
	{
		opacity: 0;
		transition: $swift-ease-out;
		transform: translate(0, 100%) scale(0.2);
	}

	opacity: 0;
	min-width: 240px;
	max-width: 80%;
	max-height: 80%;
	position: relative;
	overflow: auto; // stop content from leaking out of dialog parent and fix IE

	box-shadow: $whiteframe-shadow-13dp;

	display: flex;
	flex-direction: column;

	& > form
	{
		display: flex;
		flex-direction: column;
		overflow: auto;
	}

	.md-dialog-content
	{
		padding: $dialog-padding;


		p:last-child
		{
			padding-bottom: 0 !important;
		}
	}

	md-dialog-content
	{
		order: 1;
		flex-direction: column;
		overflow: auto;
		-webkit-overflow-scrolling: touch;

		&:not([layout=row]) > *:first-child:not(.md-subheader)
		{
			margin-top: 0;
		}

		&:focus
		{
			outline: none;
		}

		.md-subheader
		{
			margin: 0;
		}

		.md-dialog-content-body
		{
			width: 100%;
		}

		.md-prompt-input-container
		{
			width: 100%;
			box-sizing: border-box;
		}
	}

	.md-actions, md-dialog-actions
	{
		display: flex;
		order: 2;
		box-sizing: border-box;
		align-items: center;
		justify-content: flex-end;
		margin-bottom: 0;
		@include rtl(padding-right, $baseline-grid, $baseline-grid * 2);
		@include rtl(padding-left, $baseline-grid * 2, $baseline-grid);
		min-height: $baseline-grid * 6.5;
		overflow: hidden;
		padding: 0 16px 20px;

		.md-button
		{
			margin-bottom: $baseline-grid;
			@include rtl(margin-left, $baseline-grid, 0);
			@include rtl(margin-right, 0, $baseline-grid);
			margin-top: $baseline-grid;
		}
	}
	&.md-content-overflow
	{
		.md-actions, md-dialog-actions
		{
			border-top-width: 1px;
			border-top-style: solid;
		}
	}

}

@media screen and (-ms-high-contrast: active)
{
	md-dialog
	{
		border: 1px solid #FFF;
	}
}

@media (max-width: $layout-breakpoint-sm - 1)
{
	md-dialog.md-dialog-fullscreen
	{
		min-height: 100%;
		min-width: 100%;
		border-radius: 0;
	}
}

$button-fab-width: rem(5.600) !default;
$icon-button-margin: rem(0.600) !default;

md-fab-toolbar
{
	$icon-delay: 200ms;

	// Include the top/left/bottom/right fab positions
	@include fab-all-positions();

	display: block;

	/*
   * Closed styling
   */
	.md-fab-toolbar-wrapper
	{
		display: block;
		position: relative;
		overflow: hidden;

		// Account for the size of the trigger plus its margin/shadow
		height: $button-fab-width + ($icon-button-margin * 2);
	}

	md-fab-trigger
	{
		position: absolute;
		z-index: $z-index-fab;

		button
		{
			overflow: visible !important;
		}

		.md-fab-toolbar-background
		{
			display: block;
			position: absolute;
			z-index: $z-index-fab + 1;

			opacity: 1;
			transition: $swift-ease-in;
		}

		md-icon
		{
			position: relative;
			z-index: $z-index-fab + 2;

			opacity: 1;

			// Hide the icon very quickly
			transition: all $icon-delay ease-in;
		}
	}

	&.md-left
	{
		md-fab-trigger
		{
			@include rtl-prop(right, left, 0, auto);
		}

		.md-toolbar-tools
		{
			flex-direction: row-reverse;

			> .md-button:first-child
			{
				@include rtl-prop(margin-right, margin-left, 0.6rem, auto)
			}

			> .md-button:first-child
			{
				@include rtl-prop(margin-left, margin-right, -0.8rem, auto);
			}

			> .md-button:last-child
			{
				@include rtl-prop(margin-right, margin-left, 8px, auto);
			}

		}
	}

	&.md-right
	{
		md-fab-trigger
		{
			@include rtl-prop(left, right, 0, auto);
		}

		.md-toolbar-tools
		{
			flex-direction: row;
		}
	}

	md-toolbar
	{
		background-color: transparent !important;
		pointer-events: none;
		z-index: $z-index-fab + 3;

		.md-toolbar-tools
		{
			// Fix some spacing issues with the icons and the trigger
			padding: 0 20px;
			margin-top: 3px;
		}

		.md-fab-action-item
		{
			opacity: 0;
			transform: scale(0);
			transition: $swift-ease-in;

			// Cut the action item's animation time in half since we delay it in the JS
			transition-duration: $swift-ease-in-duration * 0.5;
		}
	}

	/*
   * Hover styling
   */
	&.md-is-open
	{
		md-fab-trigger > button
		{
			box-shadow: none;

			md-icon
			{
				opacity: 0;
			}
		}

		.md-fab-action-item
		{
			opacity: 1;
			transform: scale(1);
		}
	}
}

md-grid-list
{
	box-sizing: border-box;
	display: block;
	position: relative;

	md-grid-tile,
	md-grid-tile > figure,
	md-grid-tile-header,
	md-grid-tile-footer
	{
		box-sizing: border-box;
	}

	md-grid-tile
	{
		display: block;
		position: absolute;

		figure
		{
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			padding: 0;
			margin: 0;
		}

		// Headers & footers
		md-grid-tile-header,
		md-grid-tile-footer
		{
			display: flex;
			flex-direction: row;
			align-items: center;
			height: 48px;
			color: #FFF;
			background: rgba(0, 0, 0, 0.18);
			overflow: hidden;

			// Positioning
			position: absolute;
			left: 0;
			right: 0;

			h3,
			h4
			{
				font-weight: 400;
				margin: 0 0 0 16px;
			}

			h3
			{
				font-size: 14px;
			}

			h4
			{
				font-size: 12px;
			}
		}

		md-grid-tile-header
		{
			top: 0;
		}

		md-grid-tile-footer
		{
			bottom: 0;
		}
	}

}

@media screen and (-ms-high-contrast: active)
{
	md-grid-tile
	{
		border: 1px solid #FFF;
	}
	md-grid-tile-footer
	{
		border-top: 1px solid #FFF;
	}
}

$dense-baseline-grid: $baseline-grid * 0.5 !default;

$list-h3-margin: 0 0 0px 0 !default;
$list-h4-margin: 3px 0 1px 0 !default;
$list-h4-font-weight: 400 !default;
$list-header-line-height: 1.2em !default;
$list-p-margin: 0 0 0 0 !default;
$list-p-line-height: 1.6em !default;

$list-padding-top: $baseline-grid !default;
$list-padding-right: 0px !default;
$list-padding-left: 0px !default;
$list-padding-bottom: $baseline-grid !default;

$item-padding-top: 0px !default;
$item-padding-right: 0px !default;
$item-padding-left: 0px !default;
$item-padding-bottom: 0px !default;
$list-item-padding-vertical: 0px !default;
$list-item-padding-horizontal: $baseline-grid * 2 !default;
$list-item-primary-width: $baseline-grid * 7 !default;
$list-item-primary-avatar-width: $baseline-grid * 5 !default;
$list-item-primary-icon-width: $baseline-grid * 3 !default;
$list-item-secondary-left-margin: $baseline-grid * 2 !default;
$list-item-secondary-button-width: $baseline-grid * 6 !default;
$list-item-inset-divider-offset: 9 * $baseline-grid !default;
$list-item-height: 6 * $baseline-grid !default;
$list-item-two-line-height: 9 * $baseline-grid !default;
$list-item-three-line-height: 11 * $baseline-grid !default;

$list-item-dense-height: 12 * $dense-baseline-grid !default;
$list-item-dense-two-line-height: 15 * $dense-baseline-grid !default;
$list-item-dense-three-line-height: 19 * $dense-baseline-grid !default;
$list-item-dense-primary-icon-width: $dense-baseline-grid * 5 !default;
$list-item-dense-primary-avatar-width: $dense-baseline-grid * 9 !default;
$list-item-dense-header-font-size: round($subhead-font-size-base * 0.8) !default;
$list-item-dense-font-size: round($body-font-size-base * 0.85) !default;
$list-item-dense-line-height: 1.05 !default;

md-list
{
	display: block;
	padding: $list-padding-top $list-padding-right $list-padding-bottom $list-padding-left;

	.md-subheader
	{
		font-size: $body-font-size-base;
		font-weight: 500;
		letter-spacing: 0.010em;
		line-height: $list-header-line-height;
	}

	&.md-dense
	{
		md-list-item
		{
			&,
			.md-list-item-inner
			{
				min-height: $list-item-dense-height;
				@include ie11-min-height-flexbug($list-item-dense-height);

				// Layout for controls in primary or secondary divs, or auto-infered first child

				md-icon:first-child
				{
					width: $list-item-dense-primary-icon-width;
					height: $list-item-dense-primary-icon-width;
				}

				> md-icon:first-child:not(.md-avatar-icon)
				{
					@include rtl-prop(margin-right, margin-left, $list-item-primary-width - $list-item-dense-primary-icon-width, auto);
				}
				.md-avatar, .md-avatar-icon
				{
					@include rtl-prop(margin-right, margin-left, $list-item-primary-width - $list-item-dense-primary-avatar-width, auto);
				}
				.md-avatar
				{
					flex: none;
					width: $list-item-dense-primary-avatar-width;
					height: $list-item-dense-primary-avatar-width;
				}
			}

			&.md-2-line,
			&.md-3-line
			{
				&, & > .md-no-style
				{
					.md-list-item-text
					{
						&.md-offset
						{
							@include rtl-prop(margin-left, margin-right, $list-item-primary-width, auto);
						}

						h3,
						h4,
						p
						{
							line-height: $list-item-dense-line-height;
							font-size: $list-item-dense-font-size;
						}

						h3
						{
							font-size: $list-item-dense-header-font-size;
						}
					}
				}
			}

			&.md-2-line
			{
				&, & > .md-no-style
				{
					min-height: $list-item-dense-two-line-height;
					@include ie11-min-height-flexbug($list-item-dense-two-line-height);

					> .md-avatar, .md-avatar-icon
					{
						margin-top: $baseline-grid * 1.5;
					}
				}
			}

			&.md-3-line
			{
				&, & > .md-no-style
				{

					min-height: $list-item-dense-three-line-height;
					@include ie11-min-height-flexbug($list-item-dense-three-line-height);

					> md-icon:first-child,
					> .md-avatar
					{
						margin-top: $baseline-grid * 2;
					}
				}
			}
		}
	}
}

md-list-item
{
	// Ensure nested dividers are properly positioned
	position: relative;

	&.md-proxy-focus.md-focused .md-no-style
	{
		transition: background-color 0.15s linear;
	}

	&._md-button-wrap
	{
		position: relative;

		> div.md-button:first-child
		{
			// Layout - Vertically align the item content.
			display: flex;
			align-items: center;
			justify-content: flex-start;

			padding: 0 16px;
			margin: 0;

			font-weight: 400;
			@include rtl(text-align, left, right);
			border: medium none;

			// The button executor should fill the whole list item.
			> .md-button:first-child
			{
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;

				margin: 0;
				padding: 0;
			}

			.md-list-item-inner
			{
				// The list item content should fill the complete width.
				width: 100%;
				min-height: inherit;
			}

		}

	}

	&.md-no-proxy,
	.md-no-style
	{
		position: relative;
		padding: $list-item-padding-vertical $list-item-padding-horizontal;

		// Layout [flex='auto']
		flex: 1 1 auto;

		&.md-button
		{
			font-size: inherit;
			height: inherit;
			@include rtl(text-align, left, right);
			text-transform: none;
			width: 100%;
			white-space: normal;
			flex-direction: inherit;
			align-items: inherit;
			border-radius: 0;
			margin: 0;

			& > .md-ripple-container
			{
				border-radius: 0;
			}
		}
		&:focus
		{
			outline: none
		}
	}
	&.md-clickable:hover
	{
		cursor: pointer;
	}

	md-divider
	{
		position: absolute;
		bottom: 0;
		@include rtl-prop(left, right, 0, auto);
		width: 100%;
		&[md-inset]
		{
			@include rtl-prop(left, right, $list-item-inset-divider-offset, auto);
			width: calc(100% - #{$list-item-inset-divider-offset});
			margin: 0 !important;
		}
	}

	&,
	.md-list-item-inner
	{

		// Layout [flex layout-align='start center']
		display: flex;
		justify-content: flex-start;
		align-items: center;

		min-height: $list-item-height;
		@include ie11-min-height-flexbug($list-item-height);

		height: auto;

		// Layout for controls in primary or secondary divs, or auto-infered first child
		& > div.md-primary > md-icon:not(.md-avatar-icon),
		& > div.md-secondary > md-icon:not(.md-avatar-icon),
		& > md-icon:first-child:not(.md-avatar-icon),
		> md-icon.md-secondary:not(.md-avatar-icon)
		{
			width: $list-item-primary-icon-width;
			margin-top: 16px;
			margin-bottom: 12px;
			box-sizing: content-box;
		}
		& > div.md-primary > md-checkbox,
		& > div.md-secondary > md-checkbox,
		& > md-checkbox,
		md-checkbox.md-secondary
		{
			align-self: center;
			.md-label
			{
				display: none;
			}
		}

		& > md-icon:first-child:not(.md-avatar-icon)
		{
			@include rtl-prop(margin-right, margin-left, $list-item-primary-width - $list-item-primary-icon-width, auto);
		}

		& .md-avatar, .md-avatar-icon
		{
			margin-top: $baseline-grid;
			margin-bottom: $baseline-grid;
			@include rtl-prop(margin-right, margin-left, $list-item-primary-width - $list-item-primary-avatar-width, auto);
			border-radius: 50%;
			box-sizing: content-box;
		}
		& .md-avatar
		{
			flex: none;
			width: $list-item-primary-avatar-width;
			height: $list-item-primary-avatar-width;
		}
		& .md-avatar-icon
		{
			padding: 8px;

			// Set the width/height to the same as the icon to fix issue on iOS Safari where the
			// height: 100% was causing it to be larger than it's parent
			svg
			{
				width: $icon-size;
				height: $icon-size;
			}
		}

		& > md-checkbox
		{
			width: 3 * $baseline-grid;
			@include rtl(margin-left, 3px, 29px);
			@include rtl(margin-right, 29px, 3px);
			margin-top: 16px;
		}

		.md-secondary-container
		{
			display: flex;
			align-items: center;

			// Per W3C: https://www.w3.org/TR/css-flexbox/#flex-common
			// By default, flex items won’t shrink below their minimum content size.
			// Safari doesn't follow that specification due to a bug and expects the developer to
			// explicitly disable flex shrinking.
			flex-shrink: 0;

			// Using margin auto to move them to the end of the list item is more elegant, because it has
			// a lower priority than a flex filler and isn't introducing any overflow issues.
			// The margin on the top is important to align multiple secondary items vertically.
			margin: auto;

			@include rtl(margin-right, 0, auto);
			@include rtl(margin-left, auto, 0);

			.md-button, .md-icon-button
			{
				&:last-of-type
				{
					// Reset 6px margin for the button.
					@include rtl-prop(margin-right, margin-left, 0, auto);
				}
			}

			md-checkbox
			{
				margin-top: 0;
				margin-bottom: 0;

				&:last-child
				{
					width: 3 * $baseline-grid;
					@include rtl-prop(margin-right, margin-left, 0, auto);
				}
			}

			md-switch
			{
				margin-top: 0;
				margin-bottom: 0;

				@include rtl-prop(margin-right, margin-left, -6px, auto);
			}
		}

		& > p, & > .md-list-item-inner > p
		{
			flex: 1 1 auto;
			margin: 0;
		}
	}

	&.md-2-line,
	&.md-3-line
	{
		&, & > .md-no-style
		{
			align-items: flex-start;
			justify-content: center;

			&.md-long-text
			{
				margin-top: $baseline-grid;
				margin-bottom: $baseline-grid;
			}

			.md-list-item-text
			{
				flex: 1 1 auto;
				margin: auto;
				text-overflow: ellipsis;
				overflow: hidden;

				&.md-offset
				{
					@include rtl-prop(margin-left, margin-right, $list-item-primary-width, auto);
				}

				h3
				{
					font-size: $subhead-font-size-base;
					font-weight: 400;
					letter-spacing: 0.010em;
					margin: $list-h3-margin;
					line-height: $list-header-line-height;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
				h4
				{
					font-size: $body-font-size-base;
					letter-spacing: 0.010em;
					margin: $list-h4-margin;
					font-weight: $list-h4-font-weight;
					line-height: $list-header-line-height;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
				p
				{
					font-size: $body-font-size-base;
					font-weight: 500;
					letter-spacing: 0.010em;
					margin: $list-p-margin;
					line-height: $list-p-line-height;
				}
			}
		}
	}

	&.md-2-line
	{
		&, & > .md-no-style
		{
			height: auto;

			min-height: $list-item-two-line-height;
			@include ie11-min-height-flexbug($list-item-two-line-height);

			> .md-avatar, .md-avatar-icon
			{
				margin-top: $baseline-grid * 1.5;
			}

			> md-icon:first-child
			{
				align-self: flex-start;
			}

			.md-list-item-text
			{
				flex: 1 1 auto;
			}
		}
	}

	&.md-3-line
	{
		&, & > .md-no-style
		{
			height: auto;

			min-height: $list-item-three-line-height;
			@include ie11-min-height-flexbug($list-item-three-line-height);

			> md-icon:first-child,
			> .md-avatar
			{
				margin-top: $baseline-grid * 2;
			}
		}
	}
}

md-toolbar
{
	&.md-menu-toolbar
	{
		h2.md-toolbar-tools
		{
			line-height: 1rem;
			height: auto;
			padding: 3.5 * $baseline-grid;
			padding-bottom: 1.5 * $baseline-grid;
		}
	}

	// Used to allow hovering from one menu to the
	// next when inside of a toolbar.
	&.md-has-open-menu
	{
		position: relative;
		z-index: $z-index-menu;
	}
}

md-menu-bar
{
	padding: 0 2.5 * $baseline-grid;
	display: block;
	position: relative;
	z-index: 2;
	.md-menu
	{
		display: inline-block;
		padding: 0;
		position: relative;
	}
	button
	{
		font-size: rem(1.4);
		padding: 0 1.25 * $baseline-grid;
		margin: 0;
		border: 0;
		background-color: transparent;
		height: 5 * $baseline-grid;
	}

	md-backdrop.md-menu-backdrop
	{
		z-index: -2;
	}
}

md-menu-content.md-menu-bar-menu.md-dense
{
	max-height: none;
	padding: 2 * $baseline-grid 0;
	md-menu-item.md-indent
	{
		position: relative;
		> md-icon
		{
			position: absolute;
			padding: 0;
			width: 24px;
			top: 0.75 * $baseline-grid;
			@include rtl-prop(left, right, 3 * $baseline-grid, auto);
		}
		> .md-button, .md-menu > .md-button
		{
			@include rtl(padding, 0 4 * $baseline-grid 0 8 * $baseline-grid, 0 8 * $baseline-grid 0 4 * $baseline-grid);
		}
	}
	.md-button
	{
		min-height: 0;
		height: 4 * $baseline-grid;
		span
		{
			@include rtl(float, left, right);
		}
		span.md-alt-text
		{
			@include rtl(float, right, left);
			margin: 0 $baseline-grid;
		}
	}
	md-menu-divider
	{
		margin: $baseline-grid 0;
	}

	md-menu-item > .md-button, .md-menu > .md-button
	{
		@include rtl(text-align, left, right);
	}

	.md-menu
	{
		padding: 0;
		> .md-button
		{
			position: relative;
			margin: 0;
			width: 100%;
			text-transform: none;
			font-weight: normal;
			border-radius: 0px;
			@include rtl-prop(padding-left, padding-right, 2 * $baseline-grid, 0);
			&:after
			{
				display: block;
				content: '\25BC';
				position: absolute;
				top: 0px;
				speak: none;
				@include rtl(transform, rotate(270deg) scaleY(0.45) scaleX(0.9), rotate(90deg) scaleY(0.45) scaleX(0.9));
				@include rtl-prop(right, left, 3.5 * $baseline-grid, auto);
			}
		}
	}
}

/** Matches "md-tabs md-tabs-wrapper" style. */
$md-nav-bar-height: 48px;

.md-nav-bar
{
	border-style: solid;
	border-width: 0 0 1px;
	height: $md-nav-bar-height;
	position: relative;
}

._md-nav-bar-list
{
	outline: none;
	list-style: none;
	margin: 0;
	padding: 0;

	// Layout [layout='row']
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
}

.md-nav-item:first-of-type
{
	margin-left: 8px;
}

// override button styles to look more like tabs
.md-button._md-nav-button
{
	line-height: 24px;
	margin: 0 4px;
	padding: 12px 16px;
	transition: background-color 0.35s $swift-ease-in-out-timing-function;

	&:focus
	{
		outline: none;
	}

	&:hover
	{
		background-color: inherit;
	}
}

md-nav-ink-bar
{
	$duration: $swift-ease-in-out-duration * 0.5;
	$multiplier: 0.5;
	bottom: 0;
	height: 2px;
	left: auto;
	position: absolute;
	right: auto;
	background-color: black;

	&._md-left
	{
		transition: left ($duration * $multiplier) $swift-ease-in-out-timing-function,
		right $duration $swift-ease-in-out-timing-function;
	}
	&._md-right
	{
		transition: left $duration $swift-ease-in-out-timing-function,
		right ($duration * $multiplier) $swift-ease-in-out-timing-function;
	}

	// By default $ngAnimate looks for transition durations on the element, when using ng-hide, ng-if, ng-show.
	// The ink bar has a transition duration applied, which means, that $ngAnimate delays the hide process.
	// To avoid this, we need to reset the transition, when $ngAnimate looks for the duration.
	&.ng-animate
	{
		transition: none;
	}

}

md-nav-extra-content
{
	min-height: 48px;
	padding-right: 12px;
}

$input-container-padding: 2px !default;

$input-label-default-offset: 24px !default;
$input-label-default-scale: 1.0 !default;
$input-label-float-offset: 6px !default;
$input-label-float-scale: 0.75 !default;
$input-label-float-width: $input-container-padding + 16px !default;

$input-placeholder-offset: $input-label-default-offset !default;

$input-border-width-default: 1px !default;
$input-border-width-focused: 2px !default;
$input-line-height: 26px !default;
$input-padding-top: 2px !default;

$input-error-font-size: 12px !default;
$input-error-height: 24px !default;
$input-error-line-height: $input-error-font-size + 2px !default;
$error-padding-top: ($input-error-height - $input-error-line-height) * 0.5 !default;

$icon-offset: 36px !default;

$icon-top-offset: ($icon-offset - $input-padding-top - $input-border-width-focused) * 0.25 !default;

$icon-float-focused-top: -8px !default;

$input-resize-handle-height: 10px !default;

md-input-container
{
	@include pie-clearfix();
	display: inline-block;
	position: relative;
	padding: $input-container-padding;
	margin: 18px 0;
	vertical-align: middle;

	&.md-block
	{
		display: block;
	}

	// Setup a spacer that is always there as a placeholder for any messages so we don't change
	// height with only 1 message
	.md-errors-spacer
	{
		@include rtl(float, right, left);
		min-height: $input-error-height;

		// Ensure the element always takes up space, even if empty
		min-width: 1px;
	}

	> md-icon
	{
		position: absolute;
		top: $icon-top-offset;
		@include rtl(left, 2px, auto);
		@include rtl(right, auto, 2px);
	}

	textarea,
	input[type="text"],
	input[type="password"],
	input[type="datetime"],
	input[type="datetime-local"],
	input[type="date"],
	input[type="month"],
	input[type="time"],
	input[type="week"],
	input[type="number"],
	input[type="email"],
	input[type="url"],
	input[type="search"],
	input[type="tel"],
	input[type="color"]
	{
		/* remove default appearance from all input/textarea */
		-moz-appearance: none;
		-webkit-appearance: none;
	}
	input[type="date"],
	input[type="datetime-local"],
	input[type="month"],
	input[type="time"],
	input[type="week"]
	{
		min-height: $input-line-height;
	}
	textarea
	{
		resize: none;
		overflow: hidden;

		&.md-input
		{
			min-height: $input-line-height;
			-ms-flex-preferred-size: auto; //IE fix
		}

		// The height usually gets set to 1 line by `.md-input`.
		&[md-no-autogrow]
		{
			height: auto;
			overflow: auto;
		}
	}

	label:not(.md-container-ignore)
	{
		position: absolute;
		bottom: 100%;
		@include rtl(left, 0, auto);
		@include rtl(right, auto, 0);

		&.md-required:after
		{
			content: ' *';
			font-size: 13px;
			vertical-align: top;
		}
	}

	label:not(.md-no-float):not(.md-container-ignore),
	.md-placeholder
	{
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 100%;
		order: 1;
		pointer-events: none;
		-webkit-font-smoothing: antialiased;
		@include rtl(padding-left, $input-container-padding + 1px, 0);
		@include rtl(padding-right, 0, $input-container-padding + 1px);
		z-index: 1;
		transform: translate3d(0, $input-label-default-offset + 4, 0) scale($input-label-default-scale);
		transition: transform $swift-ease-out-duration $swift-ease-out-timing-function;

		// The max-width is necessary, because in some browsers, using this together with
		// a calc might cause it to overflow the parent. See #7403
		max-width: 100%;

		@include rtl(transform-origin, left top, right top);
	}
	.md-placeholder
	{
		position: absolute;
		top: 0;
		opacity: 0;
		transition-property: opacity, transform;
		transform: translate3d(0, $input-placeholder-offset + $baseline-grid * 0.75, 0);
	}
	&.md-input-focused .md-placeholder
	{
		opacity: 1;
		transform: translate3d(0, $input-placeholder-offset, 0);
	}
	// Placeholder should immediately disappear when the user starts typing
	&.md-input-has-value .md-placeholder
	{
		transition: none;
		opacity: 0;
	}

	&:not( .md-input-has-value ) input:not( :focus ),
	&:not( .md-input-has-value ) input:not( :focus )::-webkit-datetime-edit-ampm-field,
	&:not( .md-input-has-value ) input:not( :focus )::-webkit-datetime-edit-day-field,
	&:not( .md-input-has-value ) input:not( :focus )::-webkit-datetime-edit-hour-field,
	&:not( .md-input-has-value ) input:not( :focus )::-webkit-datetime-edit-millisecond-field,
	&:not( .md-input-has-value ) input:not( :focus )::-webkit-datetime-edit-minute-field,
	&:not( .md-input-has-value ) input:not( :focus )::-webkit-datetime-edit-month-field,
	&:not( .md-input-has-value ) input:not( :focus )::-webkit-datetime-edit-second-field,
	&:not( .md-input-has-value ) input:not( :focus )::-webkit-datetime-edit-week-field,
	&:not( .md-input-has-value ) input:not( :focus )::-webkit-datetime-edit-year-field,
	&:not( .md-input-has-value ) input:not( :focus )::-webkit-datetime-edit-text
	{
		color: transparent;
	}

	/*
   * The .md-input class is added to the input/textarea
   */
	.md-input
	{
		order: 2;
		display: block;
		margin-top: 0;

		background: none;
		padding-top: $input-padding-top;
		padding-bottom: $input-border-width-focused - $input-border-width-default;
		padding-left: 2px;
		padding-right: 2px;
		border-width: 0 0 $input-border-width-default 0;
		line-height: $input-line-height;
		height: $input-line-height + ($input-padding-top * 2);
		-ms-flex-preferred-size: $input-line-height; //IE fix
		border-radius: 0;
		border-style: solid; // Firefox fix

		// Fix number inputs in Firefox to be full-width
		width: 100%;
		box-sizing: border-box;

		// Hacky fix to force vertical alignment between `input` and `textarea`
		// Input and textarea do not align by default:
		// http://jsbin.com/buqomevage/1/edit?html,css,js,output
		@include rtl(float, left, right);

		&:focus
		{
			outline: none;
		}
		&:invalid
		{
			outline: none;
			box-shadow: none;
		}

		&.md-no-flex
		{
			flex: none !important;
		}
	}

	.md-char-counter
	{
		@include rtl(text-align, right, left);
		@include rtl(padding-right, $input-container-padding, 0);
		@include rtl(padding-left, 0, $input-container-padding);
	}

	//
	// ngMessage base styles - animations moved to input.js
	//
	.md-input-messages-animation
	{
		position: relative;
		order: 4;
		overflow: hidden;
		@include rtl(clear, left, right);

		&.ng-enter
		{
			// Upon entering the DOM, messages should be hidden
			.md-input-message-animation
			{
				opacity: 0;
				margin-top: -100px;
			}
		}
	}

	.md-input-message-animation, .md-char-counter
	{
		font-size: $input-error-font-size;
		line-height: $input-error-line-height;
		overflow: hidden;

		transition: $swift-ease-in;

		// Default state for messages is to be visible
		opacity: 1;
		margin-top: 0;

		// Add some top padding which is equal to half the difference between the expected height
		// and the actual height
		padding-top: $error-padding-top;

		&:not(.md-char-counter)
		{
			// Add some padding so that the messages don't touch the character counter
			@include rtl(padding-right, rem(0.5), 0);
			@include rtl(padding-left, 0, rem(0.5));
		}
	}

	&:not(.md-input-invalid)
	{
		.md-auto-hide
		{
			.md-input-message-animation
			{
				opacity: 0;
				margin-top: -100px;
			}
		}
	}

	// Note: This is a workaround to fix an ng-enter flicker bug
	.md-input-message-animation
	{
		&:not(.ng-animate)
		{
			opacity: 0;
			margin-top: -100px;
		}
	}

	.md-input-message-animation
	{
		&.ng-enter
		{
			opacity: 0;
			margin-top: -100px;
		}
	}

	&.md-input-focused,
	&.md-input-has-placeholder,
	&.md-input-has-value
	{
		label:not(.md-no-float)
		{
			transform: translate3d(0, $input-label-float-offset, 0) scale($input-label-float-scale);
			transition: transform $swift-ease-out-timing-function $swift-ease-out-duration,
			width $swift-ease-out-timing-function $swift-ease-out-duration;
		}
	}

	// If we have an existing value; don't animate the transform as it happens on page load and
	// causes erratic/unnecessary animation
	&.md-input-has-value
	{
		label
		{
			transition: none;
		}
	}

	// Use wide border in error state or in focused state
	&.md-input-focused .md-input,
	.md-input.ng-invalid.ng-dirty,
	&.md-input-resized .md-input
	{
		padding-bottom: 0; // Increase border width by 1px, decrease padding by 1
		border-width: 0 0 $input-border-width-focused 0;
	}

	.md-input
	{
		&[disabled],
		[disabled] &
		{
			// The negative border width offsets the dotted "border" so
			// it's placed in the same place as the solid one before it.
			background-position: bottom $input-border-width-default * -1 left 0;
			// This background-size is coordinated with a linear-gradient set in input-theme.scss
			// to create a dotted line under the input.
			background-size: 4px 1px;
			background-repeat: repeat-x;
		}
	}

	&.md-icon-float
	{

		transition: margin-top $swift-ease-out-duration $swift-ease-out-timing-function;

		> label
		{
			pointer-events: none;
			position: absolute;
		}

		> md-icon
		{
			top: $icon-top-offset;
			@include rtl(left, 2px, auto);
			@include rtl(right, auto, 2px);
		}

	}

	&.md-icon-left,
	&.md-icon-right
	{
		> label
		{
			&:not(.md-no-float):not(.md-container-ignore),
			.md-placeholder
			{
				width: calc(100% - #{$icon-offset} - #{$input-label-float-width});
			}
		}
	}

	// icon offset should have higher priority as normal label
	&.md-icon-left
	{
		@include rtl(padding-left, $icon-offset, 0);
		@include rtl(padding-right, 0, $icon-offset);
		> label
		{
			@include rtl(left, $icon-offset, auto);
			@include rtl(right, auto, $icon-offset);
		}
	}

	&.md-icon-right
	{
		@include rtl(padding-left, 0, $icon-offset);
		@include rtl(padding-right, $icon-offset, 0);

		> md-icon:last-of-type
		{
			margin: 0;

			@include rtl(right, 2px, auto);
			@include rtl(left, auto, 2px);
		}
	}

	&.md-icon-left.md-icon-right
	{
		padding-left: $icon-offset;
		padding-right: $icon-offset;

		> label
		{
			&:not(.md-no-float):not(.md-container-ignore),
			.md-placeholder
			{
				width: calc(100% - (#{$icon-offset} * 2));
			}
		}
	}
}

.md-resize-wrapper
{
	@include pie-clearfix();
	position: relative;
}

.md-resize-handle
{
	position: absolute;
	bottom: math.div($input-resize-handle-height, -2);
	left: 0;
	height: $input-resize-handle-height;
	background: transparent;
	width: 100%;
	cursor: ns-resize;
}

@media screen and (-ms-high-contrast: active)
{
	md-input-container.md-default-theme > md-icon
	{
		fill: #FFF;
	}
}

md-icon
{
	margin: auto;
	background-repeat: no-repeat no-repeat;
	//display: inline-block;
	vertical-align: middle;
	fill: currentColor;
	height: $icon-size;
	width: $icon-size;

	// The icons should not shrink on smaller viewports.
	min-height: $icon-size;
	min-width: $icon-size;

	@include media($tablet)
	{
		height: $icon-size*0.6;
		width: $icon-size*0.6;
		min-height: $icon-size*0.6;
		min-width: $icon-size*0.6;

	}
	/*@include media($mobile)
	{
		height: $icon-size*0.5;
		width: $icon-size*0.5;
		min-height: $icon-size*0.5;
		min-width: $icon-size*0.5;
	}*/

	svg
	{
		pointer-events: none;
		display: block;
	}

	&[md-font-icon]
	{
		line-height: $icon-size;
		width: auto;
	}
}

//
//@font-face {
//  font-family:"material";
//  src:url("/dist/material-font/material.eot");
//  font-weight:normal;
//  font-style:normal;
//}
//
//@font-face {
//  font-family:"material";
//  src:url("/dist/material-font/material.eot");
//  src:url("/dist/material-font/material.eot?#iefix") format("embedded-opentype"),
//    url("/dist/material-font/material.woff") format("woff"),
//    url("/dist/material-font/material.ttf") format("truetype"),
//    url("/dist/material-font/material.svg?#material") format("svg");
//  font-weight:normal;
//  font-style:normal;
//}
//
///* Bootstrap Overrides */
//[class^="icon-"]:before,
//[class*=" icon-"]:before {
//  font-family:"material";
//  display:inline-block;
//  vertical-align:middle;
//  line-height:1;
//  font-weight:normal;
//  font-style:normal;
//  speak:none;
//  text-decoration:inherit;
//  text-transform:none;
//  text-rendering:optimizeLegibility;
//  -webkit-font-smoothing:antialiased;
//  -moz-osx-font-smoothing:grayscale;
//}

$progress-linear-bar-height: 5px !default;

md-progress-linear
{
	display: block;
	position: relative;
	width: 100%;
	height: $progress-linear-bar-height;

	padding-top: 0 !important;
	margin-bottom: 0 !important;

	&._md-progress-linear-disabled
	{
		visibility: hidden;
	}

	.md-container
	{
		display: block;
		position: relative;
		overflow: hidden;

		width: 100%;
		height: $progress-linear-bar-height;

		transform: translate(0, 0) scale(1, 1);

		.md-bar
		{
			position: absolute;

			left: 0;
			top: 0;
			bottom: 0;

			width: 100%;
			height: $progress-linear-bar-height;
		}

		.md-dashed:before
		{
			content: "";
			display: none;
			position: absolute;

			margin-top: 0;
			height: $progress-linear-bar-height;
			width: 100%;

			background-color: transparent;
			background-size: 10px 10px !important;
			background-position: 0px -23px;
		}

		.md-bar1, .md-bar2
		{

			// Just set the transition information here.
			// Note: the actual transform values are calculated in JS

			transition: transform 0.2s linear;
		}

		// ************************************************************
		// Animations for modes: Determinate, InDeterminate, and Query
		// ************************************************************

		&.md-mode-query
		{
			.md-bar1
			{
				display: none;
			}
			.md-bar2
			{
				transition: all 0.2s linear;
				animation: query .8s infinite cubic-bezier(0.390, 0.575, 0.565, 1.000);
			}
		}

		&.md-mode-determinate
		{
			.md-bar1
			{
				display: none;
			}
		}

		&.md-mode-indeterminate
		{
			.md-bar1
			{
				animation: md-progress-linear-indeterminate-scale-1 4s infinite,
				md-progress-linear-indeterminate-1 4s infinite;
			}
			.md-bar2
			{
				animation: md-progress-linear-indeterminate-scale-2 4s infinite,
				md-progress-linear-indeterminate-2 4s infinite;
			}
		}

		&.ng-hide
		._md-progress-linear-disabled &
		{
			animation: none;

			.md-bar1
			{
				animation-name: none;
			}
			.md-bar2
			{
				animation-name: none;
			}
		}
	}

	// Special animations for the `buffer` mode

	.md-container.md-mode-buffer
	{
		background-color: transparent !important;

		transition: all 0.2s linear;

		.md-dashed:before
		{
			display: block;
			animation: buffer 3s infinite linear;
		}
	}
}

@keyframes query
{
	0%
	{
		opacity: 1;
		transform: translateX(35%) scale(.3, 1);
	}
	100%
	{
		opacity: 0;
		transform: translateX(-50%) scale(0, 1);
	}
}

@keyframes buffer
{
	0%
	{
		opacity: 1;
		background-position: 0px -23px;
	}
	50%
	{
		opacity: 0;
	}
	100%
	{
		opacity: 1;
		background-position: -200px -23px;
	}
}

@keyframes md-progress-linear-indeterminate-scale-1
{
	0%
	{
		transform: scaleX(0.1);
		animation-timing-function: linear;
	}
	36.6%
	{
		transform: scaleX(0.1);
		animation-timing-function: cubic-bezier(0.334731432, 0.124819821, 0.785843996, 1);
	}
	69.15%
	{
		transform: scaleX(0.83);
		animation-timing-function: cubic-bezier(0.225732004, 0, 0.233648906, 1.3709798);
	}
	100%
	{
		transform: scaleX(0.1);
	}
}

@keyframes md-progress-linear-indeterminate-1
{
	0%
	{
		left: math.div(-378.6 * 100%, 360);
		animation-timing-function: linear;
	}
	20%
	{
		left: math.div(-378.6 * 100%, 360);
		animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495818703);
	}
	69.15%
	{
		left: math.div(77.4 * 100%, 360);
		animation-timing-function: cubic-bezier(0.302435, 0.38135197, 0.55, 0.956352125);
	}
	100%
	{
		left: math.div(343.6 * 100%, 360);
	}
}

@keyframes md-progress-linear-indeterminate-scale-2
{
	0%
	{
		transform: scaleX(0.1);
		animation-timing-function: cubic-bezier(0.205028172, 0.057050836, 0.57660995, 0.453970841);
	}
	19.15%
	{
		transform: scaleX(0.57);
		animation-timing-function: cubic-bezier(0.152312994, 0.196431957, 0.648373778, 1.00431535);
	}
	44.15%
	{
		transform: scaleX(0.91);
		animation-timing-function: cubic-bezier(0.25775882, -0.003163357, 0.211761916, 1.38178961);
	}
	100%
	{
		transform: scaleX(0.1);
	}
}

@keyframes md-progress-linear-indeterminate-2
{
	0%
	{
		left: math.div(-197.6 * 100%, 360);
		animation-timing-function: cubic-bezier(0.15, 0, 0.5150584, 0.409684966);
	}
	25%
	{
		left: math.div(-62.1 * 100%, 360);
		animation-timing-function: cubic-bezier(0.3103299, 0.284057684, 0.8, 0.733718979);
	}
	48.35%
	{
		left: math.div(106.2 * 100%, 360);
		animation-timing-function: cubic-bezier(0.4, 0.627034903, 0.6, 0.902025796);
	}
	100%
	{
		left: math.div(422.6 * 100%, 360);
	}
}

$menu-border-radius: 2px !default;
$max-visible-items: 8 !default;
$menu-item-height: 6 * $baseline-grid !default;
$dense-menu-item-height: 4 * $baseline-grid !default;
$max-menu-height: 2 * $baseline-grid + $max-visible-items * $menu-item-height !default;
$max-dense-menu-height: 2 * $baseline-grid + $max-visible-items * $dense-menu-item-height !default;

.md-open-menu-container
{
	position: fixed;
	left: 0;
	top: 0;
	z-index: $z-index-menu;
	opacity: 0;
	border-radius: $menu-border-radius;

	md-menu-divider
	{
		margin-top: $baseline-grid * 0.5;
		margin-bottom: $baseline-grid * 0.5;
		height: 1px;
		min-height: 1px;
		max-height: 1px;
		width: 100%;
	}

	md-menu-content > *
	{
		opacity: 0;
	}

	// Don't let the user click something until it's animated
	&:not(.md-clickable)
	{
		pointer-events: none;
	}

	// enter: menu scales in, then list fade in.
	&.md-active
	{
		opacity: 1;
		transition: $swift-ease-out;
		transition-duration: 200ms;
		> md-menu-content > *
		{
			opacity: 1;
			transition: $swift-ease-in;
			transition-duration: 200ms;
			transition-delay: 100ms;
		}
	}
	// leave: the container fades out
	&.md-leave
	{
		opacity: 0;
		transition: $swift-ease-in;
		transition-duration: 250ms;
	}
}

md-menu-content
{
	display: flex;
	flex-direction: column;
	padding: $baseline-grid 0;
	max-height: $max-menu-height;
	overflow-y: auto;
	&.md-dense
	{
		max-height: $max-dense-menu-height;
		md-menu-item
		{
			height: $dense-menu-item-height;
			min-height: 0px;
		}
	}
}

md-menu-item
{
	display: flex;
	flex-direction: row;
	min-height: $menu-item-height;
	height: $menu-item-height;
	align-content: center;
	justify-content: flex-start;

	> *
	{
		width: 100%;
		margin: auto 0;
		padding-left: 2*$baseline-grid;
		padding-right: 2*$baseline-grid;
	}

	/*
   * We cannot use flex on <button> elements due to a bug in Firefox, so we also can't use it on
   * <a> elements. Add some top padding to fix alignment since buttons automatically align their
   * text vertically.
   */
	> a.md-button
	{
		padding-top: 5px;
	}

	> .md-button
	{
		// Firefox-specific reset styling to fix alignment issues (see #8464)
		&::-moz-focus-inner
		{
			padding: 0;
			border: 0
		}

		@include rtl(text-align, left, right);

		display: inline-block;
		border-radius: 0;
		margin: auto 0;
		font-size: (2*$baseline-grid) - 1;
		text-transform: none;
		font-weight: 400;
		height: 100%;
		padding-left: 2*$baseline-grid;
		padding-right: 2*$baseline-grid;
		width: 100%;
		md-icon
		{
			@include rtl(margin, auto 2*$baseline-grid auto 0, auto 0 auto 2*$baseline-grid);
		}
		p
		{
			display: inline-block;
			margin: auto;
		}
		span
		{
			margin-top: auto;
			margin-bottom: auto;
		}
		.md-ripple-container
		{
			border-radius: inherit;
		}
	}
}

md-toolbar
{
	.md-menu
	{
		height: auto;
		margin: auto;
		padding: 0;
	}
}

@media (max-width: $layout-breakpoint-sm - 1)
{
	md-menu-content
	{
		min-width: 112px;
	}
	@for $i from 3 through 7
	{
		md-menu-content[width="#{$i}"]
		{
			min-width: $i * 56px;
		}
	}
}

@media (min-width: $layout-breakpoint-sm)
{
	md-menu-content
	{
		min-width: 96px;
	}
	@for $i from 3 through 7
	{
		md-menu-content[width="#{$i}"]
		{
			min-width: $i * 64px;
		}
	}
}

$radio-width: 20px !default;
$radio-height: $radio-width !default;
$radio-text-margin: 10px !default;
$radio-top-left: 12px !default;
$radio-margin: 16px !default;

@mixin md-radio-button-disabled
{
	cursor: default;

	.md-container
	{
		cursor: default;
	}
}

md-radio-button
{
	box-sizing: border-box;
	display: block;
	margin-bottom: $radio-margin;
	white-space: nowrap;
	cursor: pointer;
	position: relative;

	// When the radio-button is disabled.
	&[disabled]
	{
		@include md-radio-button-disabled();
	}

	.md-container
	{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		box-sizing: border-box;
		display: inline-block;
		width: $radio-width;
		height: $radio-width;
		cursor: pointer;
		@include rtl(left, 0, auto);
		@include rtl(right, auto, 0);

		.md-ripple-container
		{
			position: absolute;
			display: block;
			width: auto;
			height: auto;
			left: -15px;
			top: -15px;
			right: -15px;
			bottom: -15px;
		}

		&:before
		{
			box-sizing: border-box;
			background-color: transparent;
			border-radius: 50%;
			content: '';
			position: absolute;
			display: block;
			height: auto;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			transition: all 0.5s;
			width: auto;
		}
	}

	&.md-align-top-left > div.md-container
	{
		top: $radio-top-left;
	}

	.md-off
	{
		box-sizing: border-box;
		position: absolute;
		top: 0;
		left: 0;
		width: $radio-width;
		height: $radio-width;
		border-style: solid;
		border-width: 2px;
		border-radius: 50%;
		transition: border-color ease 0.28s;
	}

	.md-on
	{
		box-sizing: border-box;
		position: absolute;
		top: 0;
		left: 0;
		width: $radio-width;
		height: $radio-width;
		border-radius: 50%;
		transition: transform ease 0.28s;
		transform: scale(0);
	}

	&.md-checked .md-on
	{
		transform: scale(0.50);
	}

	.md-label
	{
		box-sizing: border-box;
		position: relative;
		display: inline-block;

		@include rtl(margin-left, $radio-text-margin + $radio-width, 0);
		@include rtl(margin-right, 0, $radio-text-margin + $radio-width);

		vertical-align: middle;
		white-space: normal;
		pointer-events: none;
		width: auto;
	}
}

md-radio-group
{
	/** Layout adjustments for the radio group. */
	&.layout-column,
	&.layout-xs-column, &.layout-gt-xs-column,
	&.layout-sm-column, &.layout-gt-sm-column,
	&.layout-md-column, &.layout-gt-md-column,
	&.layout-lg-column, &.layout-gt-lg-column,
	&.layout-xl-column
	{
		md-radio-button
		{
			margin-bottom: $radio-margin;
		}
	}

	&.layout-row,
	&.layout-xs-row, &.layout-gt-xs-row,
	&.layout-sm-row, &.layout-gt-sm-row,
	&.layout-md-row, &.layout-gt-md-row,
	&.layout-lg-row, &.layout-gt-lg-row,
	&.layout-xl-row
	{
		md-radio-button
		{
			margin-top: 0;
			margin-bottom: 0;
			@include rtl(margin-left, 0, $radio-margin);
			@include rtl(margin-right, $radio-margin, 0);

			&:last-of-type
			{
				margin-left: 0;
				margin-right: 0;
			}
		}
	}

	&:focus
	{
		outline: none;
	}

	&.md-focused
	{
		.md-checked .md-container:before
		{
			left: -8px;
			top: -8px;
			right: -8px;
			bottom: -8px;
		}
	}

	&[disabled] md-radio-button
	{
		@include md-radio-button-disabled();
	}
}

.md-inline-form
{
	md-radio-group
	{
		margin: 18px 0 19px;
		md-radio-button
		{
			display: inline-block;
			height: 30px;
			padding: 2px;
			box-sizing: border-box;
			margin-top: 0;
			margin-bottom: 0;
		}
	}
}

@media screen and (-ms-high-contrast: active)
{
	md-radio-button.md-default-theme .md-on
	{
		background-color: #FFF;
	}
}

$progress-circular-indeterminate-duration: 1568.63ms !default;

@keyframes indeterminate-rotate
{
	0%
	{
		transform: rotate(0deg);
	}
	100%
	{
		transform: rotate(360deg);
	}
}

// Used to avoid unnecessary layout
md-progress-circular
{
	position: relative;
	display: block;

	&._md-progress-circular-disabled
	{
		visibility: hidden;
	}

	&.md-mode-indeterminate svg
	{
		animation: indeterminate-rotate $progress-circular-indeterminate-duration linear infinite;
	}

	svg
	{
		position: absolute;
		overflow: visible;
		top: 0;
		left: 0;
	}
}

$sidenav-mobile-width: 320px !default;
$sidenav-desktop-width: 400px !default;
$sidenav-min-space: 56px !default;

md-sidenav
{
	box-sizing: border-box;
	position: absolute;
	flex-direction: column;
	z-index: $z-index-sidenav;

	width: $sidenav-mobile-width;
	max-width: $sidenav-mobile-width;
	bottom: 0;
	overflow: auto;
	-webkit-overflow-scrolling: touch;

	ul
	{
		list-style: none;
	}

	// Animation Comment Syntax: [mdLockedOpen]|[mdClosed]
	// mdLockedOpen states: unlocked, locked, any
	// mdClosed states: open, closed, any
	// Default state: unlocked|open

	// unlocked|closed
	&.md-closed
	{
		display: none;
	}

	// any|open <=> any|closed
	&.md-closed-add,
	&.md-closed-remove
	{
		display: flex;
		transition: 0.2s ease-in all;
	}

	// any|open <=> any|closed (animating)
	&.md-closed-add.md-closed-add-active,
	&.md-closed-remove.md-closed-remove-active
	{
		transition: $swift-ease-out;
	}

	// unlocked|any <=> locked|any
	&.md-locked-open-add,
	&.md-locked-open-remove
	{
		position: static;
		display: flex;
		transform: translate3d(0, 0, 0);
	}

	// locked|any
	&.md-locked-open,
	&.md-locked-open.md-closed,
	&.md-locked-open.md-closed.md-sidenav-left,
	&.md-locked-open.md-closed.md-sidenav-right
	{
		position: static;
		display: flex;
		transform: translate3d(0, 0, 0);
	}

	// locked|closed => unlock|closed
	&.md-locked-open-remove.md-closed
	{
		position: static;
		display: flex;
		transform: translate3d(0, 0, 0);
	}

	// unlocked|closed => locked|closed
	&.md-closed.md-locked-open-add
	{
		position: static;
		display: flex;
		transform: translate3d(0%, 0, 0);
	}

	// unlocked|closed => locked|closed (pre-animation)
	&.md-closed.md-locked-open-add:not(.md-locked-open-add-active)
	{
		transition: width $swift-ease-in-duration $swift-ease-in-timing-function,
		min-width $swift-ease-in-duration $swift-ease-in-timing-function;
		width: 0 !important;
		min-width: 0 !important;
	}

	// unlocked|closed => locked|closed (animating)
	&.md-closed.md-locked-open-add-active
	{
		transition: width $swift-ease-in-duration $swift-ease-in-timing-function,
		min-width $swift-ease-in-duration $swift-ease-in-timing-function;
	}

	// locked|any => unlocked|any (animating)
	&.md-locked-open-remove-active
	{
		transition: width $swift-ease-in-duration $swift-ease-in-timing-function,
		min-width $swift-ease-in-duration $swift-ease-in-timing-function;
		width: 0 !important;
		min-width: 0 !important;
	}

	@extend .md-sidenav-left;
}

.md-sidenav-backdrop.md-locked-open
{
	display: none;
}

.md-sidenav-left
{
	left: 0;
	top: 0;
	transform: translate3d(0%, 0, 0);
	&.md-closed
	{
		transform: translate3d(-100%, 0, 0);
	}
}

.md-sidenav-right
{
	left: 100%;
	top: 0;
	transform: translate(-100%, 0);
	&.md-closed
	{
		transform: translate(0%, 0);
	}
}

@media (min-width: $layout-breakpoint-xs)
{
	md-sidenav
	{
		max-width: $sidenav-desktop-width;
	}
}

@media (max-width: $sidenav-desktop-width + $sidenav-min-space)
{
	md-sidenav
	{
		width: calc(100% - #{$sidenav-min-space});
		min-width: calc(100% - #{$sidenav-min-space});
		max-width: calc(100% - #{$sidenav-min-space});
	}
}

// IE Only
@media screen and (-ms-high-contrast: active)
{
	.md-sidenav-left
	{
		border-right: 1px solid #FFF;
	}
	.md-sidenav-right
	{
		border-left: 1px solid #FFF;
	}
}

$slider-background-color: rgb(200, 200, 200) !default;
$slider-size: 48px !default;
$slider-min-size: 128px !default;

$slider-track-height: 2px !default;
$slider-thumb-width: 20px !default;
$slider-thumb-height: $slider-thumb-width !default;

$slider-thumb-default-scale: 0.7 !default;
$slider-thumb-hover-scale: 0.8 !default;
$slider-thumb-focus-scale: 1 !default;
$slider-thumb-disabled-scale: 0.5 !default;
$slider-thumb-disabled-border: 4px !default;
$slider-thumb-focus-duration: .7s !default;

$slider-focus-thumb-width: 34px !default;
$slider-focus-thumb-height: $slider-focus-thumb-width !default;
$slider-focus-ring-border-width: 3px !default;

$slider-arrow-height: 16px !default;
$slider-arrow-width: 28px !default;

$slider-sign-height: 28px !default;
$slider-sign-width: 28px !default;
$slider-sign-top: ($slider-size * 0.5) - ($slider-thumb-default-scale * $slider-thumb-height * 0.5) - ($slider-sign-height) - ($slider-arrow-height) + 10px !default;

@keyframes sliderFocusThumb
{
	0%
	{
		transform: scale($slider-thumb-default-scale);
	}
	30%
	{
		transform: scale($slider-thumb-focus-scale);
	}
	100%
	{
		transform: scale($slider-thumb-default-scale);
	}
}

@keyframes sliderDiscreteFocusThumb
{
	0%
	{
		transform: scale($slider-thumb-default-scale);
	}
	50%
	{
		transform: scale($slider-thumb-hover-scale);
	}
	100%
	{
		transform: scale(0);
	}
}

@keyframes sliderDiscreteFocusRing
{
	0%
	{
		transform: scale(0.7);
		opacity: 0;
	}
	50%
	{
		transform: scale(1);
		opacity: 1;
	}
	100%
	{
		transform: scale(0);
	}
}

@mixin slider-thumb-position($width: $slider-thumb-width, $height: $slider-thumb-height)
{
	position: absolute;
	@include rtl-prop(left, right, (-$width * 0.5), auto);
	top: ($slider-size * 0.5) - ($height * 0.5);
	width: $width;
	height: $height;
	border-radius: max($width, $height);
}

md-slider
{
	height: $slider-size;
	min-width: $slider-min-size;
	position: relative;
	margin-left: 4px;
	margin-right: 4px;
	padding: 0;
	display: block;
	flex-direction: row;

	*, *:after
	{
		box-sizing: border-box;
	}

	.md-slider-wrapper
	{
		outline: none;
		width: 100%;
		height: 100%;
	}

	.md-slider-content
	{
		position: relative;
	}

	/**
   * Track
   */
	.md-track-container
	{
		width: 100%;
		position: absolute;
		top: ($slider-size * 0.5) - ($slider-track-height) * 0.5;
		height: $slider-track-height;
	}
	.md-track
	{
		position: absolute;
		left: 0;
		right: 0;
		height: 100%;
	}
	.md-track-fill
	{
		transition: all .4s cubic-bezier(.25, .8, .25, 1);
		transition-property: width, height;
	}
	.md-track-ticks
	{
		position: absolute;
		left: 0;
		right: 0;
		height: 100%;
	}
	.md-track-ticks canvas
	{
		// Restrict the width and the height of the canvas so that ticks are rendered correctly
		// when parent elements are resized. Else, the position of the ticks might
		// be incorrect as we only update the canvas width attribute on window resize.
		width: 100%;
		height: 100%;
	}

	/**
   * Slider thumb
   */
	.md-thumb-container
	{
		position: absolute;
		@include rtl-prop(left, right, 0, auto);
		top: 50%;
		transform: translate3d(-50%, -50%, 0);
		transition: all .4s cubic-bezier(.25, .8, .25, 1);
		transition-property: left, right, bottom;
	}
	.md-thumb
	{
		z-index: 1;

		@include slider-thumb-position($slider-thumb-width, $slider-thumb-height);

		// We render thumb in an :after selector to fix an obscure problem with the
		// thumb being clipped by the focus-ring and focus-thumb while running the focus
		// animation.
		&:after
		{
			content: '';
			position: absolute;
			width: $slider-thumb-width;
			height: $slider-thumb-height;
			border-radius: max($slider-thumb-width, $slider-thumb-height);
			border-width: 3px;
			border-style: solid;
			transition: inherit;
		}

		transform: scale($slider-thumb-default-scale);
		transition: all .4s cubic-bezier(.25, .8, .25, 1);
	}

	/* The sign that's focused in discrete mode */
	.md-sign
	{

		/* Center the children (slider-thumb-text) */
		display: flex;
		align-items: center;
		justify-content: center;

		position: absolute;
		left: -($slider-sign-height * 0.5);
		top: $slider-sign-top;
		width: $slider-sign-width;
		height: $slider-sign-height;
		border-radius: max($slider-sign-height, $slider-sign-width);

		transform: scale(0.4) translate3d(0, math.div(-$slider-sign-top + 10, 0.4), 0);
		transition: all 0.3s $swift-ease-in-out-timing-function;

		/* The arrow pointing down under the sign */
		&:after
		{
			position: absolute;
			content: '';
			@include rtl-prop(left, right, -($slider-sign-width * 0.5 - $slider-arrow-width * 0.5), auto);
			border-radius: $slider-arrow-height;
			top: 19px;
			border-left: $slider-arrow-width * 0.5 solid transparent;
			border-right: $slider-arrow-width * 0.5 solid transparent;
			border-top-width: $slider-arrow-height;
			border-top-style: solid;

			opacity: 0;
			transform: translate3d(0, -8px, 0);
			transition: all 0.2s $swift-ease-in-out-timing-function;
		}

		.md-thumb-text
		{
			z-index: 1;
			font-size: 12px;
			font-weight: bold;
		}
	}

	/**
   * The border/background that comes in when focused in non-discrete mode
   */
	.md-focus-ring
	{
		@include slider-thumb-position($slider-focus-thumb-width, $slider-focus-thumb-height);
		transform: scale(.7);
		opacity: 0;
		// using a custom duration to match the spec example video
		transition: all ($slider-thumb-focus-duration * 0.5) $swift-ease-in-out-timing-function;
	}
	.md-disabled-thumb
	{
		@include slider-thumb-position(
				$slider-thumb-width + $slider-thumb-disabled-border * 2,
				$slider-thumb-height + $slider-thumb-disabled-border * 2
		);
		transform: scale($slider-thumb-disabled-scale);
		border-width: $slider-thumb-disabled-border;
		border-style: solid;
		display: none;
	}

	&.md-min
	{
		.md-sign
		{
			opacity: 0;
		}
	}

	&:focus
	{
		outline: none;
	}

	/* Don't animate left/right while panning */
	&.md-dragging
	{
		.md-thumb-container,
		.md-track-fill
		{
			transition: none;
		}
	}

	&:not([md-discrete])
	{
		/* Hide the sign and ticks in non-discrete mode */
		.md-track-ticks,
		.md-sign
		{
			display: none;
		}

		&:not([disabled])
		{
			.md-slider-wrapper
			{
				.md-thumb:hover
				{
					transform: scale($slider-thumb-hover-scale);
				}

				&.md-focused
				{
					.md-focus-ring
					{
						transform: scale(1);
						opacity: 1;
					}
					.md-thumb
					{
						animation: sliderFocusThumb $slider-thumb-focus-duration $swift-ease-in-out-timing-function;
					}
				}
			}

			&.md-active
			{
				.md-slider-wrapper
				{
					.md-thumb
					{
						transform: scale($slider-thumb-focus-scale);
					}
				}
			}
		}
	}

	&[md-discrete]
	{
		&:not([disabled])
		{
			.md-slider-wrapper
			{
				&.md-focused
				{
					.md-focus-ring
					{
						transform: scale(0);
						animation: sliderDiscreteFocusRing .5s $swift-ease-in-out-timing-function;
					}
					.md-thumb
					{
						animation: sliderDiscreteFocusThumb .5s $swift-ease-in-out-timing-function;
					}
				}
			}
			.md-slider-wrapper.md-focused,
			&.md-active
			{
				.md-thumb
				{
					transform: scale(0);
				}
				.md-sign,
				.md-sign:after
				{
					opacity: 1;
					transform: translate3d(0, 0, 0) scale(1.0);
				}
			}
		}

		&[disabled][readonly]
		{
			.md-thumb
			{
				transform: scale(0);
			}
			.md-sign,
			.md-sign:after
			{
				opacity: 1;
				transform: translate3d(0, 0, 0) scale(1.0);
			}
		}
	}

	&[disabled]
	{
		.md-track-fill
		{
			display: none;
		}
		.md-track-ticks
		{
			opacity: 0;
		}
		&:not([readonly]) .md-sign
		{
			opacity: 0;
		}
		.md-thumb
		{
			transform: scale($slider-thumb-disabled-scale);
		}
		.md-disabled-thumb
		{
			display: block;
		}
	}

	&[md-vertical]
	{
		flex-direction: column;
		min-height: $slider-min-size;
		min-width: 0;

		.md-slider-wrapper
		{
			flex: 1;
			padding-top: 12px;
			padding-bottom: 12px;
			width: $slider-size;
			align-self: center;
			display: flex;
			justify-content: center;
		}

		.md-track-container
		{
			height: 100%;
			width: $slider-track-height;
			top: 0;
			left: calc(50% - (#{$slider-track-height} / 2));
		}

		.md-thumb-container
		{
			top: auto;
			margin-bottom: ($slider-size * 0.5) - ($slider-track-height) * 0.5;
			left: calc(50% - 1px);
			bottom: 0;

			.md-thumb:after
			{
				left: 1px;
			}

			.md-focus-ring
			{
				left: -(($slider-focus-thumb-width * 0.5) - ($slider-track-height * 0.5));
			}
		}

		.md-track-fill
		{
			bottom: 0;
		}

		&[md-discrete]
		{
			.md-sign
			{
				$sign-top: -($slider-sign-top * 0.5) + 1;

				left: -$slider-sign-height - 12;
				top: $sign-top;

				transform: scale(0.4) translate3d(math.div(-$slider-sign-top + 10, 0.4), 0, 0);

				/* The arrow pointing left next the sign */
				&:after
				{
					top: $sign-top;
					left: 19px;
					border-top: $slider-arrow-width * 0.5 solid transparent;
					border-right: 0;
					border-bottom: $slider-arrow-width * 0.5 solid transparent;
					border-left-width: $slider-arrow-height;
					border-left-style: solid;

					opacity: 0;
					transform: translate3d(0, -8px, 0);
					transition: all 0.2s ease-in-out;
				}

				.md-thumb-text
				{
					z-index: 1;
					font-size: 12px;
					font-weight: bold;
				}
			}

			&.md-active,
			.md-focused,
			&[disabled][readonly]
			{
				.md-sign:after
				{
					top: 0;
				}
			}
		}

		&[disabled][readonly]
		{
			.md-thumb
			{
				transform: scale(0);
			}
			.md-sign,
			.md-sign:after
			{
				opacity: 1;
				transform: translate3d(0, 0, 0) scale(1.0);
			}
		}
	}
	&[md-invert]
	{
		&:not([md-vertical]) .md-track-fill
		{
			@include rtl(left, auto, 0);
			@include rtl(right, 0, auto);
		}
		&[md-vertical]
		{
			.md-track-fill
			{
				bottom: auto;
				top: 0;
			}
		}
	}
}

md-slider-container
{
	display: flex;
	align-items: center;
	flex-direction: row;

	$items-width: 25px;
	$items-height: $items-width;
	$items-margin: 16px;

	& > *:first-child,
	& > *:last-child
	{
		&:not(md-slider)
		{
			min-width: $items-width;
			max-width: ($items-width * 2) - 8;
			height: $items-height;
			transition: $swift-ease-out;
			transition-property: color, max-width;
		}
	}

	& > *:first-child:not(md-slider)
	{
		@include rtl-prop(margin-right, margin-left, $items-margin, auto);
	}

	& > *:last-child:not(md-slider)
	{
		@include rtl-prop(margin-left, margin-right, $items-margin, auto);
	}

	&[md-vertical]
	{
		flex-direction: column;

		& > *:first-child:not(md-slider),
		& > *:last-child:not(md-slider)
		{
			margin-right: 0;
			margin-left: 0;
			text-align: center;
		}
	}

	md-input-container
	{
		input[type="number"]
		{
			text-align: center;
			@include rtl-prop(padding-left, padding-right, 15px, 0); // size of arrows
			height: $items-height * 2;
			margin-top: -$items-height;
		}
	}
}

@media screen and (-ms-high-contrast: active)
{
	md-slider.md-default-theme .md-track
	{
		border-bottom: 1px solid #FFF;
	}
}

$select-checkbox-border-radius: 2px !default;
$select-checkbox-border-width: 2px !default;
$select-checkbox-width: rem(1.4) !default;
$select-option-height: 48px !default;
$select-option-padding: 16px !default;
$select-container-padding: 16px !default;
$select-container-transition-duration: 350ms !default;

$select-max-visible-options: 5 !default;

// Fixes the animations with the floating label when select is inside an input container
md-input-container
{
	&:not([md-no-float])
	{
		.md-select-placeholder span:first-child
		{
			transition: transform $swift-ease-out-duration $swift-ease-out-timing-function;
			@include rtl(transform-origin, left top, right top);
		}
	}
	&.md-input-focused
	{
		&:not([md-no-float])
		{
			.md-select-placeholder span:first-child
			{
				transform: translateY(-22px) translateX(-2px) scale(0.75);
			}
		}
	}
}

.md-select-menu-container
{
	position: fixed;
	left: 0;
	top: 0;
	z-index: $z-index-select;
	opacity: 0;
	display: none;

	// Fix 1px alignment issue to line up with text inputs (and spec)
	transform: translateY(-1px);

	// Don't let the user select a new choice while it's animating
	&:not(.md-clickable)
	{
		pointer-events: none;
	}

	md-progress-circular
	{
		display: table;
		margin: 3*$baseline-grid auto !important;
	}

	// enter: md-select scales in, then options fade in.
	&.md-active
	{
		display: block;
		opacity: 1;
		md-select-menu
		{
			transition: $swift-ease-out;
			transition-duration: 150ms;
			> *
			{
				opacity: 1;
				transition: $swift-ease-in;
				transition-duration: 150ms;
				transition-delay: 100ms;
			}
		}
	}

	// leave: the container fades out
	&.md-leave
	{
		opacity: 0;
		transition: $swift-ease-in;
		transition-duration: 250ms;
	}
}

md-input-container > md-select
{
	margin: 0;
	order: 2;
}

// Show the asterisk on the placeholder if the element is required
//
// NOTE: When the input has a value and uses a floating label, the floating label will show the
// asterisk denoting that it is required
md-input-container:not(.md-input-has-value)
{
	md-select[required]:not(.md-no-asterisk), md-select.ng-required:not(.md-no-asterisk)
	{
		.md-select-value span:first-child:after
		{
			content: ' *';
			font-size: 13px;
			vertical-align: top;
		}
	}
}

md-input-container.md-input-invalid
{
	md-select
	{
		.md-select-value
		{
			border-bottom-style: solid;
			padding-bottom: 1px;
		}
	}
}

md-select
{
	display: flex;
	margin: 2.5*$baseline-grid 0 3*$baseline-grid + 2 0;

	&[required], &.ng-required
	{
		&.ng-invalid:not(.md-no-asterisk)
		{
			.md-select-value span:first-child:after
			{
				content: ' *';
				font-size: 13px;
				vertical-align: top;
			}
		}
	}

	&[disabled] .md-select-value
	{
		background-position: 0 bottom;
		// This background-size is coordinated with a linear-gradient set in select-theme.scss
		// to create a dotted line under the input.
		background-size: 4px 1px;
		background-repeat: repeat-x;
		margin-bottom: -1px; // Shift downward so dotted line is positioned the same as other bottom borders
	}

	&:focus
	{
		outline: none;
	}
	&[disabled]:hover
	{
		cursor: default;
	}
	&:not([disabled])
	{
		&:hover
		{
			cursor: pointer
		}
		&.ng-invalid.ng-touched
		{
			.md-select-value
			{
				border-bottom-style: solid;
				padding-bottom: 1px;
			}
		}
		&:focus
		{
			.md-select-value
			{
				border-bottom-width: 2px;
				border-bottom-style: solid;
				padding-bottom: 0;
			}
			&.ng-invalid.ng-touched
			{
				.md-select-value
				{
					padding-bottom: 0;
				}
			}
		}
	}
}

// Fix value by 1px to align with standard text inputs (and spec)
md-input-container.md-input-has-value .md-select-value
{
	> span:not(.md-select-icon)
	{
		transform: translate3d(0, 1px, 0);
	}
}

.md-select-value
{
	display: flex;
	align-items: center;
	padding: 2px 2px 1px;
	border-bottom-width: 1px;
	border-bottom-style: solid;
	background-color: rgba(0, 0, 0, 0);
	position: relative;
	box-sizing: content-box;
	min-width: 8 * $baseline-grid;
	min-height: 26px;
	flex-grow: 1;

	> span:not(.md-select-icon)
	{
		max-width: 100%;
		flex: 1 1 auto;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;

		.md-text
		{
			display: inline;
		}
	}

	.md-select-icon
	{
		display: block;
		align-items: flex-end;
		text-align: end;
		width: 3 * $baseline-grid;
		margin: 0 .5 * $baseline-grid;
		transform: translate3d(0, -2px, 0);
		font-size: 1.2rem;
	}

	.md-select-icon:after
	{
		display: block;
		content: '\25BC';
		position: relative;
		top: 2px;
		speak: none;
		font-size: 13px;
		transform: scaleY(0.5) scaleX(1);
	}

	&.md-select-placeholder
	{
		display: flex;
		order: 1;
		pointer-events: none;
		-webkit-font-smoothing: antialiased;
		padding-left: 2px;
		z-index: 1;
	}
}

md-select-menu
{
	display: flex;
	flex-direction: column;
	&.md-reverse
	{
		flex-direction: column-reverse;
	}

	&:not(.md-overflow)
	{
		md-content
		{
			padding-top: $baseline-grid;
			padding-bottom: $baseline-grid;
		}
	}

	box-shadow: $whiteframe-shadow-1dp;
	max-height: ($select-option-height * $select-max-visible-options) + 2 * $baseline-grid;
	min-height: $select-option-height;
	overflow-y: hidden;

	@include rtl(transform-origin, left top, right top);

	transform: scale(1.0);

	md-content
	{
		min-width: 136px;
		min-height: $select-option-height;
		max-height: ($select-option-height * $select-max-visible-options) + 2 * $baseline-grid;
		overflow-y: auto;
	}
	> *
	{
		opacity: 0;
	}
}

md-option
{
	cursor: pointer;
	position: relative;
	display: flex;
	align-items: center;
	width: auto;
	transition: background 0.15s linear;

	&[disabled]
	{
		cursor: default;
	}

	&:focus
	{
		outline: none;
	}

	.md-text
	{
		@include not-selectable();
		width: auto;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	padding: 0 $select-option-padding 0 $select-option-padding;
	height: $select-option-height;
}

md-optgroup
{
	display: block;
	label
	{
		display: block;
		font-size: rem(1.4);
		text-transform: uppercase;
		padding: $baseline-grid * 2;
		font-weight: 500;
	}
	md-option
	{
		padding-left: $select-option-padding * 2;
		padding-right: $select-option-padding * 2;
	}
}

@media screen and (-ms-high-contrast: active)
{
	.md-select-backdrop
	{
		background-color: transparent;
	}
	md-select-menu
	{
		border: 1px solid #FFF;
	}
}

md-select-menu[multiple]
{
	md-option.md-checkbox-enabled
	{
		@include rtl(padding-left, $select-option-padding * 2.5, $select-option-padding);
		@include rtl(padding-right, $select-option-padding, $select-option-padding * 2.5);

		@include checkbox-container('[selected]');

		.md-container
		{
			@include rtl(margin-left, $select-option-padding * math.div(2, 3), auto);
			@include rtl(margin-right, auto, $select-option-padding * math.div(2, 3));
		}
	}
}

.md-sticky-clone
{
	z-index: 2;
	top: 0;
	left: 0;
	right: 0;
	position: absolute !important;

	transform: translate3d(-9999px, -9999px, 0);

	&[sticky-state="active"]
	{
		transform: translate3d(0, 0, 0);
		&:not(.md-sticky-no-effect) .md-subheader-inner
		{
			animation: subheaderStickyHoverIn 0.3s ease-out both;
		}
	}
}

$subheader-line-height: 1em !default;
$subheader-font-size: rem(1.4) !default;
$subheader-padding: ($baseline-grid * 2) !default;
$subheader-font-weight: 500 !default;
$subheader-margin: 0 0 0 0 !default;
$subheader-sticky-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.16) !default;

@keyframes subheaderStickyHoverIn
{
	0%
	{
		box-shadow: 0 0 0 0 transparent;
	}
	100%
	{
		box-shadow: $subheader-sticky-shadow;
	}
}

@keyframes subheaderStickyHoverOut
{
	0%
	{
		box-shadow: $subheader-sticky-shadow;
	}
	100%
	{
		box-shadow: 0 0 0 0 transparent;
	}
}

.md-subheader-wrapper
{

	&:not(.md-sticky-no-effect)
	{
		.md-subheader
		{
			margin: 0;
		}

		transition: 0.2s ease-out margin;

		&.md-sticky-clone
		{
			z-index: 2;
		}

		&[sticky-state="active"]
		{
			margin-top: -2px;
		}

		&:not(.md-sticky-clone)[sticky-prev-state="active"] .md-subheader-inner:after
		{
			animation: subheaderStickyHoverOut 0.3s ease-out both;
		}
	}

}

.md-subheader
{
	display: block;
	font-size: $subheader-font-size;
	font-weight: $subheader-font-weight;
	line-height: $subheader-line-height;
	margin: $subheader-margin;
	position: relative;

	.md-subheader-inner
	{
		display: block;
		padding: $subheader-padding;
	}

	.md-subheader-content
	{
		display: block;
		z-index: 1;
		position: relative;
	}
}

$switch-width: 36px !default;
$switch-height: $baseline-grid * 3 !default;
$switch-bar-height: 14px !default;
$switch-thumb-size: 20px !default;
$switch-margin: 16px !default;

.md-inline-form
{
	md-switch
	{
		margin-top: 18px;
		margin-bottom: 19px;
	}
}

md-switch
{
	margin: $switch-margin 0;
	white-space: nowrap;
	cursor: pointer;
	outline: none;
	user-select: none;
	height: 30px;
	line-height: 28px;
	align-items: center;
	display: flex;

	@include rtl(margin-left, inherit, $switch-margin);
	@include rtl(margin-right, $switch-margin, inherit);

	&:last-of-type
	{
		@include rtl(margin-left, inherit, 0);
		@include rtl(margin-right, 0, inherit);
	}

	&[disabled]
	{
		cursor: default;

		.md-container
		{
			cursor: default;
		}
	}

	.md-container
	{
		cursor: grab;
		width: $switch-width;
		height: $switch-height;
		position: relative;
		user-select: none;
		@include rtl-prop(margin-right, margin-left, 8px, 0px);
		float: left;
	}

	&.md-inverted .md-container
	{
		@include rtl(margin-right, initial, 8px);
		@include rtl(margin-left, 8px, initial);
	}

	// If the user moves his mouse off the switch, stil display grabbing cursor
	&:not([disabled])
	{
		.md-dragging,
		&.md-dragging .md-container
		{
			cursor: grabbing;
		}
	}

	&.md-focused:not([disabled])
	{
		.md-thumb:before
		{
			left: -8px;
			top: -8px;
			right: -8px;
			bottom: -8px;
		}

		&:not(.md-checked)
		{
			.md-thumb:before
			{
				background-color: rgba(0, 0, 0, 0.12);
			}
		}
	}

	.md-label
	{
		border-color: transparent;
		border-width: 0;
		float: left;
	}

	.md-bar
	{
		left: 1px;
		width: $switch-width - 2px;
		top: $switch-height * 0.5 - $switch-bar-height * 0.5;
		height: $switch-bar-height;
		border-radius: 8px;
		position: absolute;
	}

	.md-thumb-container
	{
		top: $switch-height * 0.5 - $switch-thumb-size * 0.5;
		left: 0;
		width: $switch-width - $switch-thumb-size;
		position: absolute;
		transform: translate3d(0, 0, 0);
		z-index: 1;
	}
	&.md-checked .md-thumb-container
	{
		transform: translate3d(100%, 0, 0);
	}

	.md-thumb
	{
		position: absolute;
		margin: 0;
		left: 0;
		top: 0;
		outline: none;
		height: $switch-thumb-size;
		width: $switch-thumb-size;
		border-radius: 50%;
		box-shadow: $whiteframe-shadow-1dp;

		&:before
		{
			background-color: transparent;
			border-radius: 50%;
			content: '';
			position: absolute;
			display: block;
			height: auto;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			transition: all 0.5s;
			width: auto;
		}

		.md-ripple-container
		{
			position: absolute;
			display: block;
			width: auto;
			height: auto;
			left: -$switch-thumb-size;
			top: -$switch-thumb-size;
			right: -$switch-thumb-size;
			bottom: -$switch-thumb-size;
		}
	}

	&:not(.md-dragging)
	{
		.md-bar,
		.md-thumb-container,
		.md-thumb
		{
			transition: $swift-linear;
			transition-property: transform, background-color;
		}
		.md-bar,
		.md-thumb
		{
			transition-delay: 0.05s;
		}
	}

}

@media screen and (-ms-high-contrast: active)
{
	md-switch.md-default-theme .md-bar
	{
		background-color: #666;
	}
	md-switch.md-default-theme.md-checked .md-bar
	{
		background-color: #9E9E9E;
	}
	md-switch.md-default-theme .md-thumb
	{
		background-color: #FFF;
	}
}

$tabs-paginator-width: $baseline-grid * 4 !default;
$tabs-tab-width: $baseline-grid * 12 !default;
$tabs-header-height: 48px !default;

@keyframes md-tab-content-hide
{
	0%
	{
		opacity: 1;
	}
	50%
	{
		opacity: 1;
	}
	100%
	{
		opacity: 0;
	}
}

md-tab-data
{
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: -1;
	opacity: 0;
}

md-tabs
{
	display: block;
	margin: 0;
	border-radius: 2px;
	overflow: visible;
	position: relative;
	flex-shrink: 0;
	&:not(.md-no-tab-content):not(.md-dynamic-height)
	{
		min-height: 200 + $tabs-header-height;
	}
	&[md-align-tabs="bottom"]
	{
		padding-bottom: $tabs-header-height;
		md-tabs-wrapper
		{
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: $tabs-header-height;
			z-index: 2;
		}
		md-tabs-content-wrapper
		{
			top: 0;
			bottom: $tabs-header-height;
		}
	}
	&.md-dynamic-height
	{
		md-tabs-content-wrapper
		{
			min-height: 0;
			position: relative;
			top: auto;
			left: auto;
			right: auto;
			bottom: auto;
			overflow: visible;
		}
		md-tab-content
		{
			&.md-active
			{
				position: relative;
			}
		}
	}
	&[md-border-bottom]
	{
		md-tabs-wrapper
		{
			border-width: 0 0 1px;
			border-style: solid;
		}
		&:not(.md-dynamic-height)
		{
			md-tabs-content-wrapper
			{
				top: $tabs-header-height + 1;
			}
		}
	}
}

md-tabs-wrapper
{
	display: block;
	position: relative;
	// transform is needed for iOS Safari to prevent content from disappearing on scroll
	transform: translate3d(0, 0, 0);
	md-prev-button, md-next-button
	{
		height: 100%;
		width: $tabs-paginator-width;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		line-height: 1em;
		z-index: 2;
		cursor: pointer;
		font-size: 16px;
		background: transparent no-repeat center center;
		transition: $swift-ease-in-out;
		&:focus
		{
			outline: none;
		}
		&.md-disabled
		{
			opacity: 0.25;
			cursor: default;
		}
		&.ng-leave
		{
			transition: none;
		}
		md-icon
		{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate3d(-50%, -50%, 0);
		}
	}
	md-prev-button
	{
		@include rtl-prop(left, right, 0, auto);
		background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE3LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPiA8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPiA8c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjQgMjQiIHhtbDpzcGFjZT0icHJlc2VydmUiPiA8ZyBpZD0iSGVhZGVyIj4gPGc+IDxyZWN0IHg9Ii02MTgiIHk9Ii0xMjA4IiBmaWxsPSJub25lIiB3aWR0aD0iMTQwMCIgaGVpZ2h0PSIzNjAwIi8+IDwvZz4gPC9nPiA8ZyBpZD0iTGFiZWwiPiA8L2c+IDxnIGlkPSJJY29uIj4gPGc+IDxwb2x5Z29uIHBvaW50cz0iMTUuNCw3LjQgMTQsNiA4LDEyIDE0LDE4IDE1LjQsMTYuNiAxMC44LDEyIAkJIiBzdHlsZT0iZmlsbDp3aGl0ZTsiLz4gPHJlY3QgZmlsbD0ibm9uZSIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ii8+IDwvZz4gPC9nPiA8ZyBpZD0iR3JpZCIgZGlzcGxheT0ibm9uZSI+IDxnIGRpc3BsYXk9ImlubGluZSI+IDwvZz4gPC9nPiA8L3N2Zz4NCg==');
	}
	md-next-button
	{
		@include rtl-prop(right, left, 0, auto);
		background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE3LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPiA8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPiA8c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjQgMjQiIHhtbDpzcGFjZT0icHJlc2VydmUiPiA8ZyBpZD0iSGVhZGVyIj4gPGc+IDxyZWN0IHg9Ii02MTgiIHk9Ii0xMzM2IiBmaWxsPSJub25lIiB3aWR0aD0iMTQwMCIgaGVpZ2h0PSIzNjAwIi8+IDwvZz4gPC9nPiA8ZyBpZD0iTGFiZWwiPiA8L2c+IDxnIGlkPSJJY29uIj4gPGc+IDxwb2x5Z29uIHBvaW50cz0iMTAsNiA4LjYsNy40IDEzLjIsMTIgOC42LDE2LjYgMTAsMTggMTYsMTIgCQkiIHN0eWxlPSJmaWxsOndoaXRlOyIvPiA8cmVjdCBmaWxsPSJub25lIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiLz4gPC9nPiA8L2c+IDxnIGlkPSJHcmlkIiBkaXNwbGF5PSJub25lIj4gPGcgZGlzcGxheT0iaW5saW5lIj4gPC9nPiA8L2c+IDwvc3ZnPg0K');
		md-icon
		{
			transform: translate3d(-50%, -50%, 0) rotate(180deg);
		}
	}
	&.md-stretch-tabs
	{
		md-pagination-wrapper
		{
			width: 100%;
			flex-direction: row;
			md-tab-item
			{
				flex-grow: 1;
			}
		}
	}
}

md-tabs-canvas
{
	@include pie-clearfix;
	position: relative;
	overflow: hidden;
	display: block;
	height: $tabs-header-height;
	.md-dummy-wrapper
	{
		position: absolute;
		top: 0;
		@include rtl-prop(left, right, 0, auto);
	}
	&.md-paginated
	{
		margin: 0 $tabs-paginator-width;
	}
	&.md-center-tabs
	{
		display: flex;
		flex-direction: column;
		text-align: center;
		.md-tab
		{
			float: none;
			display: inline-block;
		}
	}
}

md-pagination-wrapper
{
	@include pie-clearfix;
	height: $tabs-header-height;
	display: flex;
	transition: transform $swift-ease-in-out-duration $swift-ease-in-out-timing-function;
	position: absolute;
	@include rtl-prop(left, right, 0, auto);
	transform: translate3d(0, 0, 0);
	&.md-center-tabs
	{
		position: relative;
		justify-content: center;
	}
}

md-tabs-content-wrapper
{
	display: block;
	position: absolute;
	top: $tabs-header-height;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: visible;
}

md-tab-content
{
	display: flex;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	transition: transform $swift-ease-in-out-duration $swift-ease-in-out-timing-function;
	overflow: auto;
	// transform is needed for iOS Safari to prevent content from disappearing on scroll
	transform: translate3d(0, 0, 0);
	&.md-no-scroll
	{
		bottom: auto;
		overflow: visible;
	}
	&.ng-leave, &.md-no-transition
	{
		transition: none;
	}
	&.md-left:not(.md-active)
	{
		@include rtl(transform, translateX(-100%), translateX(+100%));
		animation: 2 * $swift-ease-in-out-duration md-tab-content-hide;
		opacity: 0;
		*
		{
			transition: visibility 0s linear;
			transition-delay: $swift-ease-in-out-duration;
			visibility: hidden;
		}
	}
	&.md-right:not(.md-active)
	{
		@include rtl(transform, translateX(100%), translateX(-100%));
		animation: 2 * $swift-ease-in-out-duration md-tab-content-hide;
		opacity: 0;
		*
		{
			transition: visibility 0s linear;
			transition-delay: $swift-ease-in-out-duration;
			visibility: hidden;
		}
	}
	> div
	{
		flex: 1 0 100%;
		min-width: 0;
		&.ng-leave
		{
			animation: 2 * $swift-ease-in-out-duration md-tab-content-hide;
		}
	}
}

md-ink-bar
{
	$duration: $swift-ease-in-out-duration * 0.5;
	$multiplier: 0.5;
	position: absolute;
	left: auto;
	right: auto;
	bottom: 0;
	height: 2px;
	&.md-left
	{
		transition: left ($duration * $multiplier) $swift-ease-in-out-timing-function,
		right $duration $swift-ease-in-out-timing-function;
	}
	&.md-right
	{
		transition: left $duration $swift-ease-in-out-timing-function,
		right ($duration * $multiplier) $swift-ease-in-out-timing-function;
	}
}

md-tab
{
	position: absolute;
	z-index: -1;
	left: -9999px;
}

.md-tab
{
	font-size: 14px;
	text-align: center;
	line-height: $tabs-header-height - 24;
	padding: 12px 16px;
	transition: background-color 0.35s $swift-ease-in-out-timing-function;
	cursor: pointer;
	white-space: nowrap;
	position: relative;
	text-transform: uppercase;
	@include rtl(float, left, right);
	font-weight: 500;
	box-sizing: border-box;
	overflow: hidden;
	text-overflow: ellipsis;
	&.md-focused
	{
		box-shadow: none;
		outline: none;
	}
	&.md-active
	{
		cursor: default;
	}
	&.md-disabled
	{
		pointer-events: none;
		touch-action: pan-y;
		user-select: none;
		-webkit-user-drag: none;
		opacity: 0.5;
		cursor: default;
	}
	&.ng-leave
	{
		transition: none;
	}
}

md-toolbar + md-tabs
{
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

// See height set globally, depended on by buttons

$md-toast-content-padding: 3 * $baseline-grid - $button-left-right-padding !default;
$md-toast-button-left-margin: 3 * $baseline-grid - 2 * $button-left-right-padding !default;
$md-toast-text-padding: $button-left-right-padding !default;

.md-toast-text
{
	padding: 0 $md-toast-text-padding;
}

md-toast
{
	position: absolute;
	z-index: $z-index-toast;

	box-sizing: border-box;
	cursor: default;
	overflow: hidden;

	// Add some padding to the outer toast container so that the wrapper's box shadow is visible
	padding: $toast-margin;

	// Setup opacity transition on whole toast
	opacity: 1;
	transition: $swift-ease-out;

	.md-toast-content
	{
		display: flex;
		direction: row;
		align-items: center;

		max-height: 7 * $toast-height;
		max-width: 100%;

		min-height: 48px;
		// Since we're vertically centering our text by using flexbox and having a min-height, we need to apply
		// a fix for an IE11 flexbug, otherwise the text won't be centered vertically.
		@include ie11-min-height-flexbug(48px);

		padding: 0 $md-toast-content-padding;

		box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
		border-radius: 2px;
		font-size: 14px;

		overflow: hidden;

		// Setup for transform transitions on inner content
		transform: translate3d(0, 0, 0) rotateZ(0deg);
		transition: $swift-ease-out;

		@include rtl(justify-content, flex-start, flex-end);

		span
		{
			// Layout  [flex]
			flex: 1 1 0%;
			box-sizing: border-box;
			min-width: 0;
		}
	}

	&.md-capsule
	{
		border-radius: 24px;

		.md-toast-content
		{
			border-radius: 24px;
		}
	}

	&.ng-leave-active
	{
		.md-toast-content
		{
			transition: $swift-ease-in;
		}
	}

	/* Transition differently when swiping */
	&.md-swipeleft,
	&.md-swiperight,
	&.md-swipeup,
	&.md-swipedown
	{
		.md-toast-content
		{
			transition: $swift-ease-out;
		}
	}

	&.ng-enter
	{
		opacity: 0;
		.md-toast-content
		{
			transform: translate3d(0, 100%, 0);
		}
		&.md-top
		{
			.md-toast-content
			{
				transform: translate3d(0, -100%, 0);
			}
		}
		&.ng-enter-active
		{
			opacity: 1;
			.md-toast-content
			{
				transform: translate3d(0, 0, 0);
			}
		}
	}
	/*
   * When the toast doesn't take up the whole screen,
   * make it rotate when the user swipes it away
   */
	&.ng-leave.ng-leave-active
	{
		.md-toast-content
		{
			opacity: 0;
			transform: translate3d(0, 100%, 0);
		}

		&.md-swipeup
		{
			.md-toast-content
			{
				transform: translate3d(0, -50%, 0);
			}
		}
		&.md-swipedown
		{
			.md-toast-content
			{
				transform: translate3d(0, 50%, 0);
			}
		}
		&.md-top
		{
			.md-toast-content
			{
				transform: translate3d(0, -100%, 0);
			}
		}
	}

	.md-action
	{
		line-height: 19px;
		margin-left: 24px;
		margin-right: 0;
		cursor: pointer;
		text-transform: uppercase;
		float: right;
	}

	.md-button
	{
		min-width: 0;
		@include rtl(margin-right, 0, $md-toast-button-left-margin);
		@include rtl(margin-left, $md-toast-button-left-margin, 0);
	}
}

@media (max-width: $layout-breakpoint-sm - 1)
{
	md-toast
	{
		left: 0;
		right: 0;
		width: 100%;
		max-width: 100%;
		min-width: 0;
		border-radius: 0;
		bottom: 0;
		padding: 0;

		&.ng-leave.ng-leave-active
		{
			&.md-swipeup
			{
				.md-toast-content
				{
					transform: translate3d(0, -50%, 0);
				}
			}
			&.md-swipedown
			{
				.md-toast-content
				{
					transform: translate3d(0, 50%, 0);
				}
			}
		}
	}
}

@media (min-width: $layout-breakpoint-sm)
{
	md-toast
	{
		min-width: 288px + $toast-margin * 2;
		&.md-bottom
		{
			bottom: 0;
		}
		&.md-left
		{
			left: 0;
		}
		&.md-right
		{
			right: 0;
		}
		&.md-top
		{
			top: 0;
		}

		// Support for RTL alignment
		&._md-start
		{
			@include rtl-prop(left, right, 0, auto);
		}

		&._md-end
		{
			@include rtl-prop(right, left, 0, auto);
		}

		/*
   * When the toast doesn't take up the whole screen,
   * make it rotate when the user swipes it away
   */
		&.ng-leave.ng-leave-active
		{
			&.md-swipeleft
			{
				.md-toast-content
				{
					transform: translate3d(-50%, 0, 0);
				}
			}
			&.md-swiperight
			{
				.md-toast-content
				{
					transform: translate3d(50%, 0, 0);
				}
			}
		}
	}
}

@media (min-width: $layout-breakpoint-lg)
{
	md-toast
	{
		.md-toast-content
		{
			max-width: $baseline-grid * 71;
		}
	}
}

@media screen and (-ms-high-contrast: active)
{
	md-toast
	{
		border: 1px solid #FFF;
	}
}

// While animating, set the toast parent's overflow to hidden so scrollbars do not appear
/*
.md-toast-animating
{
	overflow: hidden !important;
}
*/

// Standard/Desktop Heights
$md-toolbar-height: $baseline-grid * 8 !default;
$md-toolbar-medium-tall-height: 88px !default;
$md-toolbar-tall-height: 128px !default;

// Mobile device heights
$md-toolbar-height-mobile-portrait: 56px !default;
$md-toolbar-height-mobile-landscape: 48px !default;

$md-toolbar-indent-margin: 64px !default;
$md-toolbar-padding: 16px !default;

$icon-button-margin-offset: rem(-0.800) !default;

md-toolbar
{
	box-sizing: border-box;
	display: flex;
	flex-direction: column;

	position: relative;
	//z-index: 2;

	font-size: rem(2.0);
	min-height: $md-toolbar-height;
	width: 100%;

	&._md-toolbar-transitions
	{
		transition-duration: $swift-ease-in-out-duration;
		transition-timing-function: $swift-ease-in-out-timing-function;
		transition-property: background-color, fill, color;
	}

	&.md-whiteframe-z1-add, &.md-whiteframe-z1-remove
	{
		transition: box-shadow $swift-ease-in-out-duration linear;
	}

	md-toolbar-filler
	{
		width: 9 * $baseline-grid;
	}

	*,
	*:before,
	*:after
	{
		box-sizing: border-box;
	}

	// By default $ngAnimate looks for transition durations on the element, when using ng-hide, ng-if, ng-show.
	// The toolbar has a transition duration applied, which means, that $ngAnimate delays the hide process.
	// To avoid this, we need to reset the transition, when $ngAnimate looks for the duration.
	&.ng-animate
	{
		transition: none;
	}

	&.md-tall
	{
		height: $md-toolbar-tall-height;
		min-height: $md-toolbar-tall-height;
		max-height: $md-toolbar-tall-height;
	}

	&.md-medium-tall
	{
		height: $md-toolbar-medium-tall-height;
		min-height: $md-toolbar-medium-tall-height;
		max-height: $md-toolbar-medium-tall-height;

		.md-toolbar-tools
		{
			height: 48px;
			min-height: 48px;
			max-height: 48px;
		}
	}

	> .md-indent
	{
		@include rtl-prop(margin-left, margin-right, $md-toolbar-indent-margin, auto);
	}

	~ md-content
	{
		> md-list
		{
			padding: 0;

			md-list-item:last-child
			{
				md-divider
				{
					display: none;
				}
			}
		}
	}
}

.md-toolbar-tools
{
	font-size: $title-font-size-base;
	letter-spacing: 0.005em;
	box-sizing: border-box;
	font-weight: 400;
	display: flex;
	align-items: center;
	flex-direction: row;

	width: 100%;
	height: $md-toolbar-height;
	max-height: $md-toolbar-height;
	padding: 0 $md-toolbar-padding;
	margin: 0;

	color: #FFF;

	h1, h2, h3
	{
		font-size: inherit;
		font-weight: inherit;
		margin: inherit;
		padding: 0;
	}

	a
	{
		color: inherit;
		text-decoration: none;
	}
	a i
	{
		display: inline-block;
		font-size:20px;
		line-height: 20px;
		margin:0 10px 0 0;
	}
	.fill-height
	{
		display: flex;
		align-items: center;
	}
	md-checkbox
	{
		margin: inherit;
	}
	md-icon
	{
		color: #FFF !important;
		fill: #FFF !important;
	}
	.md-button
	{
		margin-right: 0;
		margin-top: 0;
		margin-bottom: 0;

		&, &.md-icon-button md-icon
		{
			transition-duration: $swift-ease-in-out-duration;
			transition-timing-function: $swift-ease-in-out-timing-function;
			transition-property: background-color, fill, color;

			// Normally .md-button is already resetting the transition, when $ngAnimate looks for the duration,
			// but in this case, the selector has a higher specificity than the `reset selector`, which means, that
			// we need to reset the transition our self.
			&.ng-animate
			{
				transition: none;
			}
		}
	}
	& > .md-button:first-child
	{
		@include rtl-prop(margin-left, margin-right, $icon-button-margin-offset, auto);
	}
	& > .md-button:last-child
	{
		@include rtl-prop(margin-right, margin-left, $icon-button-margin-offset, auto);
	}

	& > md-menu:last-child
	{
		@include rtl-prop(margin-right, margin-left, $icon-button-margin-offset, auto);
		& > .md-button
		{
			@include rtl-prop(margin-right, margin-left, 0, auto);
		}
	}

	@media screen and (-ms-high-contrast: active)
	{
		border-bottom: 1px solid #FFF;
	}
}

// Handle mobile portrait
@media (min-width: 0) and (max-width: $layout-breakpoint-sm - 1) and (orientation: portrait)
{
	md-toolbar
	{
		min-height: $md-toolbar-height-mobile-portrait;
	}

	.md-toolbar-tools
	{
		height: $md-toolbar-height-mobile-portrait;
		max-height: $md-toolbar-height-mobile-portrait;
	}
}

// Handle mobile landscape
@media (min-width: 0) and (max-width: $layout-breakpoint-sm - 1) and (orientation: landscape)
{
	md-toolbar
	{
		min-height: $md-toolbar-height-mobile-landscape;
	}

	.md-toolbar-tools
	{
		height: $md-toolbar-height-mobile-landscape;
		max-height: $md-toolbar-height-mobile-landscape;
	}
}

$tooltip-fontsize-lg: 10px !default;
$tooltip-fontsize-sm: 14px !default;
$tooltip-height-lg: 22px !default;
$tooltip-height-sm: 32px !default;
$tooltip-top-margin-lg: 14px !default;
$tooltip-top-margin-sm: 24px !default;
$tooltip-lr-padding-lg: 8px !default;
$tooltip-lr-padding-sm: 16px !default;
$tooltip-max-width: 32px !default;

.md-tooltip
{
	pointer-events: none;
	border-radius: 4px;
	overflow: hidden;
	opacity: 0;
	font-weight: 500;
	font-size: $tooltip-fontsize-sm;
	white-space: nowrap;
	text-overflow: ellipsis;
	height: $tooltip-height-sm;
	line-height: $tooltip-height-sm;
	padding-right: $tooltip-lr-padding-sm;
	padding-left: $tooltip-lr-padding-sm;
	&.md-origin-top
	{
		transform-origin: center bottom;
		margin-top: -$tooltip-top-margin-sm;
	}
	&.md-origin-right
	{
		transform-origin: left center;
		margin-left: $tooltip-top-margin-sm;
	}
	&.md-origin-bottom
	{
		transform-origin: center top;
		margin-top: $tooltip-top-margin-sm;
	}
	&.md-origin-left
	{
		transform-origin: right center;
		margin-left: -$tooltip-top-margin-sm;
	}

	@media (min-width: $layout-breakpoint-sm)
	{
		font-size: $tooltip-fontsize-lg;
		height: $tooltip-height-lg;
		line-height: $tooltip-height-lg;
		padding-right: $tooltip-lr-padding-lg;
		padding-left: $tooltip-lr-padding-lg;
		&.md-origin-top
		{
			margin-top: -$tooltip-top-margin-lg;
		}
		&.md-origin-right
		{
			margin-left: $tooltip-top-margin-lg;
		}
		&.md-origin-bottom
		{
			margin-top: $tooltip-top-margin-lg;
		}
		&.md-origin-left
		{
			margin-left: -$tooltip-top-margin-lg;
		}
	}

	&.md-show-add
	{
		transform: scale(0);
	}
	&.md-show
	{
		transition: $swift-ease-out;
		transform: scale(1);
		opacity: 0.9;
	}
	&.md-hide
	{
		transition: $swift-ease-in;
		transition-duration: .1s;
		transform: scale(0);
		opacity: 0;
	}
}

.md-truncate
{
	overflow: hidden;
	white-space: nowrap;

	// Default overflow is ellipsis
	text-overflow: ellipsis;

	// Allow override to use clipping
	&.md-clip
	{
		text-overflow: clip;
	}

	// This is a flex-specific hack that forces the element to only take up available space.
	&.flex
	{
		width: 0;
	}
}

$virtual-repeat-scrollbar-width: 16px !default;

.md-virtual-repeat-container
{
	box-sizing: border-box;
	display: block;
	margin: 0;
	overflow: hidden;
	padding: 0;
	position: relative;

	.md-virtual-repeat-scroller
	{
		bottom: 0;
		box-sizing: border-box;
		left: 0;
		margin: 0;
		overflow-x: hidden;
		padding: 0;
		position: absolute;
		right: 0;
		top: 0;
		-webkit-overflow-scrolling: touch;
	}

	.md-virtual-repeat-sizer
	{
		box-sizing: border-box;
		height: 1px;
		display: block;
		margin: 0;
		padding: 0;
		width: 1px;
	}

	.md-virtual-repeat-offsetter
	{
		box-sizing: border-box;
		left: 0;
		margin: 0;
		padding: 0;
		position: absolute;
		right: 0;
		top: 0;
	}
}

.md-virtual-repeat-container.md-orient-horizontal
{
	.md-virtual-repeat-scroller
	{
		overflow-x: auto;
		overflow-y: hidden;
	}

	.md-virtual-repeat-offsetter
	{
		// Leave room for the scroll bar.
		// TODO: Will probably need to perform measurements at runtime.
		bottom: $virtual-repeat-scrollbar-width;
		@include rtl-prop(right, left, auto, auto);
		white-space: nowrap;
	}
}

.md-whiteframe-1dp, .md-whiteframe-z1
{
	box-shadow: $whiteframe-shadow-1dp;
}

.md-whiteframe-2dp
{
	box-shadow: $whiteframe-shadow-2dp;
}

.md-whiteframe-3dp
{
	box-shadow: $whiteframe-shadow-3dp;
}

.md-whiteframe-4dp, .md-whiteframe-z2
{
	box-shadow: $whiteframe-shadow-4dp;
}

.md-whiteframe-5dp
{
	box-shadow: $whiteframe-shadow-5dp;
}

.md-whiteframe-6dp
{
	box-shadow: $whiteframe-shadow-6dp;
}

.md-whiteframe-7dp, .md-whiteframe-z3
{
	box-shadow: $whiteframe-shadow-7dp;
}

.md-whiteframe-8dp
{
	box-shadow: $whiteframe-shadow-8dp;
}

.md-whiteframe-9dp
{
	box-shadow: $whiteframe-shadow-9dp;
}

.md-whiteframe-10dp, .md-whiteframe-z4
{
	box-shadow: $whiteframe-shadow-10dp;
}

.md-whiteframe-11dp
{
	box-shadow: $whiteframe-shadow-11dp;
}

.md-whiteframe-12dp
{
	box-shadow: $whiteframe-shadow-12dp;
}

.md-whiteframe-13dp, .md-whiteframe-z5
{
	box-shadow: $whiteframe-shadow-13dp;
}

.md-whiteframe-14dp
{
	box-shadow: $whiteframe-shadow-14dp;
}

.md-whiteframe-15dp
{
	box-shadow: $whiteframe-shadow-15dp;
}

.md-whiteframe-16dp
{
	box-shadow: $whiteframe-shadow-16dp;
}

.md-whiteframe-17dp
{
	box-shadow: $whiteframe-shadow-17dp;
}

.md-whiteframe-18dp
{
	box-shadow: $whiteframe-shadow-18dp;
}

.md-whiteframe-19dp
{
	box-shadow: $whiteframe-shadow-19dp;
}

.md-whiteframe-20dp
{
	box-shadow: $whiteframe-shadow-20dp;
}

.md-whiteframe-21dp
{
	box-shadow: $whiteframe-shadow-21dp;
}

.md-whiteframe-22dp
{
	box-shadow: $whiteframe-shadow-22dp;
}

.md-whiteframe-23dp
{
	box-shadow: $whiteframe-shadow-23dp;
}

.md-whiteframe-24dp
{
	box-shadow: $whiteframe-shadow-24dp;
}

@media screen and (-ms-high-contrast: active)
{
	md-whiteframe
	{
		border: 1px solid #FFF;
	}
}

@media print
{
	md-whiteframe, [md-whiteframe]
	{
		background-color: #FFF;
	}
}

/*
* Since Layout API uses ng-cloak to hide the dom elements while layouts are adjusted
*
*/
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak
{
	display: none !important;
}

/*
*
*  Responsive attributes
*
*  References:
*  1) https://scotch.io/tutorials/a-visual-guide-to-css3-flexbox-properties#flex
*  2) https://css-tricks.com/almanac/properties/f/flex/
*  3) https://css-tricks.com/snippets/css/a-guide-to-flexbox/
*  4) https://github.com/philipwalton/flexbugs#3-min-height-on-a-flex-container-wont-apply-to-its-flex-items
*  5) http://godban.com.ua/projects/flexgrid
*
*
*/

@-moz-document url-prefix()
{
	.layout-fill
	{
		margin: 0;
		width: 100%;
		min-height: 100%;
		height: 100%;
	}
}

/*
 *  Apply Mixins to create Layout/Flexbox styles
 *
 */

@include layouts_for_breakpoint();
@include layout-padding-margin();

/**
 * `hide-gt-sm show-gt-lg` should hide from 600px to 1200px
 * `show-md hide-gt-sm` should show from 0px to 960px and hide at >960px
 * `hide-gt-md show-gt-sm` should show everywhere (show overrides hide)`
 *
 *  hide means hide everywhere
 *  Sizes:
 *         $layout-breakpoint-xs:     600px !default;
 *         $layout-breakpoint-sm:     960px !default;
 *         $layout-breakpoint-md:     1280px !default;
 *         $layout-breakpoint-lg:     1920px !default;
 */

@media (max-width: $layout-breakpoint-xs - 1)
{
	// Xtra-SMALL  SCREEN
	.hide-xs, .hide
	{
		&:not(.show-xs):not(.show)
		{
			display: none;
		}
	}
	@include layouts_for_breakpoint(xs);
}

@media (min-width: $layout-breakpoint-xs)
{
	// BIGGER THAN Xtra-SMALL SCREEN
	@include layouts_for_breakpoint(gt-xs);

}

@media (min-width: $layout-breakpoint-xs) and (max-width: $layout-breakpoint-sm - 1)
{
	.hide, .hide-gt-xs
	{
		&:not(.show-gt-xs):not(.show-sm):not(.show)
		{
			display: none;
		}
	}
	.hide-sm:not(.show-gt-xs):not(.show-sm):not(.show)
	{
		display: none;
	}

	@include layouts_for_breakpoint(sm);
}

@media (min-width: $layout-breakpoint-sm)
{
	// BIGGER THAN SMALL SCREEN
	@include layouts_for_breakpoint(gt-sm);

}

@media (min-width: $layout-breakpoint-sm) and (max-width: $layout-breakpoint-md - 1)
{
	// MEDIUM SCREEN
	.hide, .hide-gt-xs, .hide-gt-sm
	{
		&:not(.show-gt-xs):not(.show-gt-sm):not(.show-md):not(.show)
		{
			display: none;
		}
	}
	.hide-md:not(.show-md):not(.show-gt-sm):not(.show-gt-xs):not(.show)
	{
		display: none;
	}
	@include layouts_for_breakpoint(md);
}

@media (min-width: $layout-breakpoint-md)
{
	// BIGGER THAN MEDIUM SCREEN
	@include layouts_for_breakpoint(gt-md);
}

@media (min-width: $layout-breakpoint-md) and (max-width: $layout-breakpoint-lg - 1)
{
	// LARGE SCREEN
	.hide, .hide-gt-xs, .hide-gt-sm, .hide-gt-md
	{
		&:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-lg):not(.show)
		{
			display: none;
		}
	}
	.hide-lg:not(.show-lg):not(.show-gt-md):not(.show-gt-sm):not(.show-gt-xs):not(.show)
	{
		display: none;
	}

	@include layouts_for_breakpoint(lg);
}

@media (min-width: $layout-breakpoint-lg)
{
	@include layouts_for_breakpoint(gt-lg);
	@include layouts_for_breakpoint(xl);

	// BIGGER THAN LARGE SCREEN
	.hide, .hide-gt-xs, .hide-gt-sm, .hide-gt-md, .hide-gt-lg
	{
		&:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-gt-lg):not(.show-xl):not(.show)
		{
			display: none;
		}
	}
	.hide-xl:not(.show-xl):not(.show-gt-lg):not(.show-gt-md):not(.show-gt-sm):not(.show-gt-xs):not(.show)
	{
		display: none;
	}

}

// General printing Rules
@media print
{

	.hide-print:not(.show-print):not(.show)
	{
		display: none !important;
	}
}
