$white-gray:  #F0F1F2;
$light-gray:  #AEB2B7;
$white:       #FFFFFF;
$light-blue:  #1275bf;
$blue:        #00378a;
$red:         #e41115;

html.context-webapp a#onboarding-trigger.btn.onboarding-trigger,
a#onboarding-trigger.btn.onboarding-trigger {
  position: fixed;
  bottom: 0;
  right: 48px;
  font-size: .9rem;
  background: $blue;
  color: $white;
  border-color: $blue;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin: 0;
  z-index: 5000;
}

// Workaround for navigation active highlighting
nav#navTop.introjs-fixParent .active {
  background: transparent;
}

// Workaround for navigation active highlighting
nav#navTop.introjs-fixParent .onboarding--active > a {
  font-weight: bold;
  color: $white;

  &::before {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3px;
    background: $red;
    content: " ";
    transition: opacity 0.24s ease;
  }
}

// Workaround for navigation active highlighting
nav#navTop.introjs-fixParent .user.sub.onboarding--active > a {
  &::before {
    display: none;
  }

  > div.avatar {
    border: 2px solid $red;
  }
}

.introjs-overlay {
  opacity: 0.4 !important;
};


.introjs-helperNumberLayer {
  font-family: inherit;
  font-size: 14px;
  text-shadow: none;
  width: 18px;
  height: 18px;
  line-height: 18px;
  border: 2px solid $light-gray;
  border-radius: 50%;
  background: $white-gray;
}

// Workaround for specific monitor navigation menu behaviour (hover)
html.context-webapp.no-touchevents .monitor #navTop.introjs-hover {
  top: 0;

  li > .subnav-outer.introjs-hover,
  li > .subnav-outer.introjs-hover .subnav-outer {
    display: block;

    .subnav-outer.pulldown .subnav-outer.pulldown {
      display: none;
    }
  }
}

// Workaround for z-index issues of navigation submenü
html.context-webapp.no-touchevents #navTop.introjs-hover  li {
  &.onboarding--active > .subnav-outer.introjs-hover {
    z-index: 5000 !important;
  }
}

#navTop.introjs-fixParent {
  position: absolute !important;
}


.introjs-fixParent  {
  z-index: 5 !important;
}

.introjs-helperLayer {
  background-color: $white;
  background-color: rgba(255,255,255,.2);
  border: none;
  border-radius: 1px;
  letter-spacing: inherit;
}

.introjs-tooltip {
  letter-spacing: .1em;
  color: black;
  font-family: inherit;
  font-size: 1rem;
  letter-spacing: inherit;
  border-radius: 1px;
  max-width: 400px;
  min-width: 400px;
}

#introjs-tooltip__title,
.introjs-tooltip__title  {
  font-size: 1rem;
  font-weight: bold;
  min-height: 45px;
  background: $white-gray;
  border-bottom: 1px solid $light-gray;
  margin: -10px -10px 10px -10px;
  padding: 10px 30px 10px 10px;
}

.introjs-button,
a.introjs-button,
html.context-webapp a.introjs-button {
  padding: 0.6em 0.8em;
  margin: 10px 5px 0 0;
  text-shadow: none;
  font-weight: bold;
  font-size: .9rem;
  color: $light-gray;
  font-family: inherit;
  background: white;
  background-image: none;
  border: 1px solid $light-gray;
  -webkit-border-radius: .4em;
  -moz-border-radius: .4em;
  border-radius: .4em;
  -webkit-transition: background .3s, border .3s;
     -moz-transition: background .3s, border .3s;
      -ms-transition: background .3s, border .3s;
       -o-transition: background .3s, border .3s;
          transition: background .3s, border .3s;

  &:hover {
    background: $light-gray;
    color: $white;
    box-shadow: none;
    border-color: $light-gray;
    text-decoration: none;
  }

  &.introjs-disabled,
  &.introjs-disabled:hover {
    cursor: pointer;
  }
}

.introjs-prevbutton,
.introjs-prevbutton.introjs-fullbutton {
  border-radius: .4em;
  margin: 10px 5px 0 0;
}

.introjs-nextbutton,
.introjs-nextbutton.introjs-fullbutton,
html.context-webapp a.introjs-button.introjs-nextbutton,
.introjs-donebutton,
html.context-webapp a.introjs-button.introjs-donebutton {
  border-radius: .4em;
  color: $white;
  background: $light-blue;
  border-color: $light-blue;

  &:hover {
    background: $light-gray;
    color: $white;
    box-shadow: none;
    border-color: $light-gray;
    text-decoration: none;
  }
}

.introjs-donebutton {
  float: right;
}

a.introjs-close,
a#introjs-close,
html.context-webapp a.introjs-close {
  color: black;
  position: absolute;
  padding: 10px;
  top: 0;
  right: 0;

  &:hover {
    color: rgba(0,0,0,.5);
  }
}