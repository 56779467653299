html.freeze
{
	overflow: hidden;

	body
	{
		overflow: hidden;
	}
}

#navTop
{
	a, button
	{
		color: $navigation-link-color;
		transition: color .247s ease-in-out;

		&:hover {
			color: $navigation-link-hover-color;
		}
	}
	& > ul
	{
		& > li
		{
			& > a
			{
				position: relative;
				z-index: 2;
				display: block;
				float: left;
				font-size: 1em;
				white-space: nowrap;
			}
			i, span.label
			{
				position: relative;
			}
			i
			{
				display: inline-block;
				margin-right: 6px;
				width: auto;
				line-height: 1;
				text-align: center;
			}
			span.desc
			{
				display: none;
			}

			&.active > a:before
			{
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				height: 3px;
				background: $navigation-active-border-background;
				content: " ";
				transition: opacity 0.24s ease;
			}

			ul [class^='icon']
			{
				display: none;
			}

			&.user
			{
				& > a
				{
					line-height: 30px;
				}

				span
				{
					display: block;
					font-size: .7em;
					font-weight: normal;
					line-height: 1;
				}

				@include media($mobile)
				{
					line-height: 20px;
					span
					{
						font-size: .9em;
					}
				}
			}
		}

		li.close
		{
			display: none;
		}

		&.hover
		{
			li.user:not(:hover),
			li.login:not(:hover)
			{
				display: none;
			}
			li.user.hover,
			li.login.hover
			{
				display: block;
			}

			li.user:not(:hover) + li.close,
			li.login:not(:hover) + li.close
			{
				position: absolute;
				top: 0;
				right: 0;
				display: block;
				padding: 15px;
				cursor: pointer;
				svg {
					display: block;
					width: 24px;
					height: 24px;
				}

				@include media($mobile)
				{
					padding: 12px;
					svg {
						width: 16px;
						height: 16px;
					}
				}
			}
			li.user.hover + li.close,
			li.login.hover + li.close
			{
				display: none !important;
			}
		}
	}
}

html.context-webapp body.inactive #logo,
html.context-webapp body.inactive #navTop
{
	top: -64px;
}

html.bg-nav-top-L2.context-webapp #navTop
{
	ul li.active > ul
	{
		background: rgba(0, 8, 15, .8);
	}
}

#sideNav
{
	flex-direction: row;
	flex: 0 0 25%;
	padding: 10px 20px 0 0;
	height: 100%;

	& > ul
	{
		font-size: 1.8em;
		ul
		{
			padding-left: .5em;
		}
	}
	ul
	{
		margin: 0 0 0 .5em;
		padding: 0 0 0 .5em;
		li
		{
			list-style: decimal;
			margin: 0;
			padding: 0;
			&:before
			{
				display: none;
			}
			&.active
			{
				font-weight: bold;
			}
			a
			{
				text-decoration: none;
			}
		}
	}
}

#sideNav + #main-wrap-inner
{
	flex: 0 0 75%;
}

#sideNav + #main-wrap-inner:before
{
	position: absolute;
	top: 0;
	left: -21px;
	bottom: 0;
	display: block;
	width: 1px;
	background: #FFF;
	content: " ";
}

@include media($tablet)
{
	#main
	{
		//flex-direction: column;
	}
	#sideNav > ul
	{
		font-size: 1.5em;
	}
	#sideNav
	{
		order: 2;
		flex: 0 0 100%;
		padding-top: 2em;
		& > ul
		{
			font-size: 1.5em;
		}
	}
	#sideNav + #main-wrap-inner
	{
		order: 1;
		flex: 0 0 100%;
	}
}

.context-webapp #sideNav
{
	color: #BBB;

	a, a:visited
	{
		color: #BBB;
	}

	li.active, li.active > a, li > a:hover
	{
		color: #FFF;
	}

	li.active li:not(.active)
	{
		color: #BBB;
	}
}

.context-webapp #navTop > ul > li
{
	position: static;
}

html.no-touchevents #navTop > ul:hover > li:not(:hover):not(.hover):not(.login),
html.touchevents #navTop > ul.hover > li:not(:hover):not(.hover):not(.login)
{
	&>a
	{
		color: $navigation-hover-other-link-color;
	}
}

html.no-touchevents #navTop > ul > li:hover > a,
html.touchevents #navTop > ul > li.hover > a
{
	color: $navigation-link-hover-color;
}

#navTop > ul:hover > li.active
{
	& > a:before
	{
		opacity: 0.35;
	}
}

#navTop li.active > a
{
	font-weight: bold;
}

#navTop > ul > li
{
	.subnav-outer.pulldown
	{
		& > .subnav-inner > ul > li.sub
		{
			position: relative;
		}
		& > .subnav-inner > ul > li.sub > span.subnav-door:after
		{
			position: absolute;
			top: -4px;
			right: -10px;
			content: "\f107";
			font: normal normal normal 20px/1 FontAwesome;

			padding: 10px;
			cursor: zoom-in;
		}
		& > .subnav-inner > ul > li.sub.open > span.subnav-door:after
		{
			transform: rotate(180deg);
			cursor: zoom-out;
		}
		& > .subnav-inner > ul > li.sub > .subnav-outer
		{
			display: none;
		}
		& > .subnav-inner > ul > li.sub.open > .subnav-outer
		{
			display: block;
		}
	}

	.sub-childs
	{
		ul
		{
			li
			{
				padding: 0 0 0 10px;
			}
			li:first-child
			{
				padding-left: 0;
			}
		}
	}

	& > .subnav-outer
	{
		position: fixed;
		top: 54px;
		left: 0;
		right: 0;
		overflow: hidden;
		overflow-y: auto;
		display: none;
		z-index: 4005;
		margin: 0;
		padding: 20px 0;
		background: $navigation-subnav-outer-background;
		max-height: calc(100vh - 50px);

		& > .subnav-inner
		{
			margin: 0 auto;
			padding: 0 0 0 0;
			max-width: 1000px;
		}

		& > .subnav-inner > ul
		{
			display: block;
		}

		li.sub
		{
			position: static;
		}

		&.grid
		{
			text-align: left;

			&>.subnav-inner
			{
				padding: 7px 0 12px;
				max-width: 1100px;

				& > ul > li
				{
					padding: 0 20px;
				}

				& > ul > li > a
				{
					padding-bottom: 20px;
					margin-bottom: 15px;
					border-bottom: 1px solid rgba(255,255,255,.6);
				}
			}
			ul
			{
				position: relative;
				display: block;
			}

			ul ul ul
			{
				padding-left: 10px;
			}

			ul ul li,
			.subnav-details a,
			label
			{
				font-size: 14px;
			}

			.subnav-details a.new
			{
				font-weight: bold;
				color: rgba(255, 255, 255, 0.8);
			}

			li
			{
				display: block;
				line-height: 20px;
			}
			a, label
			{
				position: relative;
				display: block;
				margin: 0;
				padding: 7px 0;
				height: auto;
				color: rgba(255,255,255,.6);
				white-space: normal;
			}

			div.skiplinks a,
			li.skiplinks li.active > a,
			div.skiplinks li.active > a
			{
				color: rgba(255,255,255,.6);
				font-weight: normal;

				&:hover
				{
					color: #FFF;
				}
			}

			.active > a,
			.active > label,
			li:hover > a {
				color: #FFF;
			}

			.sectionLabel
			{
				display: block;
				padding: 17px 0;
				font-size: 14px;
				font-weight: normal;
				line-height: 20px;
				text-transform: uppercase;
				color: rgba(255,255,255,.6);

				@include media($mobile)
				{
					display: none;
				}
			}
		}

		&.grid > div > ul
		{
			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;
			justify-content: center;
			margin: 0 auto;
			max-width: 1000px;
			width: 100%;
			& > li
			{
				flex: 1 1;

				.subnav-outer
				{
					display: block;
				}
			}
		}

		&.upward
		{
			ul
			{
				display: block;
			}
		}
	}
}

.subnav-details a
{
	font-family: Arial, sans-serif;
	font-weight: 400;
}

.context-webapp #navTop > ul > li > .subnav-outer
{
	left: 80px;
	& > .subnav-inner
	{
		padding-right: 80px;
	}
}

#navTop ul li .subnav-outer.grid .rootline
{
	margin: 0 auto 10px;
	padding: 0 20px;
	max-width: 1000px;
	font-size: 14px;
	font-weight: 400;
	line-height: 1;


	a, label
	{
		display: inline;
		font-size: 14px;
		font-weight: 400;
	}
}

#navTop > ul > li.hover
{
	& > div.subnav-outer
	{
		display: block;
	}
}

@include media($tablet)
{
	#navTop > ul > li i
	{
		margin-right: 3px;
	}

	#navTop > ul > li > a
	{
		padding: 0 6px;
	}

	#navTop > ul > li > .subnav-outer
	{
		padding: 10px 0;

		&.grid
		{
			bottom: 0;
			max-height: inherit;
		}
	}

	#navTop > ul > li > .subnav-outer.grid > .subnav-inner > ul > li > a
	{
		padding-bottom: 10px;
		margin-bottom: 4px;
	}

	#navTop > ul > li > .subnav-outer.grid > div > ul > li
	{
		flex: 1 0 50%;
		width: 50%;

		&:nth-child(n+3)
		{
			padding-top: 40px;
		}
	}
}

@include media($mobile)
{
	#navTop > ul > li > a
	{
		margin: 0 3px;
	}

	#navTop > ul > li > .subnav-outer
	{
		top: 40px;
		padding: 0;
	}

	#navTop ul ul
	{
		padding: 10px 0;
	}

	#navTop ul ul li
	{
		display: block;
		margin: 0;
		padding: 0;
	}
	#navTop ul ul a
	{
		display: block;
		margin: 6px 0;
		padding: 6px 0;
		font-size: 14px;
		line-height: 1.5;
	}

	.context-webapp #navTop > ul > li > .subnav-outer
	{
		left: 0;
	}

	.context-webapp #navTop > ul > li > .subnav-outer > .subnav-inner {
		padding-right: 0;
	}

	#navTop > ul > li > .subnav-outer .subnav-outer a
	{
		padding: 4px 0;
		font-size: 14px;
	}

	#navTop > ul > li > .subnav-outer.grid > div > ul > li:nth-child(n+2)
	{
		padding-top: 5px !important;
	}

	#navTop > ul > li > .subnav-outer.grid > div > ul > li
	{
		flex: 1 0 100%;
		width: 100%;
	}

	#navTop > ul > li > .subnav-outer
	{
		top: 40px;
	}
}

#ucrNav
{
	&:hover
	{
		#ucrNavTrigger
		{
			opacity: 1;
		}
	}
	li.active
	{
		position: relative;
		z-index: 6;
	}
}
#ucrNav
{
	transition: width .4s ease-in-out, opacity .2s ease;
}

html.context-webapp .monitor #ucrNav,
html.context-webapp .fullscreen #ucrNav
{
	width: 5px;
	opacity: 0;
}

html.context-webapp .monitor #ucrNav:hover
{
	opacity: 1;
}

html.context-webapp .monitor #main,
html.context-webapp .fullscreen #ucrNav
{
	padding-left: 0;
	padding-right: 0;
}

.context-webapp .monitor #navTop > ul > li > .subnav-outer,
html.context-webapp .fullscreen #navTop > ul > li > .subnav-outer
{
	left: 0;
	&>.subnav-inner {
		padding-right: 0;
	}
}

#ucrNavMeta
{
	position: absolute;
	bottom: 50px;
	left: 0;
	width: 200px;
	li
	{
		display: block;
		margin: 0 0 10px;
	}
	a
	{
		display: block;
	}
}

html.ucr-menu #ucrNav
{
	z-index: 4002;
	width: 200px !important;
	opacity: 1 !important;

	#ucrNavTrigger
	{
		opacity: 1;
	}

	a.shortname
	{
		display:none;
	}
	a.name
	{
		display:block;
		padding-left: 4px;
	}
	.ucrNavLabel,
	#ucrNavUser,
	#ucrNavSocial,
	#ucrNavMeta
	{
		visibility: visible;
	}

	#ucrNavSocial
	{
		display: block;
	}
}

#ucrNavSocial
{
	position: absolute;
	left: 5px;
	bottom: 0;
	display: block;
	width: 200px;
	div
	{
		padding: 0;
		font-size: 12px;
		text-align: left;
		a
		{
			padding: 10px;
		}
	}
}

#ucrNavOptions
{
	position: absolute;
	top:67px;
	left: 0;
	bottom: 50px;
	width: 200px;
	overflow: hidden;
	overflow-y: auto;
}

html.ucr-menu #ucrNavOptions
{
	bottom: 180px;
}

a#logo
{
	transition: width .4s ease-in-out;
	img
	{
		transition: left .4s ease-in-out;
	}
}

html.ucr-menu a#logo
{
	width: 200px;
	img
	{
		left: 12px;
	}
}

#ucrNavContent
{
	width: 200px;
}

#ucrNavTrigger
{
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	z-index: 5;
	width: 5px;
	opacity: 0;
	border-left: 3px solid $maincolor-Red;
	transition: opacity .24s, border-left .24s;
}

@keyframes ucrmenu
{
	from {
		width: 80px;
	}
	to {
		width: 200px;
	}
}
