footer
{
	position: relative;
	overflow: hidden;
	z-index: 3000;
	background: $footer-background;
	line-height: 1.5em;

	&:before
	{
		position: absolute;
		bottom: -50%;
		right: 15%;
		left: 15%;
		z-index: 1;
		height: 170%;
		background: $footer-background-overlay;
		background-color: transparent;
		content: " ";
	}
	a,
	.nav-item-content
	{
		display: block;
		color: #AAA;
		text-decoration: none;
		transition: color .247s;
		&:hover
		{
			color: #FFF;
		}
	}
	a.home
	{
		position: relative;
		z-index: 2;
		display: block;
		margin: 0;
		padding: 24px 0 18px;
		color: $maincolor-Red-Divera247;
		font-size: 36px;
		text-align: center;
	}
	.mainwrap
	{
		position: relative;
		&:before
		{
			position: absolute;
			top: 0;
			bottom: 0;
			right: -99px;
			left: -99px;
			z-index: 1;
			background: rgba(0,8,15,.4);
			transform: rotate(-1deg);
			content: " ";
		}
	}
	ul.hmenu
	{
		position: relative;
		overflow: hidden;
		z-index: 2;
		margin: 0;
		& > li
		{
			font-size: 1em;
		}

		&.main
		{
			margin: 10px auto;
			padding: 24px 0px;
			max-width: 1280px;

			&:empty
			{
				display: none;
			}

			& > li
			{
				display: block;
				float: left;
				margin: 0;
				padding: 15px;
				width: 20%;
				box-sizing: border-box;

				&.login,
				&.user,
				&.blog,
				&.jobs,
				&.faq,
				&.downloads
				{
					display: none;
				}

				@include media($tablet)
				{
					width: 33%;

					&.registration
					{
						clear: left;
					}
					&.contact
					{
						position: relative;
						width: 66%;
						.social
						{
							position: absolute;
							top: 0;
							right: 0px;
							padding: 10px 0 0 10px;
							width: 50%;
						}
					}
				}

				@include media($mobile)
				{
					width: 50%;
					&:nth-child(2n+1)
					{
						clear: left;
					}
					&.registration
					{
						clear: none;
					}
					&.contact
					{
						width: 100%;
						&>a,
						.nav-item-content
						{
							width: 45%;
						}
					}
				}

				& > a
				{
					margin: 0 0 4px;
					padding: 6px 0;
					color: #FFF;
					font-size: 16px;
				}

				li,
				.nav-item-content
				{
					display: block;
					a
					{
						margin: 0 0 5px;
						padding: 6px 0;
						font-size: 14px;
					}
				}

				span.desc,
				&.registration .nav-item-content a,
				li li a
				{
					font-size: 12px;
				}

				span.desc
				{
					line-height: 16px;
				}

				li.sub ul
				{
					padding-left: 10px;
				}

				li li li a
				{
					padding: 1px 0;
				}
			}
		}
		&.meta
		{
			text-align: center;
			padding: 5px 0 0;
			li
			{
				display: inline-block;
				padding: 0;
				font-size: 1.2em;
				a
				{
		    		margin: 0 5px 5px;
    				padding: 10px 5px;
				}
			}
		}
	}

	.nav-item-content .social
	{
		padding: 25px 0 0;
		span
		{
			color: #FFF;
			font-size: 16px;
		}
		a
		{
			display: inline-block !important;
			margin: 0 20px 5px 0 !important;
			font-size: 2.6em !important;
		}
	}

	.metawrap {
		background: $footer-metanav-background;
	}
}
